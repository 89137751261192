import { createStyles, keyframes, rem } from "@mantine/core";

const props = {
  scroll: false,
};
export const useStyles = createStyles((theme, { scroll } = {}) => ({
  //HEADER COMPONENT
  inner: {
    height: rem(56),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "red",
  },
  textState: {
    fontSize: "18px",
    color: "#ed7703",
  },
  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
  burger: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: "#004236",
    fontSize: theme.fontSizes.sm,
    fontWeight: "bolder",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  linkActive: {
    "&, &:hover": {
      backgroundColor: "#CBD300",
    },
  },
  linkLabel: {
    marginRight: rem(5),
  },
  // FOOTER COMPONENT
  footer: {
    position: scroll === 0 ? "fixed" : "relative",
    bottom: 0,
    width: "100%",
    zIndex: 300,
    [theme.fn.smallerThan("md")]: {
      position: "relative",
    },
  },
  //TRAVEL COMPONENT
  dropdown: { marginTop: "0.5rem" },
  rightSection: { pointerEvents: "none" },
  label: {
    color: "white",
  },
  labelSecondary: {
    color: "#004236",
    fontWeight: "bold",
  },
  input: {
    border: "1px solid #cbd300",
    "&:focus-within": {
      border: "2px solid #F6BB81",
    },
    "&[data-disabled]": {
      color: "black",
      backgroundColor: "#d3d3d3",
      border: "none",
    },
  },
  item: {
    "&:hover": {
      backgroundColor: "#e3eb99",
    },
    "&[data-selected]": {
      "&, &:hover": {
        backgroundColor: "#e3eb99",
        color: "black",
      },
    },
  },
  button: {
    backgroundColor: "#cbd300",
    color: "#004236",
    "&:hover": {
      backgroundColor: "#cbd300",
      filter: "brightness(96%)",
    },
  },
  buttonOutline: {
    color: "#004236",
    borderColor: "#004236",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#FBFBFB",
      filter: "brightness(98%)",
    },
  },
  checkBox: {
    margin: "1rem 0",
    input: {
      "&:checked": {
        backgroundColor: "#cbd300",
        border: "2px solid #cbd300",
      },
      border: "2px solid #cbd300",
    },
  },
  rootSegmentedControl: {
    backgroundColor: "transparent",
    "&:not([data-active])": {
      label: {
        color: "#fff",
        "&:hover": {
          color: "#cbd300",
        },
      },
    },
    label: {
      "&[data-active]": {
        color: "#cbd300",
      },
    },
  },
  indicatorSegmentedControl: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
  calendar: {
    "&[data-selected], &[data-selected]:hover": {
      backgroundColor: "#cbd300",
      color: "white",
    },
    "&[data-in-range], &[data-in-range]:hover": {
      backgroundColor: "#cbd300",
      color: "white",
      opacity: "0.5",
    },
    "&[data-first-in-range], &[data-first-in-range]:hover": {
      backgroundColor: "#cbd300",
      color: "white",
      opacity: "1",
    },
    "&[data-last-in-range], &[data-last-in-range]:hover": {
      backgroundColor: "#cbd300",
      color: "white",
      opacity: "1",
    },
  },
  // LOGIN
  lineGradientEcopetrol: {
    height: "10px",
    background:
      "linear-gradient(to right, #004237 0%, #004237 50%, #ffdd00 50%, #ffdd00 100%)",
  },
  lineGradientCenit: {
    height: "10px",
    background:
      "linear-gradient(to right, #14334D 0%, #14334D 50%, #D3CF37 50%, #D3CF37 100%)",
  },
  // BACKGROUND IMAGE
  backgroundImage: {
    minHeight: "calc(100vh - 135px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
    boxSizing: "border-box",
    paddingBlock: "3rem",
  },
  clouds: {
    animation: `${cloudsAnimation} 60s linear infinite`,
    position: "absolute",
    top: 0,
    left: 0,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  textOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  alignVerticallyCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  textShadow: {
    textShadow: "black 0.1em 0.1em 0.2em",
  },
  logoFligth: {
    width: "60px !important",
    height: "60px !important",
    maxWidth: "400px",
  },
  flightCard: {
    padding: theme.spacing.md,
    marginBottom: theme.spacing.md,
  },
  flightInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing.xs,
  },
  modalOverlay: {
    zIndex: 300,
  },
  modalHeader: {
    backgroundColor: "#cbd300",
  },
  modalTitle: {
    fontWeight: 700,
    color: "#004236",
  },
  modalClose: {
    color: "#004236",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  modalBody: {
    "&:not(:only-child)": {
      paddingTop: "1rem",
    },
    paddingTop: "1rem",
    fontWeight: 400,
  },
  modalInner: {
    padding: 0,
    zIndex: 400,
  },
  tabsList: { gap: "12px", borderBottom: "none" },
  tab: {
    position: "relative",
    backgroundColor: "#c3c5c6",
    borderRadius: "20px",
    color: "#004236",
    borderBottom: "none",
    "& + &": {
      borderLeftWidth: 0,
      ...(theme.rtl && {
        borderRightWidth: 0,
        borderLeftWidth: "1px",
      }),
    },
    "&:hover": {
      backgroundColor: "#cbd300",
      color: "#004236",
      borderColor: "#004236",
    },
    "&[data-active]": {
      zIndex: 1,
      color: "#004236",
      fontWeight: "400",
      backgroundColor: "#cbd300",
      borderColor: "#cbd300",
      "&:hover": {
        backgroundColor: "#cbd300",
        borderColor: "#004236",
      },
    },
  },
  radioButton: {
    input: {
      "&:checked": {
        backgroundColor: "#cbd300",
        borderColor: "#cbd300",
      },
    },
  },
  drawerOverlay: {
    zIndex: 300,
  },
  drawerInner: {
    zIndex: 400,
  },
  // Legalization
  scrollAreaLegalization: {
    position: "absolute !important",
    background: "#fff",
    zIndex: 1000,
    padding: "8px",
    minWidth: "278px",
    maxWidth: "350px",
    width: "360px",
    border: "1px solid #cbd300",
    borderRadius: "5px",
    marginTop: "0.5rem",
  },
}));

const cloudsAnimation = keyframes({
  "0%": { transform: "translateX(0)" },
  "50%": { transform: "translateX(100%)" },
  "100%": { transform: "translateX(0)" },
});
