import {
  Box,
  Text,
  Flex,
  Button,
  TextInput,
  Textarea,
  Grid,
  Popover,
  FileInput,
  Table,
  ScrollArea,
  NumberInput,
  Anchor,
} from "@mantine/core";
import { BiSearchAlt2 } from "react-icons/bi";
import { FaUpload } from "react-icons/fa";
import ModifyDates from "./modifyDates.component";
import AddNewExpenses from "./addNewExpenses.component";
import ReportedExpenses from "../components/reportedExpenses.component";
import { ConfirmAid } from "../components/confirmAid.component";
import { ModifyDatesAid } from "../components/modifyDatesAid.component";
import { startCase, camelCase } from "lodash";
import { LegalizationTravelExternalAidComponent } from "./legalizationTravelExternalAid.component";

export const LegalizationTravelComponent = ({
  handleSubmit,
  disableButton,
  classes,
  GlobalCard,
  form,
  handleChangeApprover,
  isAid,
  data,
  openedApprover,
  setOpenedApprover,
  openedPreApprover,
  setOpenedPreApprover,
  filteredDataExpenses,
  validateThirdUserOrIsAid,
  getAmbulanceData,
  dataLegalization,
  isEdit,
  aidData,
  utils,
  aidValue,
  cardTravel,
  formAid,
  es,
  handleTravelCardFileChange,
  newExpensesProps,
  modifyDatesProps,
  reportedExpensesProps,
  externalAidProps,
  isExternalAid,
}) => {
  const dataUserList = (data, onClick, isPrevious = false) => {
    return (
      <ScrollArea.Autosize mt={10} mah={150}>
        <Table>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Box
                    sx={{
                      padding: ".5rem",
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "#e3eb99",
                      },
                    }}
                    data-testid="list-button"
                    onClick={() => {
                      onClick(item, isPrevious);
                    }}
                  >
                    <Text>{startCase(camelCase(item.nombre))}</Text>
                    <Text>{item.codigoEmpleado}</Text>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea.Autosize>
    );
  };

  const validateServiceKey = (key) => {
    switch (key) {
      case "HOTEL":
        return "Hotel";
      case "AVION":
        return "Avión";
      case "ASISMED":
        return "Asistencia Médica";
      case "ALQVEH":
        return "Alquiler Vehículo";
    }
  };

  const tripData = isEdit
    ? dataLegalization?.[0]
    : data?.travelLiquidatorData?.ViajeTrayecto?.[0];

  const modifiedList = aidValue?.map((item) => {
    if (!tripData?.auxilioMedico?.Acompanante) {
      return {
        ...item,
        values: [item?.values?.[0]],
      };
    }
    return item;
  });

  const searchData = (data, fields, searchValue) => {
    const filterFunction = (item) => {
      const lowerValue = searchValue.toLowerCase();
      return fields.some((field) => {
        const value = item?.[field]?.toLowerCase();
        return value?.includes(lowerValue);
      });
    };
    return data?.filter(filterFunction) || [];
  };
  const searchApprovers = searchData(
    isAid ? data?.aidApprovers : data?.travelApprovers,
    ["nombre", "codigoEmpleado"],
    form.values.searchApprover
  );

  const searchPreviousApprovers = searchData(
    data?.travelPreApprovers,
    ["nombre", "codigoEmpleado"],
    form.values.searchPreApprover
  );

  const showChards = validateThirdUserOrIsAid(
    data?.travelLiquidatorData?.CodigoEmpleadoViajero,
    isAid
  );

  const hasTaxis = modifyDatesProps?.allCosts?.[0]?.some(
    (cost) => cost?.alias === "VLRTAXI"
  );

  return (
    <>
      {!isExternalAid && (
        <Box maw={{ xs: "100%", md: "80%" }} mx="auto" py={16}>
          <Text
            role="heading"
            size={24}
            color="#004236"
            fw={700}
            component="h1"
          >
            {isAid ? "Legalizar auxilio" : "Legalizar viaje"}
          </Text>

          {!isAid && (
            <>
              <Text color="#004236" size="xl" fw={500} mt={16}>
                {`Modificar fecha del viaje ${data?.travelLiquidatorData?.Id}`}
              </Text>
              <ModifyDates {...modifyDatesProps} />
            </>
          )}

          {!isAid && !data?.travelLiquidatorData?.Multiviaje && hasTaxis && (
            <>
              <Text color="#004236" size="xl" fw={500}>
                Transporte terminales
              </Text>
              <GlobalCard>
                <NumberInput
                  value={form.values.newTaxisValue}
                  onChange={modifyDatesProps?.handleChangeTaxisValue}
                  parser={utils.parserNumberInput}
                  formatter={utils.formatterNumberInput}
                  min={0}
                  classNames={{
                    input: classes.input,
                    label: classes.labelSecondary,
                  }}
                  hideControls
                  label="Cambiar el valor de Taxis"
                  description="Ingrese si tuvo un valor diferente"
                />
              </GlobalCard>
            </>
          )}

          {isAid && (
            <>
              <ModifyDatesAid
                form={formAid}
                title="Modificar fecha del auxilio Beneficiario"
                data={data?.travelLiquidatorData?.ViajeTrayecto?.[0]}
                dataLegalization={dataLegalization}
                dateType="beneficiario"
                isEdit={isEdit}
                classes={classes}
                GlobalCard={GlobalCard}
                es={es}
              />
              {aidData?.Acompanante && (
                <ModifyDatesAid
                  form={formAid}
                  title="Modificar fecha del auxilio Acompañante"
                  data={data?.travelLiquidatorData?.ViajeTrayecto[0]}
                  dataLegalization={dataLegalization?.[0]}
                  dateType="acompañante"
                  isEdit={isEdit}
                  classes={classes}
                  GlobalCard={GlobalCard}
                  es={es}
                />
              )}
            </>
          )}

          {isAid && (
            <>
              <Text color="#004236" size="xl" fw={500}>
                Tarjeta de viaje
              </Text>
              <GlobalCard>
                <FileInput
                  sx={{
                    button: {
                      overflow: "hidden",
                      span: {
                        color: "#000",
                      },
                    },
                  }}
                  classNames={{
                    input: classes.input,
                    label: classes.labelSecondary,
                  }}
                  onChange={handleTravelCardFileChange}
                  label="Adjuntar tarjeta de viaje efectivo de atención"
                  description="Adjuntar un archivo con capacidad máxima de 200 MB."
                  placeholder={
                    isEdit
                      ? String(cardTravel?.nombre) || ""
                      : cardTravel?.nombre
                  }
                  withAsterisk
                  icon={<FaUpload size="1rem" />}
                />
                {cardTravel?.UlrArchivo && (
                  <Anchor
                    mt={15}
                    href={cardTravel?.UlrArchivo}
                    c="#fd7e14"
                    target="_blank"
                    underline
                    download
                  >
                    Ver Documento
                  </Anchor>
                )}
              </GlobalCard>
            </>
          )}
          {isAid && (
            <>
              <Text color="#004236" size="xl" fw={500}>
                Valor auxilio ambulatorio
              </Text>
              <GlobalCard
                styles={{
                  padding: 0,
                }}
              >
                <Grid columns={5} p={20}>
                  {modifiedList?.map((item, index) => (
                    <Grid.Col xs={5} md={1} key={item.title}>
                      <Grid ta="center">
                        <Grid.Col xs={4} md={12}>
                          <Text color="#004236" fw={500}>
                            {item.title}
                          </Text>
                        </Grid.Col>
                        {item.values.map((value, index) => (
                          <Grid.Col xs={4} md={12} key={index}>
                            {value && (
                              <Text>
                                {item.title === "Tarifa" ||
                                item.title === "Valor"
                                  ? utils.formatter.format(value)
                                  : value}
                              </Text>
                            )}
                          </Grid.Col>
                        ))}
                      </Grid>
                    </Grid.Col>
                  ))}
                </Grid>
                <Flex
                  sx={{ background: "#F5F5F5" }}
                  gap={12}
                  px={15}
                  py={15}
                  justify="space-between"
                >
                  <Text size="lg" weight={700} color="#004236">
                    Total
                  </Text>
                  <Text size="lg" weight={700} color="#004236">
                    {utils.formatter.format(
                      isEdit
                        ? dataLegalization?.[0]?.auxilioMedico
                            ?.ValorTotalAuxilio
                        : aidData?.ValorTotalAuxilio
                    )}
                  </Text>
                </Flex>
              </GlobalCard>

              <ConfirmAid data={getAmbulanceData} GlobalCard={GlobalCard} />
            </>
          )}

          {data?.bpoBills?.serviciosBPO?.length > 0 && (
            <>
              <Text color="#004236" size="xl" fw={500}>
                Servicios de viaje
              </Text>

              {data?.bpoBills?.serviciosBPO?.map((service, index) => (
                <GlobalCard key={index}>
                  <Grid fz="sm">
                    <Grid.Col xs={3}>
                      <Text fw={700} color="#004236">
                        Nombre
                      </Text>
                      <Text>{validateServiceKey(service?.idApp)}</Text>
                    </Grid.Col>
                    <Grid.Col xs={3}>
                      <Text fw={700} color="#004236">
                        Valor
                      </Text>
                      <Text>
                        {service?.tipoMoneda === "COP"
                          ? utils.formatter.format(service?.valor)
                          : utils.formatterUSD.format(service?.valor)}
                      </Text>
                    </Grid.Col>
                    <Grid.Col xs={3}>
                      <Text fw={700} color="#004236">
                        Nit
                      </Text>
                      <Text>{service?.nitProveedor}</Text>
                    </Grid.Col>
                    <Grid.Col xs={3}>
                      <Text fw={700} color="#004236">
                        Factura
                      </Text>
                      <Flex direction="column" gap={8}>
                        {service?.facturas?.map((bill, index) => (
                          <Anchor
                            href={bill?.urlFactura}
                            c="#fd7e14"
                            target="_blank"
                            underline
                            download
                            key={index}
                          >
                            Ver Documento
                          </Anchor>
                        ))}
                      </Flex>
                    </Grid.Col>
                  </Grid>
                </GlobalCard>
              ))}
            </>
          )}
          {showChards && (
            <>
              <>
                {filteredDataExpenses?.length > 0 && (
                  <Text color="#004236" size="xl" fw={500}>
                    Gastos reportados
                  </Text>
                )}
                <ReportedExpenses {...reportedExpensesProps} />
              </>
              <AddNewExpenses {...newExpensesProps} />
              <>
                <Text color="#004236" size="xl" fw={500}>
                  Cambiar aprobador
                </Text>
                <GlobalCard>
                  <Popover
                    position="bottom-start"
                    opened={openedApprover}
                    onChange={setOpenedApprover}
                    closeOnClickOutside
                    width="target"
                  >
                    <Popover.Target>
                      <TextInput
                        {...form.getInputProps("newApprover.nombre")}
                        classNames={{
                          label: classes.labelSecondary,
                          input: classes.input,
                          item: classes.item,
                        }}
                        readOnly
                        data-testid="newApprover"
                        onClick={() => setOpenedApprover(!openedApprover)}
                        placeholder="Nombre del aprobador"
                        label="Nuevo aprobador"
                        description="Para seleccionar el nuevo aprobador, por favor, dar clic en el nombre."
                      />
                    </Popover.Target>
                    <Popover.Dropdown>
                      <TextInput
                        {...form.getInputProps("searchApprover")}
                        icon={<BiSearchAlt2 size="1rem" />}
                        placeholder="Buscar Aprobador"
                        classNames={{
                          label: classes.label,
                          input: classes.input,
                        }}
                      />
                      {dataUserList(searchApprovers, handleChangeApprover)}
                    </Popover.Dropdown>
                  </Popover>
                </GlobalCard>
              </>

              {!isAid && (
                <>
                  <Text color="#004236" size="xl" fw={500}>
                    Cambiar Pre-aprobador
                  </Text>
                  <GlobalCard>
                    <Grid align="end">
                      <Grid.Col xs={12} md={6}>
                        <TextInput
                          label="Pre-aprobador inicial"
                          value={data?.travelUserSummary?.nombre || ""}
                          classNames={{
                            input: classes.input,
                            label: classes.labelSecondary,
                          }}
                          disabled={true}
                        />
                      </Grid.Col>
                      <Grid.Col xs={12} md={6}>
                        <Popover
                          position="bottom-start"
                          opened={openedPreApprover}
                          onChange={setOpenedPreApprover}
                          closeOnClickOutside
                          width="target"
                        >
                          <Popover.Target>
                            <TextInput
                              {...form.getInputProps("newPreApprover.nombre")}
                              classNames={{
                                label: classes.labelSecondary,
                                input: classes.input,
                                item: classes.item,
                              }}
                              readOnly
                              data-testid="newPreApprover"
                              onClick={() =>
                                setOpenedPreApprover(!openedPreApprover)
                              }
                              placeholder="Nombre del Preaprobador"
                              label="Nuevo Preaprobador"
                              description="Para seleccionar el nuevo preaprobador, por favor, dar clic en el nombre."
                            />
                          </Popover.Target>
                          <Popover.Dropdown>
                            <TextInput
                              {...form.getInputProps("searchPreApprover")}
                              icon={<BiSearchAlt2 size="1rem" />}
                              placeholder="Buscar Aprobador"
                              classNames={{
                                label: classes.label,
                                input: classes.input,
                              }}
                            />
                            {dataUserList(
                              searchPreviousApprovers,
                              handleChangeApprover,
                              true
                            )}
                          </Popover.Dropdown>
                        </Popover>
                      </Grid.Col>
                    </Grid>
                  </GlobalCard>
                </>
              )}
            </>
          )}

          <>
            <Text color="#004236" size="xl" fw={500}>
              Justificación
            </Text>
            <GlobalCard>
              <Textarea
                {...form.getInputProps("justification")}
                label="Justificación"
                minRows={3}
                description="Por favor, ingrese una justificación superior a 10 caracteres"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                placeholder="Escriba la justificación de la legalización"
                withAsterisk
              />
            </GlobalCard>
          </>

          <Flex align="center" gap={12}>
            <Button
              className={classes.buttonOutline}
              fullWidth
              onClick={() => window.history.back()}
            >
              Cancelar
            </Button>
            <Button
              className={classes.button}
              fullWidth
              onClick={() => handleSubmit()}
              disabled={disableButton}
            >
              Reliquidar
            </Button>
          </Flex>
        </Box>
      )}

      {isExternalAid && (
        <Box maw={{ xs: "100%", md: "80%" }} mx="auto" py={16}>
          <LegalizationTravelExternalAidComponent {...externalAidProps} />
        </Box>
      )}
    </>
  );
};
