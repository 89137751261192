import {
  images,
  useStyles,
  publishLib,
  ModalComponent,
} from "@mfe/js-common-ave-uiutils";
import {
  useMsal,
  useIsAuthenticated,
  handleLogin,
  MsalProvider,
} from "@mfe/react-common-ave-msal";
import {
  fetchAccessTokenGraph,
  fetchRules,
  fetchMenu,
  fetchTokenApi,
  fetchHomologation,
} from "@mfe/ts-common-ave-utilitaries";
import { useEffect, useState } from "react";
import { LoginFormComponent } from "../components/loginForm.component";
import { useForm } from "@mantine/form";
import { LoadingOverlay } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import MaintenancePage from "../components/maintenancePage.component";

export const Login = ({ organization, msalInstance }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <LoginForm organization={organization} />
    </MsalProvider>
  );
};
export const LoginForm = ({ organization }) => {
  const { classes } = useStyles();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const loadingStorage = localStorage.getItem("loading") === "true";
  const isAuth = localStorage.getItem("isAuth") === "true";
  const [opened, { open, close }] = useDisclosure(false);
  const [data, setData] = useState(null);
  const isMaintenance =
    process.env.ENABLE_MAINTENANCE === "true" ? true : false;

  const disabledMessage = process.env.MESSAGE_DISABLE_AVE ?? undefined;

  const whiteList = process.env.USERSACCESSWHITELIST?.length
    ? process.env.USERSACCESSWHITELIST.split(",").map((email) =>
        email.toLowerCase()
      )
    : [];

  const isEmailAllowed = (email) => {
    const normalizedEmail = email.toLowerCase();
    return whiteList.length > 0 ? whiteList.includes(normalizedEmail) : true;
  };

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) =>
        /^[^\s@]+@(ecopetrol)+.(com.co)+$/.test(value)
          ? isEmailAllowed(value)
            ? null
            : "Usted no tiene acceso a AVE 2.0"
          : "Email inválido",
    },
  });

  const emailIdData = {
    "_AVEGEETest01@ecopetrol.com.co": process.env.USER_TEST1,
    "_AVEGEETest02@ecopetrol.com.co": process.env.USER_TEST2,
  };

  function getIdFromEmail(email) {
    return (
      emailIdData[email.userPrincipalName] || email.onPremisesSamAccountName
    );
  }

  const getAccess = async () => {
    try {
      setLoading(true);
      const responseGraph = await fetchAccessTokenGraph(instance, accounts);
      const { accessToken } = await fetchTokenApi(instance, accounts);
      let responseHomologation;
      if (
        process.env.ENVIRONMENT === "DEV" ||
        process.env.ENVIRONMENT === "QAS"
      ) {
        responseHomologation = await fetchHomologation(
          accessToken,
          responseGraph.userPrincipalName
        );
      }

      const responseMenu = await fetchMenu(
        accessToken,
        responseHomologation?.data &&
          responseHomologation?.data !== responseGraph.userPrincipalName
          ? responseHomologation?.data
          : getIdFromEmail(responseGraph),
        isAuth
      );

      const responseRules = await fetchRules(
        accessToken,
        responseMenu.data.datosUsuario
      );

      setData((prev) => ({
        ...prev,
        responseGraph,
        ...responseMenu.data,
        rules: responseRules.data,
      }));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (values) => {
    handleLogin(values.email, setLoading, instance);
  };

  useEffect(() => {
    if (loadingStorage) {
      setLoading(true);
    }
    if (isAuthenticated) {
      getAccess();
      localStorage.setItem("isAuth", "true");
    }
  }, [isAuthenticated, loadingStorage]);

  useEffect(() => {
    localStorage.setItem("loading", "false");
    localStorage.setItem("isAuth", "false");
  }, []);

  useEffect(() => {
    if (disabledMessage) open();
  }, [disabledMessage]);

  useEffect(() => {
    if (data) {
      localStorage.setItem("userData", JSON.stringify(data));
      publishLib("userData", data);
    }
  }, [data]);

  const props = { classes, form, images, handleSubmit};

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return (
    !isAuthenticated && (
      <>
        <ModalComponent
          opened={opened}
          onClose={close}
          title="Alerta"
          closeOnClickOutside={true}
          size="xl"
        >
          <div dangerouslySetInnerHTML={{ __html: disabledMessage }} />
        </ModalComponent>

        {isMaintenance ? (
          <MaintenancePage images={images} />
        ) : (
          <LoginFormComponent {...props} />
        )}
      </>
    )
  );
};
