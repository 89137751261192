import { Accordion, Grid, Text, Anchor } from "@mantine/core";

export const OtherExpenses = ({
  data,
  travelExpensesType,
  GlobalCard,
  utils,
}) => {
  const searchName = (id) => {
    return travelExpensesType?.find((item) => item?.id === Number(id))?.label;
  };

  return (
    <Grid.Col p={15}>
      <Accordion defaultValue="expenses">
        <Accordion.Item value="expenses">
          <Accordion.Control fz="sm" p={0}>
            Otros gastos
          </Accordion.Control>

          <Accordion.Panel>
            {data?.map((item, index) => (
              <GlobalCard key={index}>
                <Grid fz="sm">
                  <Grid.Col xs="auto">
                    <Text fw={700} color="#004236">
                      Nombre
                    </Text>
                    <Text>{searchName(item.GastoId)}</Text>
                  </Grid.Col>
                  <Grid.Col xs="auto">
                    <Text fw={700} color="#004236">
                      Valor
                    </Text>
                    <Text>
                      {item?.TipoMonedaId === "COP"
                        ? utils.formatter.format(item?.Valor)
                        : utils.formatterUSD.format(item?.Valor)}
                    </Text>
                  </Grid.Col>
                  <Grid.Col xs="auto">
                    <Text fw={700} color="#004236">
                      Proveedor
                    </Text>
                    <Text>{item?.Proveedor}</Text>
                  </Grid.Col>
                  <Grid.Col xs="auto">
                    <Text fw={700} color="#004236">
                      Nit
                    </Text>
                    <Text>{item?.NitProveedor}</Text>
                  </Grid.Col>
                  {item?.UlrArchivo && (
                    <Grid.Col xs="auto">
                      <Text fw={700} color="#004236">
                        Factura
                      </Text>
                      <Anchor
                        href={item?.UlrArchivo}
                        c="#fd7e14"
                        target="_blank"
                        underline
                        download
                      >
                        Ver Documento
                      </Anchor>
                    </Grid.Col>
                  )}
                </Grid>
              </GlobalCard>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Grid.Col>
  );
};
