import {
  Flex,
  Text,
  Button,
  FileInput,
  TextInput,
  LoadingOverlay,
  Anchor,
} from "@mantine/core";
import { FaUpload } from "react-icons/fa";

export const BankSupport = ({
  opened,
  close,
  form,
  handleSubmitSupports,
  item,
  classes,
  ModalComponent,
  handleAttachSupport,
  utils,
  loadingSupports,
  rejectedSupports,
}) => {
  const hasRejectedSupports = rejectedSupports?.length > 0;
  return (
    <ModalComponent
      opened={opened}
      onClose={close}
      title="Soporte Bancario"
      closeOnClickOutside={true}
    >
      <LoadingOverlay
        visible={loadingSupports}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
      <Flex direction="column" gap={12}>
        <Text ta="center">Soporte de la consignación.</Text>
        <>
          <FileInput
            mt={10}
            onChange={(fileData) => handleAttachSupport(fileData)}
            label="Adjuntar soporte de la consignación"
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            icon={<FaUpload size="1rem" />}
            data-testid="attachSupport"
            placeholder={
              hasRejectedSupports
                ? rejectedSupports?.[0]?.nombreArchivo
                : "Adjunte un archivo"
            }
            sx={{
              button: {
                overflow: "hidden",
                span: {
                  color: "#000",
                },
              },
            }}
          />
          <Text size="sm" color="#004236">
            Seleccionar un archivo con una capacidad maxima de 200 mb
          </Text>
          {hasRejectedSupports && (
            <Anchor
              c="#fd7e14"
              target="_blank"
              underline
              download
              href={rejectedSupports?.[0]?.urlArchivo}
            >
              Ver Documento
            </Anchor>
          )}
        </>
        <Button
          sx={{
            alignSelf: "end",
          }}
          className={classes.button}
          onClick={handleSubmitSupports}
        >
          Aceptar
        </Button>
      </Flex>
    </ModalComponent>
  );
};
