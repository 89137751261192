import { Box, Button, Grid, Text, Textarea } from "@mantine/core";

export const ExpensesSupportReviewComponent = ({
  classes,
  GlobalCard,
  buttonStyles,
  observationForm,
  onApprove,
  onReject,
  isBankSupportsAid,
}) => {
  const isValid = !observationForm.isValid();
  return (
    <GlobalCard styles={{ margin: 0, padding: 0 }}>
      <Grid sx={{ backgroundColor: "#004236" }} m={0} columns={12}>
        <Grid.Col span="auto">
          <Text size="md" ta="left" weight={700} color="#fff">
            Revisión soportes de {isBankSupportsAid ? "pagos" : "gastos"}:
          </Text>
        </Grid.Col>
      </Grid>

      <Box
        style={{
          padding: "15px 15px 0px 15px",
        }}
      >
        <Textarea
          {...observationForm.getInputProps("observation")}
          withAsterisk
          placeholder="Ingrese un observación"
          label="Observación"
          minRows={8}
          classNames={{
            input: classes.input,
            label: classes.labelSecondary,
          }}
        />
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
          padding: "15px",
          flexWrap: "wrap",
        }}
      >
        <Button
          fullWidth
          disabled={isValid}
          className={classes.buttonOutline}
          onClick={onApprove}
          sx={{
            ...buttonStyles,
            paddingInline: 0,
          }}
        >
          Aprobar soportes
        </Button>
        <Button
          disabled={isValid}
          fullWidth
          className={classes.button}
          onClick={onReject}
          sx={{
            ...buttonStyles,
            paddingInline: 0,
          }}
        >
          Rechazar soportes
        </Button>
      </Box>
    </GlobalCard>
  );
};
