import { Grid, Box, Text, Anchor } from "@mantine/core";

const InfoAid = ({
  data,
  extraData,
  dataAid,
  dataLiquidation,
  filterByBeneficiaryType,
  utils,
}) => {
  const col1 = [
    {
      label: "Titular",
      value: `${filterByBeneficiaryType(
        dataAid,
        "TITULAR",
        "nombres"
      )} ${filterByBeneficiaryType(dataAid, "TITULAR", "apellidos")}`,
    },
    {
      label: "Beneficiario",
      value: `${filterByBeneficiaryType(
        dataAid,
        "BENEFICIARIO",
        "nombres"
      )} ${filterByBeneficiaryType(dataAid, "BENEFICIARIO", "apellidos")}`,
    },
    {
      label: "Centro de costo",
      value: data?.codigoElementoPresupuestal,
    },
    { label: "ID auxilio", value: data?.Id, type: "text" },
    {
      label: "Ciudad inicio",
      value: data?.ViajeTrayecto?.[0]?.nombreCiudadOrigen,
    },
    {
      label: "Gestor",
      value: extraData?.funcionarioNombreGestor,
      type: "text",
    },
    ...(dataLiquidation?.tarjetaViaje?.urlArchivo
      ? [
          {
            label: "Tarjeta de viaje",
            type: "anchor",
            link: dataLiquidation?.tarjetaViaje?.urlArchivo,
          },
        ]
      : []),
    ,
  ];

  const col2 = [
    {
      label: "Cod. Empleado",
      value: data?.CodigoEmpleadoViajero,
    },
    {
      label: "Doc. Beneficiario",
      value: filterByBeneficiaryType(
        dataAid,
        "BENEFICIARIO",
        "numeroDocumento"
      ),
    },
    {
      label: "Número de referencia",
      value: data?.ViajeTrayecto?.[0]?.auxilioMedico?.NumeroRef,
    },
    {
      label: "Fecha inicio",
      value: utils.parseDateLocaleFormat(
        dataLiquidation?.liquidacion?.ViajeTrayecto?.[0]?.FechaInicio
      ),
    },
    {
      label: "Ciudad destino",
      value: data?.ViajeTrayecto?.[0]?.nombreCiudadDestino,
    },
    {
      label: "Fecha de creación",
      value: utils.parseDateLocaleFormat(extraData?.fechaCreacionViaje),
    },
  ];
  const col3 = [
    {
      label: "Doc. del titular",
      value: filterByBeneficiaryType(dataAid, "TITULAR", "numeroDocumento"),
    },
    {
      label: "Fecha de nacimiento",
      value: utils.parseDateLocaleFormat(
        filterByBeneficiaryType(dataAid, "BENEFICIARIO", "fechaNacimiento")
      ),
    },
    {
      label: "Con acompañante",
      value: data?.ViajeTrayecto?.[0]?.auxilioMedico?.Acompanante
        ? `${filterByBeneficiaryType(
            dataAid,
            "ACOMPANANTE",
            "nombres"
          )} ${filterByBeneficiaryType(dataAid, "ACOMPANANTE", "apellidos")}`
        : "No",
    },
    {
      label: "Fecha fin",
      value: utils.parseDateLocaleFormat(
        dataLiquidation?.liquidacion?.ViajeTrayecto?.[0]?.FechaFin
      ),
    },
    {
      label: "Es urgencia",
      value: data?.ViajeTrayecto?.[0]?.auxilioMedico?.EsUrgencia ? "Si" : "No",
    },
    {
      label: "Objetivo del auxilio",
      value: data?.ViajeTrayecto?.[0]?.auxilioMedico?.ObjetoAux,
    },
  ];
  return (
    <>
      <RenderCols data={col1} />
      <RenderCols data={col2} />
      <RenderCols data={col3} />
    </>
  );
};

const RenderCols = ({ data }) => {
  return (
    <Grid.Col xs={12} md={4}>
      {data?.map((item, index) => (
        <Box mb={index < data.length - 1 && 12} key={index}>
          <Text size="sm" fw={700} color="#004236">
            {item.label}:
          </Text>
          {item?.type === "anchor" ? (
            <Anchor
              href={item?.link}
              c="#fd7e14"
              target="_blank"
              underline
              download
              key={index}
            >
              Ver Documento
            </Anchor>
          ) : (
            <Text size="sm">{item.value}</Text>
          )}
        </Box>
      ))}
    </Grid.Col>
  );
};

export default InfoAid;
