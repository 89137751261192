import { useEffect, useState } from "react";
import {
  ModalComponent,
  travelExpensesList,
  GlobalCard,
  useStyles,
} from "@mfe/js-common-ave-uiutils";
import { Button, Flex, LoadingOverlay, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";

import {
  MsalProvider,
  useIsAuthenticated,
  useMsal,
} from "@mfe/react-common-ave-msal";
import {
  es,
  lodash,
  moment,
  formatter,
  isExternalFlow,
  formatterUSD,
  getAidHolderData,
  fetchSaveProvider,
  getAccessTokenApi,
  parserNumberInput,
  fetchAidBudgetData,
  fetchApproversData,
  fetchTravelByIDData,
  fetchTravelExpenses,
  fetchUserSummaryData,
  formatterNumberInput,
  fetchDepartmentsData,
  fetchAidApproversLeg,
  fetchExpensesTypeData,
  fetchLegalizationData,
  fetchProviderByIdData,
  fetchPreApproversData,
  filterByBeneficiaryType,
  travelServicesExpensesData,
  fetchAidTravelExpensesData,
  fetchCitiesByDepartmentData,
  fetchTraveLiquidatorByIDData,
  fetchOtherExpensesExternalAid,
  fetchLegalizationConditionServices,
  fetchUserAdditionalInfoByTravelData,
  fetchOtherExpensesExternalRejectAidData,
  fetchBpoBillsData,
} from "@mfe/ts-common-ave-utilitaries";
import {
  multiTripPayload,
  tripPayload,
  aidPayloadTrip,
  handleReliquidate,
  handleReliquidateMulti,
  otherExpensesExternalAidPayload,
} from "../services";

import ProviderModal from "../components/providerModal.component";
import { navigateToUrl } from "single-spa";
import { LegalizationTravelComponent } from "../components/legalizationTravel.component";

export default function LegalizationTravel({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <LegalizationTravelApp organization={organization} />
    </MsalProvider>
  );
}

export const LegalizationTravelApp = ({ organization }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { classes } = useStyles();

  const [opened, { open, close }] = useDisclosure(false);
  const [naturalPerson, setNaturalPerson] = useState(true);
  const [legalPerson, setLegalPerson] = useState(false);
  const [tokenApi, setTokenApi] = useState("");
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([]);
  const [validateDates, setValidateDates] = useState([]);
  const [allCosts, setAllCosts] = useState([]);
  const [openedApprover, setOpenedApprover] = useState(false);
  const [openedPreApprover, setOpenedPreApprover] = useState(false);
  const [reportedExpensesList, setReportedExpensesList] = useState([]);
  const [newExpenses, setNewExpenses] = useState([]);
  const [responseCreateProvider, setResponseCreateProvider] = useState(null);
  const [providerInputs, setProviderInputs] = useState([]);
  const [openProviders, setOpenProviders] = useState([]);
  const [usedTrips, setUsedTrips] = useState([]);
  const [cardTravel, setCardTravel] = useState<any>({});
  const [openedModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const [
    openedStatusModal,
    { open: openStatusModal, close: closeStatusModal },
  ] = useDisclosure(false);

  const [holderData, setHolderData] = useState(null);
  const [budgetSelected, setBudgetSelected] = useState(null);
  const [modalContent, setModalContent] = useState({
    content: "",
    showButtons: false,
    closeOnClickOutside: true,
    onClick: () => {},
  });
  const [modalStatusContent, setModalStatusContent] = useState({
    content: "",
    showButtons: false,
    closeOnClickOutside: true,
    onClick: () => {},
  });

  const userData = JSON.parse(localStorage.getItem("userData"));

  const isEditExternalAid = JSON.parse(
    localStorage.getItem("isRejectedSupports")
  );

  const validateFlow = JSON.parse(localStorage.getItem("externalFlow"));
  const isAidPermission = userData?.datosUsuario?.permisosUnicos;

  const isExternalAid = isExternalFlow(isAidPermission) && validateFlow;

  const [data, setData] = useState<any>({
    travelExpensesType: [],
    travelExpenses: [],
    departments: [],
    cities: [],
    travelLiquidatorData: {},
    listOfProviders: [],
    travelApprovers: [],
    aidApprovers: [],
    travelPreApprovers: [],
    travelUserSummary: {},
    travelStatus: {},
    dataAid: [],
    legalization: {},
    aidBudget: [],
    bpoBills: {},
    expensesExternalAidData: [],
  });

  const formAid = useForm({
    initialValues: {
      beneficiaryValues: {
        initial: null,
        end: null,
        return: false,
        aidUsed: false,
      },
      acompanionValues: {
        initial: null,
        end: null,
        return: false,
        aidUsed: false,
        companionliquid: false,
      },
    },
  });

  const form = useForm({
    initialValues: {
      initialDate: new Date(),
      finalDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      finalValueSupplier: "",
      idSupplier: "",
      bill: null,
      unusedExpense: false,
      newApprover: {
        nombre: "",
        codigoEmpleado: "",
      },
      newPreApprover: {
        nombre: "",
        codigoEmpleado: "",
      },
      justification: "",
      newExpense: "",
      newExpenseValue: "",
      idSupplierNewExpense: "",
      idSupplierNewExpenseSearch: "",
      billNewExpense: null,
      searchApprover: "",
      searchPreApprover: "",
      isDollar: false,
      providerNameNewExpense: "",
      newTaxisValue: "",
    },
    validate: {
      newExpense: (value) => (value.length ? null : "El gasto es obligatorio"),
      newExpenseValue: (value) =>
        value ? null : "El valor del gasto es obligatorio",
      idSupplierNewExpense: (value) =>
        value.length ? null : "El id del proveedor es obligatorio",
      billNewExpense: (value) => (value ? null : "La factura es obligatoria."),
      providerNameNewExpense: (value) =>
        value.length ? null : "El id del proveedor es obligatorio",
      justification: (value) =>
        value.length >= 10 ? null : "La justificación es obligatoria.",
    },
  });

  const providerForm = useForm({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      nit: "",
      verificationDigit: "",
      companyName: "",
      id: "",
      firstName: "",
      secondName: "",
      lastFirstName: "",
      lastSecondName: "",
      address: "",
      department: "",
      city: "",
      phone: "",
      email: "",
    },
    validate: {
      nit: (value) =>
        legalPerson ? (value ? null : "El NIT es obligatorio") : null,
      verificationDigit: (value) =>
        legalPerson
          ? value
            ? null
            : "El código de verificación es requerido"
          : null,
      companyName: (value) =>
        legalPerson ? (value ? null : "El nombre es obligatorio") : null,
      id: (value) =>
        naturalPerson
          ? value
            ? null
            : "El documento de identidad es obligatorio"
          : null,
      firstName: (value) =>
        naturalPerson ? (value ? null : "El nombre es obligatorio") : null,
      lastFirstName: (value) =>
        naturalPerson ? (value ? null : "El Apellido es obligatorio") : null,
      lastSecondName: (value) =>
        naturalPerson ? (value ? null : "El Apellido es obligatorio") : null,
      address: (value) => (value ? null : "La dirección es obligatoria"),
      department: (value) => (value ? null : "El departamento es obligatorio"),
      city: (value) => (value ? null : "La ciudad es obligatoria"),
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Ingrese un email valido",
      phone: (value) =>
        value?.length > 0
          ? /^[0-9]*$/.test(value)
            ? null
            : "Escriba un número válido."
          : "El telefono es obligatorio.",
    },
  });

  const dataLiquidation = data?.travelLiquidatorData;

  const isAid = dataLiquidation?.TipoViajeAlias === "TVAMBULATORIOS";

  const isTurn = dataLiquidation?.TipoViajeAlias === "TVTURNOS";

  const isEdit = localStorage.getItem("isEdit");

  const dataLegalization = data?.legalization?.data?.liquidacion?.ViajeTrayecto;

  const dataLiquidationTrayect = dataLiquidation?.ViajeTrayecto;

  const dataTravel = data?.travelStatus?.listaDestinos;

  const expensesExternalAidData = data?.expensesExternalAidData;

  const isCNT =
    userData?.datosUsuario?.permisosUnicos?.includes("legalizacion Cenit");

  const getliquidationID = localStorage.getItem("liquidationID");

  const validateThirdUserOrIsAid = (data, isAid) => {
    const regex = /^[0-9]+$/;
    return isCNT && !isAid
      ? true
      : isAid
      ? isAid
      : isTurn
      ? !isTurn
      : !regex.test(data);
  };

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
    form.reset();
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        getliquidationID,
        setLoading
      );

      fetchDepartmentsData(setData, tokenApi);
    }
  }, [tokenApi]);

  useEffect(() => {
    if (tokenApi && isEditExternalAid && isExternalAid) {
      fetchOtherExpensesExternalRejectAidData(
        setData,
        tokenApi,
        setLoading,
        getliquidationID
      );
    }
  }, [tokenApi]);

  useEffect(() => {
    if (dataLiquidationTrayect?.length) {
      fetchTravelByIDData(tokenApi, dataLiquidation?.Id, setData, setLoading);
      fetchBpoBillsData(tokenApi, data?.travelLiquidatorData?.Id, setData);
      if (isEdit && Object?.keys(data?.travelStatus)?.length) {
        fetchLegalizationData(
          setData,
          tokenApi,
          data?.travelStatus?.liquidadorIdLiquidacion,
          setLoading
        );
      }
    }
  }, [
    dataLiquidationTrayect?.length,
    Object?.keys(data?.travelStatus)?.length,
  ]);

  const travelLiquidationServicesExpensesData = async () => {
    try {
      const filteredDataMap = isEdit
        ? dataLegalization
        : dataLiquidationTrayect;

      const isMultitravel = dataLiquidation?.Multiviaje
        ? filteredDataMap
        : [filteredDataMap?.[0]];

      const promises =
        isMultitravel?.map(async (item) => {
          const payload = {
            travelType: dataLiquidation?.TipoViajeId,
            travelCommission: dataLiquidation?.ComisionId,
            tipoUsuario: dataLiquidation?.Perfil,
            idCategoria: dataLiquidation?.Categoria,
            originValue: item?.CodigoIataorigen,
            destinationValue: item?.CodigoIatadestino,
          };
          const response = await fetchTravelExpenses(tokenApi, payload);

          return {
            response,
            item,
          };
        }) || [];

      const results = await Promise?.all(promises);
      const mappedResults = results?.map((result) => {
        const mapResult = {
          ...result.item,
          viaticosMap: travelServicesExpensesData(
            result.response.data.map((response) => ({
              id: response.id,
              alias: response.alias,
              title: response.nombre,
              value: response.alias,
              Valor: result.item.Viatico?.find(
                ({ AliasValorViatico }) => AliasValorViatico === response.alias
              )?.Valor,
              disabled: !isMultitravel?.length
                ? true
                : !result.item.Viatico?.some(
                    ({ AliasValorViatico }) =>
                      AliasValorViatico === response.alias
                  ),
            })),
            travelExpensesList,
            false
          ),
        };
        return mapResult;
      });

      const newState = mappedResults.map((result) => {
        return result.viaticosMap;
      });
      setAllCosts(newState);
      if (
        !isAid &&
        !data?.travelLiquidatorData?.Multiviaje &&
        newState?.[0]?.some((cost) => cost?.alias === "VLRTAXI")
      ) {
        const taxiValue = newState?.[0]?.find(
          (cost) => cost?.alias === "VLRTAXI"
        )?.Valor;

        form.setFieldValue("newTaxisValue", taxiValue || 0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mapExpenses = (data) =>
    data?.map((item) => ({
      ...item,
      GastoUtilizado: true,
    }));

  const { ViajeGasto, ViajeTrayecto } = dataLiquidation;

  const isUrlArchivo = ViajeGasto?.some(
    (item) => item.UlrArchivo && item.UlrArchivo.trim() !== ""
  );

  const isTarjetaViajeAuxilio = ViajeTrayecto?.some(
    (trayecto) =>
      trayecto.TarjetaViajeAuxilio &&
      Object.keys(trayecto.TarjetaViajeAuxilio).length > 0
  );

  const reportExpensesLiquidation = mapExpenses(dataLiquidation?.ViajeGasto);

  const reportExpensesLegalization = mapExpenses(
    data?.legalization?.data?.liquidacion?.ViajeGasto
  );

  const otherExpensesExternalAid = data?.expensesExternalAidData?.otherExpenses;

  const filteredDataExpenses =
    isEditExternalAid && isExternalAid
      ? otherExpensesExternalAid
      : isEdit
      ? reportExpensesLegalization
      : reportExpensesLiquidation;

  const attachCardAux = (fileData) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = () => {
        let base = reader.result as string;
        base = base.split(",")[1];
        resolve(base);
      };
      reader.onerror = reject;
    });
  };

  const fetchAndConvertToBase64 = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const base64 = await attachCardAux(blob);
      return base64;
    } catch (error) {
      console.error(error);
    }
  };

  const transformUrlExpensesToBase = async () => {
    try {
      const urlPromises = filteredDataExpenses?.map((url, index) =>
        fetchAndConvertToBase64(url?.UlrArchivo).then((base64) => {
          return base64;
        })
      );

      const base64Results = await Promise.all(urlPromises);

      setReportedExpensesList(
        filteredDataExpenses?.map((item, index) => ({
          ...item,
          base64File: base64Results[index],
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const transformUrlCardTravelLegalizationToBase = async () => {
    try {
      const urlCardTravel =
        data?.legalization?.data?.tarjetaViaje?.nombreArchivo?.urlArchivo;

      const base64CardTravel = await fetchAndConvertToBase64(urlCardTravel);

      setCardTravel({
        idViaje: dataLiquidation?.Id,
        codEmpleado: dataLiquidation?.CodigoEmpleadoViajero,
        nombre:
          data?.legalization?.data?.tarjetaViaje?.nombreArchivo?.split(".")[0],
        extension:
          data?.legalization?.data?.tarjetaViaje?.nombreArchivo?.split(".")[2],
        archivo: base64CardTravel,
        UlrArchivo: data?.legalization?.data?.tarjetaViaje?.urlArchivo,
        validar: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loadTravelCardData = async () => {
    try {
      const tarjetaViajeAuxilio = isTarjetaViajeAuxilio
        ? ViajeTrayecto?.[0]?.TarjetaViajeAuxilio
        : data?.expensesExternalAidData?.travelCard;

      if (!tarjetaViajeAuxilio) {
        return;
      }

      const { IdAuxilio, NombreArchivo, UlrArchivo } = tarjetaViajeAuxilio;
      const base64 = await fetchAndConvertToBase64(UlrArchivo);
      setCardTravel({
        idViaje: IdAuxilio,
        codEmpleado: dataLiquidation?.CodigoEmpleadoViajero,
        nombre: NombreArchivo ? NombreArchivo.split(".")[0] : "",
        extension: NombreArchivo ? NombreArchivo.split(".")[1] : "",
        archivo: base64,
        UlrArchivo: UlrArchivo,
        validar: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const noveltyExpenses = data?.travelStatus?.novedades
    ?.filter((novelty) => novelty?.IdEstado === "APROBADO")
    ?.flatMap((nov) => nov?.Gastos)
    ?.map((item) => ({
      Nombre: item?.NombreGasto,
      Proveedor: "",
      NitProveedor: "",
      TipoMonedaId: item?.Moneda,
      Valor: item?.Valor,
      UlrArchivo: "",
      GastoId: item?.FkIdOtroGasto,
      EsNovedad: item?.EsNovedad,
      alias: item?.Alias,
    }));

  const providersData = () => {
    setProviderInputs(
      isUrlArchivo
        ? filteredDataExpenses?.map(({ NitProveedor }) => NitProveedor)
        : Array(dataLiquidation?.ViajeGasto?.length).fill("")
    );
    setOpenProviders(
      Array(dataLiquidation?.ViajeGasto?.length).fill(
        isUrlArchivo ? false : true
      )
    );
  };

  const isPreviousApprover = () =>
    dataLiquidation?.CodigoEmpleadoaprobadorPrevio &&
    fetchUserSummaryData(
      setData,
      setLoading,
      tokenApi,
      dataLiquidation?.CodigoEmpleadoaprobadorPrevio
    );

  const isAidData = () => {
    fetchUserAdditionalInfoByTravelData(setData, tokenApi, getliquidationID);
    fetchAidTravelExpensesData(setData, tokenApi);
    getAidHolderData(
      setHolderData,
      tokenApi,
      {
        register: dataLiquidation?.CodigoEmpleadoViajero,
        alias: "",
        doc: "",
      },
      setLoading
    );
    transformUrlExpensesToBase();
    !isEdit && loadTravelCardData();
  };

  const isExternalAidData = () => {
    fetchUserAdditionalInfoByTravelData(setData, tokenApi, getliquidationID);
    setProviderInputs(
      filteredDataExpenses.map(({ NitProveedor }) => NitProveedor)
    );
    setReportedExpensesList(filteredDataExpenses);
    transformUrlExpensesToBase();
    loadTravelCardData();
  };

  const isEditLegalization = async () => {
    form.setValues({
      justification: data?.legalization?.data?.justificacion,
    });
    setProviderInputs(
      filteredDataExpenses.map(({ NitProveedor }) => NitProveedor)
    );
    setDates(
      data?.legalization?.data?.liquidacion?.ViajeTrayecto?.map((item) => [
        moment(item.FechaInicio).toDate(),
        moment(item.FechaFin).toDate(),
      ])
    );
    setOpenProviders(
      Array(data?.legalization?.data?.liquidacion?.ViajeGasto?.length).fill(
        false
      )
    );
    setReportedExpensesList(filteredDataExpenses);
    transformUrlExpensesToBase();
    transformUrlCardTravelLegalizationToBase();
  };

  const noveltyDates = data?.travelStatus?.novedades
    ?.filter((novelty) => novelty?.IdEstado === "APROBADO")
    ?.flatMap((nov) => nov?.Trayectos)
    ?.map((item, index) => ({
      FechaInicio: dataLiquidation?.ViajeTrayecto?.[index]?.FechaInicio,
      FechaFin: item?.FechaFinalPorNovedad,
    }));

  const travelLiquidationData = () => {
    if (dataLiquidation?.Multiviaje) {
      setUsedTrips(Array(dataLiquidation?.ViajeTrayecto?.length).fill(true));
    }

    if (isAid) {
      isAidData();
    } else if (
      validateThirdUserOrIsAid(dataLiquidation?.CodigoEmpleadoViajero, isAid)
    ) {
      fetchExpensesTypeData(setData, tokenApi);
      fetchApproversData(
        setData,
        tokenApi,
        dataLiquidation?.TipoViajeId,
        dataLiquidation?.CodigoEmpleadoViajero,
        dataLiquidation?.codigoCargoViajero,
        data?.travelStatus?.TipoFuncionarioViajero,
        setLoading
      );
      fetchPreApproversData(
        setData,
        tokenApi,
        dataLiquidation?.TipoViajeId,
        dataLiquidation?.CodigoEmpleadoViajero,
        dataLiquidation?.ComisionId,
        dataLiquidation?.codigoCargoViajero,
        data?.travelStatus?.TipoFuncionarioViajero,
        setLoading
      );
    }

    setDates(
      (noveltyDates?.length > 0
        ? noveltyDates
        : dataLiquidation?.ViajeTrayecto
      )?.map((item) => [
        moment(item.FechaInicio).toDate(),
        moment(item.FechaFin).toDate(),
      ])
    );

    setValidateDates(
      !dataLiquidation?.Multiviaje
        ? [false]
        : Array.from({ length: dataLiquidation?.ViajeGasto?.length }, () => [
            false,
          ])
    );

    setReportedExpensesList(
      [...dataLiquidation?.ViajeGasto, ...noveltyExpenses]?.map((item) => ({
        ...item,
        GastoUtilizado: true,
      }))
    );
    providersData();
    isPreviousApprover();
    !isAid && travelLiquidationServicesExpensesData();
  };

  useEffect(() => {
    if (dataLiquidationTrayect?.length && dataTravel?.length) {
      travelLiquidationData();
    }

    if (isEditExternalAid && filteredDataExpenses && isExternalAid) {
      isExternalAidData();
    }

    if (isEdit && dataLegalization?.length) {
      isEditLegalization();
    }
  }, [
    isEdit,
    dataLiquidationTrayect?.length,
    dataLegalization?.length,
    dataTravel?.length,
    isEditExternalAid,
  ]);

  useEffect(() => {
    if (holderData) {
      fetchAidBudgetData(
        tokenApi,
        setData,
        holderData?.[0]?.regional,
        holderData?.[0]?.ciudad
      );
    }
  }, [holderData]);

  useEffect(() => {
    if (dataLiquidationTrayect?.length > 0 && data?.aidBudget?.length) {
      const budgetSelected = data?.aidBudget?.filter(
        (item) => item.label === dataLiquidation?.codigoElementoPresupuestal
      );
      setBudgetSelected(budgetSelected[0]);
    }
  }, [data?.aidBudget?.length, dataLiquidationTrayect]);

  useEffect(() => {
    if (budgetSelected) {
      const [budgetCode, budgetElement, budgetCodRegional, budgetLocality] =
        budgetSelected?.value?.split("/") || "";
      fetchAidApproversLeg(tokenApi, setData, {
        budgetCodRegional: budgetCodRegional,
        budgetLocality: budgetLocality,
      });
    }
  }, [budgetSelected]);

  useEffect(() => {
    if (data?.travelUserSummary?.nombre) {
      form.setFieldValue("newPreApprover", {
        nombre: lodash.startCase(
          lodash.camelCase(data?.travelUserSummary?.nombre)
        ),
        codigoEmpleado: data?.travelUserSummary?.codigoEmpleado,
      });
    }
  }, [data?.travelUserSummary?.nombre]);

  useEffect(() => {
    if (!data?.travelApprovers?.length || !data?.aidApprovers?.length) {
      form.setFieldValue("newApprover", {
        nombre: lodash.startCase(
          lodash.camelCase(dataLiquidation?.NombreEmpleadoAprobador)
        ),
        codigoEmpleado: dataLiquidation?.CodigoEmpleadoAprobador,
      });
    }
  }, [data?.travelApprovers?.length, data?.aidApprovers?.length]);

  useEffect(() => {
    !opened && providerForm.reset();
    providerForm.setValues((prev) => ({
      ...prev,
      nit: prev.nit.replace(/[^0-9]/g, ""),
      verificationDigit: prev.verificationDigit.replace(/[^0-9]/g, ""),
      id: prev.id.replace(/[^0-9]/g, ""),
    }));
  }, [
    providerForm.values.nit,
    providerForm.values.verificationDigit,
    providerForm.values.id,
    opened,
  ]);

  const handleChangeApprover = (item, isPrevious) => {
    if (isPrevious) {
      form.setFieldValue("newPreApprover", {
        nombre: lodash.startCase(lodash.camelCase(item.nombre)),
        codigoEmpleado: item.codigoEmpleado,
      });
    } else {
      form.setFieldValue("newApprover", {
        nombre: lodash.startCase(lodash.camelCase(item.nombre)),
        codigoEmpleado: item.codigoEmpleado,
      });
    }
    isPrevious
      ? setOpenedPreApprover(!openedPreApprover)
      : setOpenedApprover(!openedApprover);
  };

  const handleCostChange = (alias, index) => {
    const costs = [...allCosts];
    const updatedCosts = costs[index].map((item) => {
      const aliasType = !item?.AliasValorViatico?.length
        ? item?.alias
        : item?.AliasValorViatico;
      if (aliasType === alias) {
        return { ...item, disabled: !item.disabled };
      } else {
        return item;
      }
    });
    costs[index] = updatedCosts;
    setAllCosts(costs);
  };

  const aidData = dataLiquidation?.ViajeTrayecto?.[0]?.auxilioMedico;

  const allServicesBpo = lodash.flatMap(
    dataLiquidation?.ViajeTrayecto,
    ({ ServicioBpo }) => ServicioBpo
  );

  const getAmbulanceData = allServicesBpo.filter(
    ({ AliasServicio }) => AliasServicio === "AMBULANCIA"
  );

  const aidValue = [
    {
      title: "Nombre",
      values: [
        lodash.startCase(
          lodash.camelCase(
            `${filterByBeneficiaryType(
              data?.dataAid,
              "BENEFICIARIO",
              "nombres"
            )}  ${filterByBeneficiaryType(
              data?.dataAid,
              "BENEFICIARIO",
              "apellidos"
            )}`
          )
        ),
        lodash.startCase(
          lodash.camelCase(
            `${filterByBeneficiaryType(
              data?.dataAid,
              "ACOMPANANTE",
              "nombres"
            )} ${filterByBeneficiaryType(
              data?.dataAid,
              "ACOMPANANTE",
              "apellidos"
            )}`
          )
        ),
      ],
    },
    {
      title: "Categoría",
      values: [
        "Beneficiario",
        dataLiquidationTrayect?.[0]?.auxilioMedico?.Acompanante
          ? "Acompañante"
          : "",
      ],
    },
    {
      title: "Tarifa",
      values: isEdit
        ? [
            dataLegalization?.[0]?.auxilioMedico?.PerBeneficiario
              ?.totalTarifaPersona,
            dataLegalization?.[0]?.auxilioMedico?.PerAcompanante
              ?.totalTarifaPersona,
          ]
        : [
            aidData?.PerBeneficiario?.totalTarifaPersona,
            aidData?.PerAcompanante?.totalTarifaPersona,
          ],
    },
    {
      title: "Días",
      values: isEdit
        ? [
            dataLegalization?.[0]?.auxilioMedico?.PerBeneficiario
              ?.numeroDiasPernocte +
              dataLegalization?.[0]?.auxilioMedico?.PerBeneficiario
                ?.numeroDiasRetorno,
            dataLegalization?.[0]?.auxilioMedico?.PerAcompanante
              ?.numeroDiasPernocte +
              dataLegalization?.[0]?.auxilioMedico?.PerAcompanante
                ?.numeroDiasRetorno,
          ]
        : [
            aidData?.PerBeneficiario?.numeroDiasPernocte +
              aidData?.PerBeneficiario?.numeroDiasRetorno,
            aidData?.PerAcompanante?.numeroDiasPernocte +
              aidData?.PerAcompanante?.numeroDiasRetorno,
          ],
    },
    {
      title: "Valor",
      values: isEdit
        ? [
            dataLegalization?.[0]?.auxilioMedico?.PerBeneficiario
              ?.totalTarifaPersona,
            dataLegalization?.[0]?.auxilioMedico?.PerAcompanante
              ?.totalTarifaPersona,
          ]
        : [
            aidData?.PerBeneficiario?.totalTarifaPersona,
            aidData?.PerAcompanante?.totalTarifaPersona,
          ],
    },
  ];

  const handleSaveProvider = (values) => {
    fetchSaveProvider(setResponseCreateProvider, tokenApi, {
      ...values,
      documentType: naturalPerson ? 1 : 2,
      id: naturalPerson ? values.id : values.nit,
      name: naturalPerson ? values.firstName : values.companyName,
      city: Number(values.city.split("/")[2]),
    });
    close();
    providerForm.reset();
  };

  const handleDeleteExpense = (indexExpense) => {
    const newState = [...newExpenses];
    setNewExpenses(newState.filter((_, index) => index !== indexExpense));
  };

  useEffect(() => {
    if (providerForm.isDirty("department")) {
      providerForm.setFieldValue("city", "");
      fetchCitiesByDepartmentData(setData, tokenApi, {
        id: providerForm.values.department.split("/")[0],
      });
    }
  }, [providerForm.values.department]);

  useEffect(() => {
    providerForm.reset();
  }, [naturalPerson]);

  const handleInputChange = (index, field, value) => {
    const updatedExpensesList = [...reportedExpensesList];
    let currentExpense = updatedExpensesList[index];
    if (field === "base64File" && value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        const base = reader.result as string;
        currentExpense[field] = base.split(",")[1];
      };
    } else {
      currentExpense[field] = value;
    }
    setReportedExpensesList(updatedExpensesList);
  };

  const debouncedFetch = lodash.debounce(
    (value) =>
      fetchProviderByIdData(
        setData,
        tokenApi,
        value,
        setModalContent,
        openModal,
        setOpenProviders
      ),
    500
  );

  const handleChangeProviderReported = (event, index) => {
    const { value } = event.currentTarget;
    const updatedList = [...providerInputs];
    const openedList = [...openProviders];
    updatedList[index] = value;
    setProviderInputs(updatedList);
    if (value.length > 2) {
      debouncedFetch(value);
      openedList[index] = true;
      setOpenProviders(openedList);
    } else {
      openedList[index] = false;
      setOpenProviders(openedList);
    }
  };

  const handleInputProviderChange = (event) => {
    const { value } = event.currentTarget;
    form.setFieldValue("providerNameNewExpense", "");
    form.setFieldValue("idSupplierNewExpense", value);
    if (value.length > 2) {
      debouncedFetch(value);
    }
  };

  const gotoLegalization = () => {
    isExternalAid
      ? navigateToUrl("/solicitudes-auxilio-autogestion")
      : navigateToUrl("/detalle-legalizacion"),
      localStorage.setItem("liquidationID", dataLiquidation?.Id);
  };

  const postOtherExpensesExternalAid = async (payload) => {
    try {
      setLoading(true);
      const response = await fetchOtherExpensesExternalAid(tokenApi, payload);
      if (response.status === 200) {
        setModalContent({
          content: "La verificación se ha enviado con éxito.",
          showButtons: false,
          closeOnClickOutside: true,
          onClick: () => {},
        });
        setTimeout(() => {
          navigateToUrl("/solicitudes-auxilio-autogestion");
        }, 1000);
      } else {
        const errorData = response.data;
        throw new Error(
          errorData.message || "Error al enviar la verificación."
        );
      }
    } catch (error) {
      setModalContent({
        content: error.message,
        showButtons: false,
        closeOnClickOutside: true,
        onClick: () => {},
      });
    } finally {
      setLoading(false);
      openModal();
    }
  };

  const handleAddExpense = () => {
    const reader = new FileReader();
    reader.readAsDataURL(form.values.billNewExpense);

    reader.onload = () => {
      const base = reader.result as string;
      const newExpense = {
        tipoGasto: form.values.newExpense,
        valorFinal: form.values.newExpenseValue,
        nombreProveedor: form.values.providerNameNewExpense,
        nitProveedor: form.values.idSupplierNewExpense,
        archivoFactura: form.values.billNewExpense,
        base: base.split(",")[1],
        isDollar: form.values.isDollar,
      };

      setNewExpenses((prev) => [...prev, newExpense]);

      form.setValues((prev) => ({
        ...prev,
        newExpense: "",
        newExpenseValue: "",
        providerNameNewExpense: "",
        idSupplierNewExpense: "",
        billNewExpense: null,
        isDollar: false,
      }));
    };
  };

  const handleChangeUsedTrips = (event, index) => {
    const { checked } = event.currentTarget;
    const newState = [...usedTrips];
    newState[index] = checked;
    setUsedTrips(newState);
  };

  const handleChangeTaxisValue = (value) => {
    form.setFieldValue("newTaxisValue", value);
    setAllCosts((prev) => {
      const updatedCosts = [...prev];
      updatedCosts[0] = updatedCosts[0].map((cost) => {
        if (cost?.alias === "VLRTAXI")
          return {
            ...cost,
            ValorFinal: value,
          };
        return cost;
      });
      return updatedCosts;
    });
  };

  const handleSubmit = async () => {
    const payload = {
      form,
      travelId: dataLiquidation?.Id,
      travelerId: dataLiquidation?.CodigoEmpleadoViajero,
      traveler: dataLiquidation?.NombreEmpleadoViajero,
      services: dataLiquidation?.ViajeTrayecto?.flatMap(
        (item) => item.ServicioBpo
      ),
      travelCosts: allCosts,
      otherExpenses: [
        ...reportedExpensesList,
        ...newExpenses.map((item) => ({
          ...item,
          Proveedor: item.nombreProveedor,
          Valor: item.valorFinal,
          NombFactura: item.archivoFactura,
          base64File: item.base,
          alias: item.tipoGasto.split("/")[2],
          NitProveedor: item.nitProveedor,
          GastoId: item.tipoGasto.split("/")[0],
          Nombre: item.tipoGasto.split("/")[1],
          GastoUtilizado: true,
          TipoMonedaId: item.isDollar ? "USD" : "COP",
          TipoGasto: "NUEVO",
        })),
      ],
      travelInfo: data?.travelStatus,
      dates,
      trips: dataLiquidation?.ViajeTrayecto,
      isEdit: isEdit,
      usedTrips,
      ...(isAid && {
        dataAid: aidData,
        formAid: formAid.values,
        cardTravel: cardTravel,
      }),
    };
    if (
      data?.travelStatus?.serviciosViaje?.length > 0 &&
      /^[0-9]+$/.test(dataLiquidation?.CodigoEmpleadoViajero) &&
      !isAid
    )
      try {
        setLoading(true);
        const { data: supports } = await fetchLegalizationConditionServices(
          tokenApi,
          data?.travelStatus?.id
        );
        if (supports?.data?.soportesCompletosBpo) {
          dataLiquidation?.Multiviaje
            ? handleReliquidateMulti(
                multiTripPayload(payload),
                tokenApi,
                setLoading,
                openModal,
                setModalContent,
                data
              )
            : handleReliquidate(
                tripPayload(payload),
                tokenApi,
                setLoading,
                openModal,
                setModalContent,
                data
              );
        } else {
          setLoading(false);
          setModalContent({
            content: supports?.data?.detSoportesFaltantes,
            showButtons: false,
            onClick: () => {},
            closeOnClickOutside: true,
          });
          openModal();
        }
      } catch (error) {
        setLoading(false);
        setModalContent({
          content: "Ocurrió un error, inténtelo de nuevo.",
          showButtons: false,
          onClick: () => {},
          closeOnClickOutside: true,
        });
      }
    else {
      dataLiquidation?.Multiviaje
        ? handleReliquidateMulti(
            multiTripPayload(payload),
            tokenApi,
            setLoading,
            openModal,
            setModalContent,
            data
          )
        : handleReliquidate(
            isAid ? aidPayloadTrip(payload) : tripPayload(payload),
            tokenApi,
            setLoading,
            openModal,
            setModalContent,
            data
          );
    }
  };

  const handleAttachCardAux = async (fileData) => {
    const result = await attachCardAux(fileData);
    const rest = {
      idViaje: dataLiquidation?.Id,
      codEmpleado: dataLiquidation?.CodigoEmpleadoViajero,
      nombre: fileData.name.split(".")[0],
      extension: fileData.name.split(".")[1],
      archivo: result,
      validar: true,
    };
    setCardTravel(rest);
  };

  const validateReportedExpensesList = (reportedExpensesList) => {
    if (isEdit) {
      return (
        providerInputs?.some(
          (input) =>
            !reportedExpensesList?.some(
              (expense) =>
                expense?.NitProveedor === input && expense?.Valor !== ""
            )
        ) || reportedExpensesList?.some((expense) => expense?.Valor === "")
      );
    } else {
      return reportedExpensesList?.some(
        (item) =>
          (item?.NitProveedor === "" && item?.GastoUtilizado === true) ||
          (item?.NombFactura === "" && item?.GastoUtilizado === true)
      );
    }
  };
  const disableButton =
    validateReportedExpensesList(reportedExpensesList) === false &&
    !form.isValid("justification") === false
      ? false
      : true;

  const checkFileSize = (
    fileData,
    onFailded,
    onSuccess,
    hasToShowModal = false
  ) => {
    const maxSize = 200 * 1024 * 1024;
    if (fileData?.size > maxSize) {
      setModalContent({
        content:
          "El archivo excede el límite de 200 MB, por favor ingrese otro archivo.",
        showButtons: false,
        closeOnClickOutside: true,
        onClick: () => {},
      });
      openModal();
      onFailded;
    } else {
      onSuccess;
      if (hasToShowModal && isCNT) {
        setModalContent({
          content:
            'Recuerde diligenciar de manera correcta los campos "NIT" y "Razón Social" del proveedor tal como está indicado en la factura de venta que va a adjuntar. Tenga en cuenta que, si la información del NIT y Razón Social es errada, la compañía no podrá tomar como deducción el gasto asociado en la declaración de renta',
          showButtons: false,
          closeOnClickOutside: true,
          onClick: () => {},
        });
        openModal();
      }
    }
  };

  const handleTravelCardFileChange = (fileData) => {
    checkFileSize(fileData, "", handleAttachCardAux(fileData));
  };

  const handleNewExpensesFileChange = (fileData) => {
    checkFileSize(
      fileData,
      form.setFieldValue("billNewExpense", null),
      form.setFieldValue("billNewExpense", fileData),
      true
    );
  };

  const handleReportedExpensesFileChange = (fileData, index) => {
    checkFileSize(
      fileData,
      (handleInputChange(index, "base64File", ""),
      handleInputChange(index, "NombFactura", "")),
      (handleInputChange(index, "base64File", fileData),
      handleInputChange(index, "NombFactura", fileData)),
      true
    );
  };

  const handleClickListOfProviders = (index, item) => {
    let openedList = [...openProviders];
    let inputs = [...providerInputs];
    inputs[index] = item.value.split("/")[1];
    setProviderInputs(inputs);
    handleInputChange(index, "Proveedor", item.label);
    handleInputChange(index, "NitProveedor", item.value.split("/")[1]);
    setData((prev) => ({
      ...prev,
      listOfProviders: [],
    }));
    openedList[index] = false;
    setOpenProviders(openedList);
  };

  const handleModifyInitialDate = (value, index) => {
    const newValidateDates = [...dates];
    newValidateDates[index] = true;
    setValidateDates(newValidateDates);
    const newDates = [...dates];
    newDates[index][0] = value;
    setDates(newDates);
  };

  const handleModifyEndDate = (value, index) => {
    const newDates = [...dates];
    newDates[index][1] = value;
    setDates(newDates);
  };

  const openStatusModalAlert = () => {
    setModalStatusContent({
      content: "Los soportes han sido enviados para validación",
      showButtons: true,
      closeOnClickOutside: false,
      onClick: () => {
        closeStatusModal();
      },
    });
    openStatusModal();
  };
  const isInternational = dataLiquidation?.TipoViajeAlias === "TVINTERNCL";

  const handleSubmitExternalAid = async () => {
    const payload = otherExpensesExternalAidPayload(
      cardTravel,
      reportedExpensesList
    );
    await postOtherExpensesExternalAid(payload);
  };

  const validate = (lista) => {
    return lista.every((item) => {
      const hasNit = item.NitProveedor !== "";
      const hasArchive = item.NombFactura !== "";

      return (hasNit && hasArchive) || (!hasNit && !hasArchive);
    });
  };

  const disabledButtonExternal =
    !cardTravel?.nombre?.length || !validate(reportedExpensesList);

  const statusExpensesProps = {
    classes,
    userData,
    GlobalCard,
    expensesExternalAidData,
  };

  const utils = {
    formatterUSD,
    formatter,
    parserNumberInput,
    formatterNumberInput,
  };

  const newExpensesProps = {
    data,
    form,
    handleAddExpense,
    handleDeleteExpense,
    handleInputProviderChange,
    handleNewExpensesFileChange,
    newExpenses,
    setData,
    open,
    isAid,
    classes,
    isInternational,
    GlobalCard,
    utils,
  };

  const modifyDatesProps = {
    es,
    dates,
    allCosts,
    handleModifyInitialDate,
    handleModifyEndDate,
    handleCostChange,
    handleChangeUsedTrips,
    usedTrips,
    data,
    validateDates,
    GlobalCard,
    classes,
    validateThirdUserOrIsAid: validateThirdUserOrIsAid(
      dataLiquidation?.CodigoEmpleadoViajero,
      isAid
    ),
    handleChangeTaxisValue,
  };

  const reportedExpensesProps = {
    reportedExpensesList,
    handleReportedExpensesFileChange,
    handleClickListOfProviders,
    handleInputChange,
    providerInputs,
    handleChangeProviderReported,
    openProviders,
    data,
    setData,
    open,
    isEdit,
    GlobalCard,
    classes,
    isEditExternalAid,
    utils,
    disabled: false,
  };

  const externalAidProps = {
    statusExpensesProps,
    GlobalCard,
    classes,
    handleTravelCardFileChange,
    isEdit,
    cardTravel,
    aidValue,
    utils,
    dataLegalization,
    aidData,
    filteredDataExpenses,
    reportedExpensesProps,
    getAmbulanceData,
    openStatusModalAlert,
    handleSubmitExternalAid,
    disabledButtonExternal,
  };

  const legalizationComponentProps = {
    handleSubmit,
    disableButton,
    classes,
    GlobalCard,
    form,
    handleChangeApprover,
    isAid,
    data,
    openedApprover,
    setOpenedApprover,
    openedPreApprover,
    setOpenedPreApprover,
    filteredDataExpenses,
    validateThirdUserOrIsAid,
    getAmbulanceData,
    dataLegalization,
    isEdit,
    aidData,
    utils: { formatter, parserNumberInput, formatterNumberInput },
    aidValue,
    cardTravel,
    formAid,
    es,
    handleTravelCardFileChange,
    newExpensesProps,
    modifyDatesProps,
    reportedExpensesProps,
    userData,
    statusExpensesProps,
    openStatusModalAlert,
    externalAidProps,
    isExternalAid,
    isEditExternalAid,
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <>
      <ProviderModal
        opened={opened}
        data={data}
        handleSaveProvider={handleSaveProvider}
        legalPerson={legalPerson}
        naturalPerson={naturalPerson}
        providerForm={providerForm}
        setLegalPerson={setLegalPerson}
        setNaturalPerson={setNaturalPerson}
        close={close}
        ModalComponent={ModalComponent}
        classes={classes}
      />

      <ModalComponent
        title="Alerta"
        opened={openedModal}
        onClose={closeModal}
        closeOnClickOutside={modalContent.closeOnClickOutside}
      >
        <Text ta="center" fw={700} color="#004236">
          {modalContent.content}
        </Text>
        {modalContent.showButtons && (
          <Flex gap={16} my={12} justify="flex-end">
            <Button onClick={gotoLegalization} className={classes.button}>
              Aceptar
            </Button>
          </Flex>
        )}
      </ModalComponent>

      <ModalComponent
        title="Confirmación"
        opened={openedStatusModal}
        onClose={closeStatusModal}
        closeOnClickOutside={modalStatusContent.closeOnClickOutside}
      >
        <Text ta="center" fw={700} color="#004236">
          {modalStatusContent.content}
        </Text>
        {modalStatusContent.showButtons && (
          <Flex gap={16} my={12} justify="flex-end">
            <Button
              onClick={modalStatusContent.onClick}
              className={classes.button}
            >
              Aceptar
            </Button>
          </Flex>
        )}
      </ModalComponent>

      {responseCreateProvider !== null && (
        <ModalComponent
          opened={true}
          onClose={() => setResponseCreateProvider(null)}
          title="Alerta"
        >
          <Text fw={700} color="#004236" size="lg" ta="center">
            {responseCreateProvider
              ? "El proveedor se creó correctamente."
              : "Hubo un error al crear el proveedor, inténtelo nuevamente."}
          </Text>
        </ModalComponent>
      )}
      <LegalizationTravelComponent {...legalizationComponentProps} />
    </>
  ) : null;
};
