import React from "react";
import { Text, Grid, Button, Flex, Box, Anchor } from "@mantine/core";
import InfoAid from "./infoAid.component";
import AidValueCard from "./aidValueCard.component";
import { OtherExpenses } from "./otherExpenses.component";

import { ExpensesSupportReviewComponent } from "./expensesSupportReview.component";

const totalTextStyles = {
  "@media print": {
    fontSize: "1rem",
  },
};

export const LegalizationDetailExternalComponent = ({
  data,
  GlobalCard,
  classes,
  utils,
  ModalComponent,
  ServiceDetailCard,
  modalContent,
  opened,
  close,
  allCosts,
  filterByBeneficiaryType,
  legalizationTravelExpense,
  expensesSupportReview,
  isBankSupport = false,
  isBankSupportsAid,
}) => {
  const isBeforeLegalization =
    data?.travelStatus?.fkEstadoViaje === "APROPAGSAP";
  const dataLegalization = data?.legalization?.data;
  const showData = isBeforeLegalization
    ? data?.travelLiquidatorData
    : dataLegalization?.liquidacion;

  const { TipoViajeAlias, totalDias, TotalGastos, ViajeTrayecto } =
    showData || {};

  const { diasViaje } = dataLegalization || {};

  const totalDays = `${isBeforeLegalization ? totalDias : diasViaje} Días`;

  const externalData = data?.expensesExternalAidData;

  const infoAidProps = {
    data: {
      ...showData,
      Id: data?.travelLiquidatorData?.Id,
    },
    extraData: data?.travelStatus,
    dataAid: data?.dataAid,
    dataLiquidation: {
      liquidacion: showData,
      tarjetaViaje: {
        urlArchivo: externalData?.travelCard?.UlrArchivo,
      },
    },
    filterByBeneficiaryType,
    utils,
  };

  const aidValueCardProps = {
    utils,
    liquidator: showData,
    dataAid: data?.dataAid,
    ModalComponent,
    filterByBeneficiaryType,
  };

  const otherExpensesProps = {
    data: isBeforeLegalization
      ? externalData?.otherExpenses
      : data?.legalization?.data?.liquidacion?.ViajeGasto,
    travelExpensesType: externalData?.otherExpenses?.map((item) => ({
      ...item,
      id: item.GastoId,
      label: item.Nombre,
    })),
    GlobalCard,
    utils,
  };

  return (
    <>
      <ModalComponent
        title="Alerta"
        opened={opened}
        onClose={close}
        closeOnClickOutside={modalContent.closeOnClickOutside}
      >
        <Text ta="center" fw={700} color="#004236">
          {modalContent.content}
        </Text>
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={modalContent.onClick}
              fullWidth
              className={classes.button}
            >
              Aceptar
            </Button>
            <Button onClick={close} fullWidth className={classes.buttonOutline}>
              Cancelar
            </Button>
          </Flex>
        )}
      </ModalComponent>

      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
        <Grid py={16}>
          <Grid.Col span="auto">
            <Text size={24} fw={700} color="#004236">
              Información general
            </Text>
          </Grid.Col>
          <Grid.Col span="auto" ta="right">
            <Text size={24} fw={700} color="#004236">
              Estado
              <Text span c="orange" inherit>
                {` ${
                  data?.travelStatus?.listaEstados?.find(
                    (status) =>
                      status.paramFkEstadoViaje ===
                      data?.travelStatus?.fkEstadoViaje
                  ).paramNombreEstado
                }`}
              </Text>
            </Text>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col
            xs={12}
            md={9}
            sx={{
              "@media print": {
                flexBasis: "70%",
                maxWidth: "70%",
              },
            }}
          >
            <ServiceDetailCard
              showTotal
              totalDays={totalDays}
              showTotalDays
              gridStyles={{
                ".mantine-Grid-col": {
                  "@media print": {
                    flexBasis: "33%",
                    maxWidth: "33%",
                  },
                },
              }}
            >
              <InfoAid {...infoAidProps} />
            </ServiceDetailCard>
            <ServiceDetailCard
              totalLabel="Total"
              title="Valor auxilio ambulatorio"
              totalCop={utils.formatter.format(
                ViajeTrayecto?.[0]?.auxilioMedico?.ValorTotalAuxilio
              )}
            >
              <AidValueCard {...aidValueCardProps} />
            </ServiceDetailCard>
            <ServiceDetailCard title="Fechas del auxilio ambulatorio">
              <Grid.Col>
                <Grid columns={5} fz="sm" fw={700} color="#004236">
                  <Grid.Col span={1}>
                    <Text color="#004236">Nombre</Text>
                  </Grid.Col>

                  <Grid.Col span={1}>
                    <Text color="#004236">Tipo</Text>
                  </Grid.Col>

                  <Grid.Col span={1}>
                    <Text color="#004236">Fecha inicial</Text>
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Text color="#004236">Fecha final</Text>
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Text color="#004236">Retorno</Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col>
                <Grid columns={5} fz="sm">
                  <Grid.Col span={1}>
                    <Text>
                      {`${filterByBeneficiaryType(
                        data?.dataAid,
                        "BENEFICIARIO",
                        "nombres"
                      )} ${filterByBeneficiaryType(
                        data?.dataAid,
                        "BENEFICIARIO",
                        "apellidos"
                      )}`}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Text>Beneficiario</Text>
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Text>
                      {utils.parseDateLocaleFormat(
                        ViajeTrayecto?.[0]?.auxilioMedico?.PerBeneficiario
                          ?.fechaInicioViaje
                      )}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Text>
                      {utils.parseDateLocaleFormat(
                        ViajeTrayecto?.[0]?.auxilioMedico?.PerBeneficiario
                          ?.fechaFinViaje
                      )}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Text>
                      {ViajeTrayecto?.[0]?.auxilioMedico?.PerBeneficiario
                        ?.retorno === false
                        ? "No"
                        : "Sí"}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              {ViajeTrayecto?.[0]?.auxilioMedico?.Acompanante && (
                <Grid.Col>
                  <Grid columns={5} fz="sm">
                    <Grid.Col span={1}>
                      <Text>
                        {`${filterByBeneficiaryType(
                          data?.dataAid,
                          "ACOMPANANTE",
                          "nombres"
                        )} ${filterByBeneficiaryType(
                          data?.dataAid,
                          "ACOMPANANTE",
                          "apellidos"
                        )}`}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <Text>Acompañante</Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <Text>
                        {utils.parseDateLocaleFormat(
                          ViajeTrayecto?.[0]?.auxilioMedico?.PerAcompanante
                            ?.fechaInicioViaje
                        )}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <Text>
                        {utils.parseDateLocaleFormat(
                          ViajeTrayecto?.[0]?.auxilioMedico?.PerAcompanante
                            ?.fechaFinViaje
                        )}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <Text>
                        {ViajeTrayecto?.[0]?.auxilioMedico?.PerAcompanante
                          ?.retorno === false
                          ? "No"
                          : "Sí"}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              )}
            </ServiceDetailCard>
            {otherExpensesProps?.data?.length > 0 && (
              <ServiceDetailCard
                totalLabel="Total"
                title="Otros gastos"
                totalCop={utils.formatter.format(TotalGastos)}
                totalUsd={0}
              >
                <OtherExpenses {...otherExpensesProps} />
              </ServiceDetailCard>
            )}
            {isBankSupportsAid && (
              <ServiceDetailCard title="Soporte de pago bancario">
                <Grid.Col xs="auto">
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isBankSupport && (
                      <Grid.Col span={12}>
                        Tiene marcación de descuento por nomina.
                      </Grid.Col>
                    )}

                    <Grid.Col span={12}>
                      <Grid>
                        {data?.pendingSupports?.map((item) => (
                          <Grid.Col span={12} key={item?.nombreArchivo}>
                            <Text fw={700} color="#004236">
                              {`Soporte: `}
                              <Text span fw={"normal"}>
                                {item?.nombreArchivo}
                              </Text>
                            </Text>
                            <Anchor
                              c="#fd7e14"
                              target="_blank"
                              underline
                              download
                              href={item?.urlArchivo}
                            >
                              Ver Documento
                            </Anchor>
                          </Grid.Col>
                        ))}
                      </Grid>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </ServiceDetailCard>
            )}
            <Box>
              <Text size="lg" color="#004236" weight={700} sx={totalTextStyles}>
                Valor total
              </Text>
              {TipoViajeAlias === "TVAMBULATORIOS" && (
                <Flex
                  bg="#cbd300"
                  py={10}
                  px={15}
                  sx={{ borderRadius: "0.5rem" }}
                  mt={20}
                  justify="space-between"
                  align="center"
                >
                  <Text
                    size="lg"
                    color="#004236"
                    fw={700}
                    ml="auto"
                    sx={totalTextStyles}
                  >
                    {utils.formatter.format(ViajeTrayecto?.[0]?.TotalAveLiqCop)}
                  </Text>
                </Flex>
              )}
            </Box>
          </Grid.Col>
          <Grid.Col
            xs={12}
            md={3}
            sx={{
              "@media print": {
                flexBasis: "30%",
                maxWidth: "30%",
              },
            }}
          >
            <GlobalCard>
              <ExpensesSupportReviewComponent {...expensesSupportReview} />
            </GlobalCard>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};
