import React from "react";
import { Text, TextInput, Select, Grid } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { LuChevronDown } from "react-icons/lu";

export const TravelerForm = ({
  titleForm,
  data,
  frequentTravelerAirlines,
  disabledValidation,
  utils,
  formUser,
  inputValues,
  handleChangeInputs,
  formDataStep,
  GlobalCard,
  classes,
}) => {
  return (
    <div>
      <Text fz={24} fw={700} color="#004236" py={16} component="h1" px={8}>
        {titleForm}
      </Text>
      <Grid>
        <Grid.Col span={12}>
          <GlobalCard
            styles={{
              marginBlock: 0,
            }}
          >
            <Grid>
              {formDataStep === 2 ? (
                <>
                  <Grid.Col xs={12} md={6}>
                    <TextInput
                      {...formUser.getInputProps("nombre")}
                      disabled={disabledValidation()}
                      label="Nombre"
                      classNames={{
                        input: classes.input,
                        label: classes.labelSecondary,
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} md={6}>
                    <TextInput
                      {...formUser.getInputProps("apellidos")}
                      disabled={disabledValidation()}
                      label="Apellidos"
                      classNames={{
                        input: classes.input,
                        label: classes.labelSecondary,
                      }}
                    />
                  </Grid.Col>
                </>
              ) : (
                <Grid.Col xs={12} md={12} lg={12}>
                  <TextInput
                    {...formUser.getInputProps("nombres")}
                    disabled={disabledValidation()}
                    label="Nombre"
                    classNames={{
                      input: classes.input,
                      label: classes.labelSecondary,
                    }}
                  />
                </Grid.Col>
              )}
              <Grid.Col xs={12} md={6} lg={6}>
                {formDataStep === 2 ? (
                  <Select
                    {...formUser.getInputProps("tipoDocumento")}
                    label="Tipo de documento"
                    placeholder="Selecciona el tipo de documento"
                    rightSection={<LuChevronDown size="1rem" color="#004236" />}
                    rightSectionWidth={30}
                    data={[
                      { value: "TI", label: "Tarjeta de identidad" },
                      { value: "CC", label: "Cédula de ciudadanía" },
                      { value: "CE", label: "Cédula de extranjería" },
                      { value: "IDP", label: "Pasaporte" },
                    ]}
                    classNames={{
                      dropdown: classes.dropdown,
                      rightSection: classes.rightSection,
                      label: classes.labelSecondary,
                      input: classes.input,
                      item: classes.item,
                    }}
                  />
                ) : (
                  <TextInput
                    {...formUser.getInputProps("tipoDocumento")}
                    disabled={disabledValidation()}
                    label="Tipo de documento"
                    classNames={{
                      input: classes.input,
                      label: classes.labelSecondary,
                    }}
                  />
                )}
              </Grid.Col>
              <Grid.Col xs={12} md={6} lg={6}>
                <TextInput
                  {...formUser.getInputProps("numeroDocumento")}
                  disabled={disabledValidation()}
                  label="Documento de identidad"
                  classNames={{
                    input: classes.input,
                    label: classes.labelSecondary,
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={6} lg={6}>
                <TextInput
                  {...formUser.getInputProps("correoElectronico")}
                  label="Correo electrónico"
                  classNames={{
                    input: classes.input,
                    label: classes.labelSecondary,
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={6} lg={6}>
                <DatePickerInput
                  {...formUser.getInputProps("fechaNacimiento")}
                  locale={utils.es}
                  valueFormat="DD-MMM-YYYY"
                  label="Fecha de nacimiento"
                  classNames={{
                    input: classes.input,
                    label: classes.labelSecondary,
                  }}
                  sx={{ input: { textTransform: "capitalize" } }}
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col xs={12} md={6} lg={6}>
                <Select
                  {...formUser.getInputProps("nacionalidad")}
                  transitionProps={{ transition: "pop" }}
                  dropdownPosition="bottom"
                  label="Nacionalidad"
                  placeholder="Nacionalidad"
                  searchable
                  rightSection={<LuChevronDown size="1rem" color="#004236" />}
                  rightSectionWidth={30}
                  data={data?.countriesData}
                  classNames={{
                    dropdown: classes.dropdown,
                    rightSection: classes.rightSection,
                    label: classes.labelSecondary,
                    input: classes.input,
                    item: classes.item,
                  }}
                  disabled={!data?.countriesData?.length}
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col xs={12} md={6} lg={6}>
                <TextInput
                  {...formUser.getInputProps("telefono")}
                  label="Número telefónico"
                  classNames={{
                    input: classes.input,
                    label: classes.labelSecondary,
                  }}
                  placeholder="Número telefónico"
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col xs={12} md={6} lg={6}>
                <Select
                  {...formUser.getInputProps("genero")}
                  label="Género"
                  placeholder="Género"
                  rightSection={<LuChevronDown size="1rem" color="#004236" />}
                  data={[
                    { value: "M", label: "Masculino" },
                    { value: "F", label: "Femenino" },
                  ]}
                  classNames={{
                    input: classes.input,
                    item: classes.item,
                    label: classes.labelSecondary,
                  }}
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col xs={12} md={6} lg={6}>
                <TextInput
                  {...formUser.getInputProps("ciudadResidencia")}
                  label="Ciudad de residencia"
                  placeholder="Ciudad de residencia"
                  classNames={{
                    input: classes.input,
                    label: classes.labelSecondary,
                  }}
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col xs={12} md={6} lg={6}>
                <TextInput
                  {...formUser.getInputProps("direccion")}
                  label="Dirección de residencia"
                  classNames={{
                    input: classes.input,
                    label: classes.labelSecondary,
                  }}
                  placeholder="Dirección"
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <Text fw={700} color="#004236" ta="center">
                  Contacto de emergencia
                </Text>
              </Grid.Col>

              <Grid.Col xs={12} md={6} lg={6}>
                <TextInput
                  {...formUser.getInputProps("nombreEmergencia")}
                  label="Nombre"
                  classNames={{
                    input: classes.input,
                    label: classes.labelSecondary,
                  }}
                  placeholder="Nombre contacto de emergencia"
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col xs={12} md={6} lg={6}>
                <TextInput
                  {...formUser.getInputProps("numeroTelefonoEmergencia")}
                  label="Número de contacto"
                  classNames={{
                    input: classes.input,
                    label: classes.labelSecondary,
                  }}
                  placeholder="Número de contacto"
                  withAsterisk
                />
              </Grid.Col>

              {frequentTravelerAirlines().length > 0 && (
                <>
                  <Grid.Col span={12}>
                    <Text fw={700} color="#004236" ta="center">
                      Viajero frecuente
                    </Text>
                  </Grid.Col>
                  {frequentTravelerAirlines().map((info, index) => (
                    <Grid.Col span={12} key={index}>
                      <TextInput
                        label={info.name}
                        value={inputValues[index]["id"]}
                        classNames={{
                          input: classes.input,
                          label: classes.labelSecondary,
                        }}
                        data-testid="frecuentTraveler-input"
                        onChange={(event) =>
                          handleChangeInputs(index, event.currentTarget.value)
                        }
                      />
                    </Grid.Col>
                  ))}
                </>
              )}
              {data?.travelLiquidatorData?.TipoProceso === 4 &&
                frequentTravelerAirlines().length > 0 && (
                  <Grid.Col span={12}>
                    <Select
                      label="Seleccione los servicios"
                      clearable
                      data={data.frequentServices}
                      classNames={{
                        input: classes.input,
                        item: classes.item,
                        label: classes.labelSecondary,
                        rightSection: classes.rightSection,
                      }}
                      rightSection={
                        <LuChevronDown size="1rem" color="#004236" />
                      }
                    />
                  </Grid.Col>
                )}
            </Grid>
          </GlobalCard>
        </Grid.Col>
      </Grid>
    </div>
  );
};
