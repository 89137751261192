import React, { useEffect, useState } from "react";
import {
  useWindowSize,
  GlobalCard,
  useStyles,
  ModalComponent,
} from "@mfe/js-common-ave-uiutils";
import { Flex, Button, LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";

import {
  formatter,
  parseDateLocaleFormat,
  filterByBeneficiaryType,
  fetchUploadBankSupportsExternalAid,
  fetchPSEVariable,
  fetchRejectedSupportsData,
} from "@mfe/ts-common-ave-utilitaries";
import { navigateToUrl } from "single-spa";
import { BankSupport } from "../components/bankSupport.component";
import CardDetails from "../components/cardDetails.component";

export const ExternalRequestCardDetail = ({
  data,
  path,
  tokenApi,
  dinamicRoutes,
}) => {
  const { classes } = useStyles();
  const isMd = useWindowSize("md");
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedEnd, { open: openEnd, close: closeEnd }] = useDisclosure(false);
  const [openedSupports, { open: openSupports, close: closeSupports }] =
    useDisclosure(false);
  const [itemModal, setItemModal] = useState(null);
  const [fileSupport, setFileSupport] = useState({
    name: "",
    base: "",
  });
  const [loadingSupports, setLoadingSupports] = useState(false);
  const [rejectedSupports, setRejectedSupports] = useState([]);

  const form = useForm({
    initialValues: {
      reference: "",
    },
  });

  const [modalContent, setModalContent] = useState({
    content: {},
    showButtons: true,
    onClick: () => {},
    onClose: () => {},
  });

  const navigateToLegalization = (item) => {
    const isRejectedSupports =
      item.selfManagementStatus === "Autogestión rechazada";

    localStorage.setItem("liquidationID", item.travel.id);
    localStorage.setItem("isRejectedSupports", String(isRejectedSupports));
    localStorage.setItem("externalFlow", "true");
    navigateToUrl("/legalizacion");
  };

  const handleOpenSupport = async (item) => {
    openSupports();
    setItemModal(item);
  };

  useEffect(() => {
    if (openedSupports)
      fetchRejectedSupportsData(
        tokenApi,
        setLoadingSupports,
        setRejectedSupports,
        itemModal.travel.id
      );
  }, [openedSupports]);

  const handleSubmitSupports = async () => {
    const fileExtension = fileSupport.name.split(".").pop();

    const payload = {
      idAuxilio: itemModal?.travel?.id,
      paymentSupports: [
        {
          nombreArchivo: fileSupport.name,
          extensionArchivo: fileExtension,
          archivo: fileSupport.base,
        },
      ],
    };

    try {
      setLoading(true);
      const response = await fetchUploadBankSupportsExternalAid(
        tokenApi,
        payload
      );
      setModalContent({
        content: "Soporte bancario enviado a revisión con éxito.",
        showButtons: false,
        onClick: () => close(),
        onClose: () => {
          close();
          dinamicRoutes();
        },
      });
      open();
    } catch (error) {
      setModalContent({
        content: JSON.parse(error.request.responseText).message,
        showButtons: false,
        onClick: () => close(),
        onClose: () => {
          close();
          dinamicRoutes();
        },
      });
      open();
    } finally {
      setLoading(false);
      closeSupports();
      setItemModal(null);
    }
  };

  const handlePSE = async (requestItem) => {
    try {
      setLoading(true);
      const { data } = await fetchPSEVariable(tokenApi, true);

      const url = data?.data?.replace(
        "{{document}}",
        filterByBeneficiaryType(
          requestItem?.travel?.beneficiarios,
          "TITULAR",
          "numeroDocumento"
        )
      );
      window.open(url, "_blank");
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
        onClose: () => close(),
      });
    } finally {
      setLoading(false);
    }
  };

  const buttonStates = [
    {
      name: "Cargar Soportes",
      action: (requestItem) => {
        navigateToLegalization(requestItem);
      },
      stateByPath: [
        {
          path: "auxilios-por-legalizar",
          state: ["APROPAGSAP"],
        },
      ],
      validations: [],
    },
    {
      name: "Soportes Bancarios",
      action: (requestItem) => {
        handleOpenSupport(requestItem);
      },
      stateByPath: [
        {
          path: "auxilios-saldo-a-favor",
          state: ["ANULFAVORECP", "LEGAPROSEC"],
        },
      ],
      validations: [],
    },
    {
      name: "Pago Por PSE",
      action: (requestItem) => {
        handlePSE(requestItem);
      },
      stateByPath: [
        {
          path: "auxilios-saldo-a-favor",
          state: ["ANULFAVORECP", "LEGAPROSEC"],
        },
      ],
      validations: [],
    },
  ];

  function filterButtonStates(path, state) {
    return buttonStates.filter((button) =>
      button.stateByPath.some(
        (stateObj) => stateObj.path === path && stateObj.state.includes(state)
      )
    );
  }

  const handleAttachSupport = (value) => {
    const reader = new FileReader();
    reader.readAsDataURL(value);
    reader.onload = () => {
      const base = reader.result as string;
      setFileSupport({
        name: value.name,
        base: base.split(",")[1],
      });
    };
  };

  const cardDetailsProps = {
    data,
    GlobalCard,
    isMd,
    showDetails,
    setShowDetails,
    filterByBeneficiaryType,
    classes,
    utils: { formatter, parseDateLocaleFormat },
    filterButtonStates,
    path,
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return (
    <>
      <ModalComponent
        opened={opened}
        onClose={modalContent.onClose}
        title="Alerta"
      >
        {modalContent.content}
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={() => modalContent.onClick()}
              fullWidth
              className={classes.button}
            >
              Aceptar
            </Button>
            <Button
              onClick={modalContent.onClose}
              fullWidth
              className={classes.buttonOutline}
            >
              Cancelar
            </Button>
          </Flex>
        )}
      </ModalComponent>
      <BankSupport
        opened={openedSupports}
        close={closeSupports}
        form={form}
        handleSubmitSupports={handleSubmitSupports}
        item={itemModal}
        ModalComponent={ModalComponent}
        classes={classes}
        handleAttachSupport={handleAttachSupport}
        utils={{ formatter }}
        loadingSupports={loadingSupports}
        rejectedSupports={rejectedSupports}
      />
      <CardDetails {...cardDetailsProps} />
    </>
  );
};
