import { Box, Button, Collapse, Flex } from "@mantine/core";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2";

const TopFilterSection = ({
  activeRouteName,
  data,
  classes,
  WCInlineInputFilter,
  opened,
  topFilterData,
  toggle,
}) => {
  return (
    <>
      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto">
        <Flex my={16} justify="flex-end" align="center">
          <Button className={classes.button} onClick={toggle}>
            <HiMiniAdjustmentsHorizontal />
          </Button>
        </Flex>
      </Box>
      <Collapse in={opened}>
        <WCInlineInputFilter inputs={topFilterData} />
      </Collapse>
    </>
  );
};

export default TopFilterSection;
