import React, { useEffect, useState } from "react";
import {
  useStyles,
  useWindowSize,
  GlobalCard,
  ModalComponent,
} from "@mfe/js-common-ave-uiutils";
import {
  Button,
  Flex,
  Text,
  Radio,
  Textarea,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";

import {
  formatter,
  parseDateLocaleFormat,
  fetchTravelRequestRejectTrip,
  fetchTravelRequestCloseTrip,
  fetchTravelRequestApproveTrip,
  fetchLegalizationConditionServices,
  fetchTripWithoutLegalization,
  fetchNoTripWithoutLegalization,
  fetchTraveLiquidatorDelete,
  fetchTravelRequestPreApproveTrip,
  fetchLegalizationApprove,
  fetchLegalizationReject,
  fetchCommissionerNotify,
  fetchRetrieveApproval,
  fetchPermanentViaticum,
  fetchApproveUnions,
  fetchApproveUnionLegalization,
  fetchPSEVariable,
  moment,
} from "@mfe/ts-common-ave-utilitaries";
import { navigateToUrl } from "single-spa";
import ModalUnionApprove from "../components/modalUnionApprove.component";
import CardDetail from "../components/cardDetail.component";

const RadioGroupWithState = ({
  open,
  close,
  item,
  setLoading,
  tokenApi,
  setModalContent,
  openModal,
  closeModal,
  dinamicRoutes,
}) => {
  const { classes } = useStyles();

  const [confirmTravel, setConfirmTravel] = useState("true");
  const [requireModify, setRequireModify] = useState("false");
  const [obsAnulacion, setObsAnulacion] = useState("");
  const dataUser = JSON.parse(localStorage.getItem("userData") || "{}");

  const trip = {
    idViaje: item?.id?.toString(),
    usuarioLogueado: dataUser?.datosUsuario?.codigoEmpleado,
    obsAnulacion: obsAnulacion,
    aplicaSegmentos: true,
    segmentoViaje: 0,
    numLiquidacion: item?.liquidadorIdLiquidacion || 0,
    ultimoSegmento: true,
    validaSoportesBpo: item?.serviciosViaje?.length > 0,
  };

  const noTrip = async () => {
    try {
      setLoading(true);
      if (
        item?.paramTipoViaje === "TVTURNOS" ||
        (item?.paramTipoComision === "VPPASMENSIN" && !item?.tieneOtrosGastos)
      ) {
        await fetchTravelRequestCloseTrip(tokenApi, {
          idViaje: item?.id,
          idUsuarioTransaccion: dataUser?.datosUsuario?.codigoEmpleado,
          nombreUsuarioTransaccion: dataUser?.datosUsuario?.nombre,
          viajeRealizado: false,
        });
        localStorage.setItem("liquidationID", item.id);
        navigateToUrl("/reserva-detalles");
      } else {
        const { data } = await fetchNoTripWithoutLegalization(tokenApi, trip);
        if (data?.estado === true) {
          navigateToDetail();
        } else {
          close();
          setModalContent({
            content: data?.mensaje,
            showButtons: false,
            onClick: () => {},
          });
          openModal();
        }
      }
    } catch (error) {
      close();
      setModalContent({
        content: "Ocurrió un error, por favor inténtelo nuevamente",
        showButtons: false,
        onClick: () => {},
      });
      openModal();
    } finally {
      setLoading(false);
    }
  };

  const withTrip = async () => {
    try {
      setLoading(true);
      if (item?.serviciosViaje?.length > 0) {
        const { data } = await fetchLegalizationConditionServices(
          tokenApi,
          item.id
        );
        if (data?.data?.soportesCompletosBpo) {
          setLoading(true);
          if (item?.tieneOtrosGastos) {
            navidateToLiquidation();
          } else {
            await fetchTripWithoutLegalization(tokenApi, trip);
            navigateToDetail();
          }
        } else {
          close();
          setModalContent({
            content: data?.data?.detSoportesFaltantes,
            showButtons: false,
            onClick: () => {},
          });
          openModal();
        }
      } else {
        setLoading(true);
        if (item?.tieneOtrosGastos) {
          navidateToLiquidation();
        } else {
          await fetchTripWithoutLegalization(tokenApi, trip);
          navigateToDetail();
        }
      }
    } catch (error) {
      close();
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => {},
      });
      openModal();
    } finally {
      setLoading(false);
    }
  };

  const navigateToDetail = () => {
    localStorage.setItem("liquidationID", item.id);
    navigateToUrl("/detalle-legalizacion");
  };

  useEffect(() => {
    if (confirmTravel === "false") {
      setRequireModify("false");
    }
  }, [confirmTravel]);

  const handleCloseTrip = () => {
    if (confirmTravel === "true") {
      withTrip();
    } else {
      noTrip();
    }
  };

  const handleCloseModal = () => {
    close();
    setConfirmTravel("true");
    setRequireModify("false");
  };

  const navidateToLiquidation = () => {
    localStorage.setItem("liquidationID", item.id);
    navigateToUrl("/legalizacion");
  };

  return (
    <ModalComponent opened={open} onClose={handleCloseModal}>
      <Text>Para continuar. ¿Realizó el viaje?</Text>
      <Radio.Group value={confirmTravel} onChange={setConfirmTravel}>
        <Flex align="center" gap={12}>
          <Radio value={"true"} label="Sí" className={classes.radioButton} />
          <Radio value={"false"} label="No" className={classes.radioButton} />
        </Flex>
      </Radio.Group>
      {confirmTravel === "false" && (
        <>
          <Text>Ingrese el motivo por el cual, no se realizó el viaje.</Text>
          <Textarea
            onChange={(e) => setObsAnulacion(e.target.value)}
            my={12}
            minRows={3}
          />
        </>
      )}
      {confirmTravel === "true" &&
      item?.fkEstadoViaje !== "APROBADO" &&
      item?.paramTipoViaje !== "TVTURNOS" ? (
        <>
          <Text>
            ¿Requiere modificar fechas, adicionar o modificar gastos a su viaje?
          </Text>
          <Radio.Group
            value={requireModify}
            onChange={setRequireModify}
            mt={12}
          >
            <Flex align="center" gap={12}>
              <Radio
                value={"true"}
                label="Sí"
                className={classes.radioButton}
              />
              <Radio
                value={"false"}
                label="No"
                className={classes.radioButton}
              />
            </Flex>
          </Radio.Group>
        </>
      ) : (
        confirmTravel !== "false" &&
        item?.paramTipoViaje !== "TVTURNOS" &&
        item?.paramTipoComision !== "VPPASMENSIN" && (
          <Text>
            El proceso de legalización no puede continuar porque no se ha
            notificado el pago del anticipo en ave.
          </Text>
        )
      )}

      <Flex gap={16} my={12}>
        <Button
          onClick={() =>
            requireModify === "true"
              ? navidateToLiquidation()
              : handleCloseTrip()
          }
          fullWidth
          className={classes.button}
          disabled={
            confirmTravel === "true"
              ? item?.fkEstadoViaje === "APROBADO" &&
                item?.paramTipoViaje !== "TVTURNOS" &&
                item?.paramTipoComision !== "VPPASMENSIN"
              : false
          }
        >
          Aceptar
        </Button>
        <Button
          onClick={handleCloseModal}
          fullWidth
          className={classes.buttonOutline}
        >
          Cancelar
        </Button>
      </Flex>
    </ModalComponent>
  );
};

export default function RequestsCardDetail({
  data,
  setData,
  path,
  approveAll,
  setApproveAll,
  tokenApi,
  dinamicRoutes,
  organization,
  travelTypesList,
  approveAllLeg,
  setApproveAllLeg,
  hasMassiveLegalization,
}) {
  const { classes } = useStyles();
  const isMd = useWindowSize("md");
  const dataUser = JSON.parse(localStorage.getItem("userData"));

  const [showDetails, setShowDetails] = useState(false);
  const [itemUnion, setItemUnion] = useState(null);

  const [opened, { open, close }] = useDisclosure(false);
  const [openedEnd, { open: openEnd, close: closeEnd }] = useDisclosure(false);
  const [openedUnion, { open: openUnion, close: closeUnion }] =
    useDisclosure(false);
  const [openedReject, { open: openReject, close: closeReject }] =
    useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const [isRejectLegalization, setIsRejectLegalization] = useState(false);
  const [modalContent, setModalContent] = useState({
    content: {},
    showButtons: true,
    onClick: () => {},
  });

  const formApproveUnion = useForm({
    initialValues: {
      flights: 0,
      buses: 0,
    },
  });

  const formRejectTravel = useForm({
    validateInputOnChange: true,
    initialValues: {
      obsAnulacion: "",
    },
    validate: {
      obsAnulacion: (value) =>
        value.length > 2
          ? null
          : "Ingrese el motivo, con al menos 3 caracteres, del rechazo",
    },
  });
  const [itemModal, setItemModal] = useState(null);

  const validityStateList = ["PENDAPROBO", "PEDNCONFIRMACION", "PRAPROBADO"];

  const modalText = {
    rejectTrip: "Rechazar viaje",
    rejectLegalization: "Rechazar legalización",
    sendToApprove: `El viaje será enviado a aprobación.`,
    successLegalize: `La legalización se envió a aprobación correctamente.`,
    approveLegalization: `La legalización será aprobada.`,
    successApproveLegalization: `La legalización se aprobó correctamente.`,
    successApproveTrip: `El viaje se aprobó correctamente.`,
    successRejectLegalization: `La legalización se rechazó correctamente.`,
    delete: "¿Está seguro que quiere eliminar el viaje?",
  };

  const handleApproveTrip = async (requestItem, isPreApprover = false) => {
    try {
      setLoading(true);
      !isPreApprover
        ? requestItem?.paramTipoViaje === "TVSINDICATOS"
          ? await fetchApproveUnions(tokenApi, {
              travelId: requestItem?.id,
              approverCode: requestItem.funcionariosIdAprobador,
              flights: formApproveUnion.values.flights,
              buses: formApproveUnion.values.buses,
            })
          : await fetchTravelRequestApproveTrip(
              tokenApi,
              requestItem.id,
              requestItem.funcionariosIdAprobador
            )
        : await fetchTravelRequestPreApproveTrip(
            tokenApi,
            requestItem.id,
            requestItem.funcionariosIdAprobadorPrevio
          );
      closeUnion();
      setModalContent({
        content: modalText["successApproveTrip"],
        showButtons: false,
        onClick: () => {},
      });
      open();
      setTimeout(() => {
        dinamicRoutes();
        formApproveUnion.reset();
        setItemUnion(null);
      }, 2000);
    } catch (error) {
      setLoading(false);
      closeUnion();
      formApproveUnion.reset();
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
      open();
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLiquidationByID = async (travelId, employeeCode) => {
    try {
      setLoading(true);
      await fetchTraveLiquidatorDelete(tokenApi, travelId, employeeCode);
      setModalContent({
        content: `El viaje con ID ${travelId} se eliminó correctamente`,
        showButtons: false,
        onClick: () => {},
      });
      open();
    } catch (error) {
      setModalContent({
        content: JSON?.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
      open();
    } finally {
      setLoading(false);
      setTimeout(() => {
        dinamicRoutes();
      }, 3000);
    }
  };

  const handleApproveLegalization = async (requestItem) => {
    const payload = {
      idViaje: requestItem?.id,
      idAprobador:
        requestItem?.funcionariosIdAprobadorPrevio ===
        dataUser?.datosUsuario?.codigoEmpleado
          ? requestItem?.funcionariosIdAprobadorPrevio
          : requestItem?.funcionariosIdAprobador,
      nombreAprobador:
        requestItem?.funcionariosIdAprobadorPrevio ===
        dataUser?.datosUsuario?.codigoEmpleado
          ? requestItem?.funcionarioNombreAprobadorPrevio
          : requestItem?.funcionarioNombreAprobador,
      segmentoViaje: 0,
    };
    const unionPayload = {
      idViaje: requestItem?.id,
      idAprobador: requestItem?.funcionariosIdAprobador,
      nombreAprobador: requestItem?.funcionarioNombreAprobador,
      segmentoViaje: 0,
      nuevoNumVuelos: formApproveUnion.values.flights,
      nuevoNumBuses: formApproveUnion.values.buses,
    };
    try {
      setLoading(true);
      requestItem?.paramTipoViaje === "TVSINDICATOS"
        ? await fetchApproveUnionLegalization(
            tokenApi,
            unionPayload,
            requestItem?.liquidadorIdLiquidacion
          )
        : await fetchLegalizationApprove(
            tokenApi,
            payload,
            requestItem?.liquidadorIdLiquidacion
          );
      setModalContent({
        content: modalText["successApproveLegalization"],
        showButtons: false,
        onClick: () => {},
      });
      closeUnion();
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        dinamicRoutes();
      }, 1000);
    }
  };

  const handleRejectLegalization = async (requestItem) => {
    const payload = {
      idViaje: requestItem?.id,
      idUsuarioTransaccion: requestItem?.funcionariosIdViajero,
      nombreUsuarioTransaccion: requestItem?.funcionariosNombreViajero,
      funcionariosIdAprobador: requestItem?.funcionariosIdAprobador,
      funcionariosIdAprobadorPrevio: requestItem?.funcionariosIdAprobadorPrevio,
      segmentoViaje: 0,
    };
    try {
      setLoading(true);
      await fetchLegalizationReject(
        tokenApi,
        payload,
        requestItem?.liquidadorIdLiquidacion
      );
      setModalContent({
        content: modalText["successRejectLegalization"],
        showButtons: false,
        onClick: () => {},
      });

      open();
      handleCloseReject();
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        dinamicRoutes();
      }, 1000);
    }
  };

  const handleRejectTrip = async (requestItem) => {
    try {
      setLoading(true);
      await fetchTravelRequestRejectTrip(tokenApi, {
        id: requestItem.id,
        codAprobador: requestItem.funcionariosIdAprobador,
        descripcionRechazo: formRejectTravel.values.obsAnulacion,
      });
      handleCloseReject();
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        dinamicRoutes();
      }, 1000);
    }
  };

  const handleChangeViaticum = async (item) => {
    try {
      await fetchPermanentViaticum(tokenApi, {
        travelId: item?.id,
        codeApprover: item?.funcionariosIdAprobador,
        viaticum: !item?.tieneViaticoPermanente,
      });

      setData((prevData) => ({
        ...prevData,
        requestData: prevData.requestData.map((prevItem) =>
          prevItem.id === item.id
            ? {
                ...prevItem,
                tieneViaticoPermanente: !prevItem.tieneViaticoPermanente,
              }
            : prevItem
        ),
      }));
    } catch (error) {
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    }
  };

  const handleRetrieveApproval = async (requestItem) => {
    try {
      setLoading(true);
      const { data } = await fetchRetrieveApproval(tokenApi, {
        id: requestItem.id,
        idUsuarioTransaccion: "",
        nombreUsuarioTransaccion: "",
      });
      if (data.code === 200) {
        open();
        setModalContent({
          content: "La comisión enviada a confirmar se ha recuperado con éxito",
          showButtons: false,
          onClick: () => close(),
        });
        setTimeout(() => {
          dinamicRoutes();
        }, 2000);
      }
    } catch (error) {
      open();
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCommissionerNotify = async (requestItem) => {
    try {
      setLoading(true);
      const { data } = await fetchCommissionerNotify(tokenApi, {
        id: requestItem.id,
        commissionerId: requestItem.funcionariosIdViajero,
      });
      if (data.code === 200) {
        open();
        setModalContent({
          content: data.message,
          showButtons: false,
          onClick: () => close(),
        });
      }
    } catch (error) {
      open();
      setModalContent({
        content: JSON?.parse(error?.request?.responseText)?.ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (requestItem) => {
    localStorage.setItem("liquidationID", requestItem.id);
    navigateToUrl("/reserva-detalles");
  };

  const handleLegalizationDetails = (requestItem) => {
    localStorage.setItem("liquidationID", requestItem.id);
    navigateToUrl("/detalle-legalizacion");
  };

  const handleNoveltyDetails = (requestItem) => {
    localStorage.setItem("liquidationID", requestItem.id);
    navigateToUrl("/novedad");
  };

  const handleEdit = (requestItem) => {
    localStorage.setItem("liquidationID", requestItem.id);
    localStorage.setItem("isEdit", "true");
    navigateToUrl("/viajes");
  };

  const handleAction = (modalContent, actionFunction) => {
    open();
    setModalContent({
      content: modalContent,
      showButtons: true,
      onClick: actionFunction,
    });
  };

  const handlePSE = async (requestItem) => {
    try {
      setLoading(true);
      const { data } = await fetchPSEVariable(tokenApi);
      const url = data?.data?.replace(
        "{{document}}",
        requestItem?.beneficiarios?.find((item) => item?.tipo === "VIAJERO")
          ?.numeroDocumento
      );
      window.open(url, "_blank");
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenUnion = (item) => {
    openUnion();
    setItemUnion(item);
  };

  const handleOpenReject = (item, isLegalization = false) => {
    const label = `${
      isLegalization ? "La legalización del viaje" : "La solicitud"
    } <b>${
      item.id
    }</b> será rechazada. Ingrese la observación o razón del rechazo.`;
    setModalContent({
      content: label,
      showButtons: true,
      onClick: () => {},
    });
    openReject();
    setItemModal(item);
    setIsRejectLegalization(isLegalization);
  };

  const handleCloseReject = () => {
    closeReject();
    setIsRejectLegalization(false);
    setItemModal(null);
  };

  const findTravelName = (alias) => {
    const travel = travelTypesList?.find((item) => item.alias === alias);
    return travel?.nombre;
  };

  const validateValidity = (item) => {
    if (validityStateList.includes(item?.fkEstadoViaje)) {
      return true;
    }
    return false;
  };

  function filterButtonStates(path, state) {
    return buttonStates.filter((button) =>
      button.stateByPath.some(
        (stateObj) => stateObj.path === path && stateObj.state.includes(state)
      )
    );
  }

  const handleOpen = (item) => {
    openEnd();
    setItemModal(item);
  };

  const buttonStates = [
    {
      name: "Pago PSE",
      action: (requestItem) => {
        handlePSE(requestItem);
      },
      stateByPath: [
        {
          path: "viajes-gestionados",
          state: ["ANULFAVORECP", "LEGAPROSEC"],
        },
      ],
      validations: [
        dataUser?.datosUsuario?.permisosUnicos?.includes("Pago PSE"),
      ],
    },
    {
      name: "Rechazar",
      action: (requestItem) => {
        handleOpenReject(requestItem);
      },
      stateByPath: [
        {
          path: "viajes-por-aprobar",
          state: ["PENDAPROBO", "PEDNCONFIRMACION", "PRAPROBADO"],
        },
      ],
    },
    {
      name: "Aprobar",
      action: (requestItem) => {
        requestItem?.paramTipoViaje === "TVSINDICATOS" &&
        dataUser?.datosUsuario?.filial !== "CNT"
          ? handleOpenUnion(requestItem)
          : handleAction(modalText["sendToApprove"], () => {
              handleApproveTrip(
                requestItem,
                requestItem?.funcionariosIdAprobadorPrevio?.length > 0 &&
                  dataUser?.datosUsuario?.codigoEmpleado ===
                    requestItem?.funcionariosIdAprobadorPrevio
              );
            });
      },
      stateByPath: [
        {
          path: "viajes-por-aprobar",
          state: ["PENDAPROBO", "PEDNCONFIRMACION", "PRAPROBADO"],
        },
      ],
    },
    {
      name: "Aprobar/Rechazar Novedad",
      action: (requestItem) => handleViewDetails(requestItem),
      stateByPath: [
        {
          path: "viajes-por-aprobar",
          state: ["APROPAGSAP", "CONFIRPAGSAP", "APROBADO"],
        },
      ],
      validations: [
        dataUser?.datosUsuario?.permisosUnicos?.includes("Novedades"),
      ],
    },

    {
      name: "Ver detalle anticipo",
      action: (requestItem) => handleViewDetails(requestItem),
      stateByPath: [
        {
          path: "viajes-por-aprobar",
          state: [
            "LEGALIZENV",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
            "LEGAPROSEC",
            "ENPROLEGAL",
          ],
        },
        {
          path: "mis-viajes-en-curso",
          state: [
            "ENPROLEGAL",
            "LEGALIZENV",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
            "LEGAPROSNV",
            "LEGAPROSAF",
            "LEGALIZREC",
            "ANULFAVORECP",
            "LEGAPROSEC",
            "ANULSINVIAJE",
            "APROPAGSAP",
            "CONFSINPAGSAP",
            "CONFIRPAGSAP",
            "APROERRSAP",
            "APROSINPAGSAP",
          ],
        },
        {
          path: "viajes-gestionados",
          state: [
            "ENPROLEGAL",
            "LEGALIZENV",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
            "LEGAPROSNV",
            "LEGALIZREC",
            "ANULFAVORECP",
            "ANULSINVIAJE",
            "LEGAPROSAF",
            "LEGAPROSEC",
          ],
        },
      ],
    },
    {
      name: "Ver detalle legalización",
      action: (requestItem) => handleLegalizationDetails(requestItem),
      stateByPath: [
        {
          path: "viajes-por-aprobar",
          state: [
            "LEGALIZENV",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
            "LEGAPROSEC",
            "ENPROLEGAL",
          ],
        },
        {
          path: "mis-viajes-en-curso",
          state: [
            "ANULFAVORECP",
            "LEGAPROSEC",
            "ENPROLEGAL",
            "LEGALIZENV",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
            "LEGAPROSNV",
            "LEGAPROSAF",
            "LEGALIZREC",
          ],
        },
        {
          path: "viajes-gestionados",
          state: [
            "ANULFAVORECP",
            "LEGAPROSEC",
            "ENPROLEGAL",
            "LEGALIZENV",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
            "LEGAPROSNV",
            "LEGAPROSAF",
            "LEGAPROSEC",
            "LEGALIZREC",
          ],
        },
      ],
    },
    {
      name: "Rechazar legalización",
      action: (requestItem) => handleOpenReject(requestItem, true),
      stateByPath: [
        {
          path: "viajes-por-aprobar",
          state: ["LEGALIZENV", "PEDNCONFIRMACIONLEG", "LEGALIZENVPREV"],
        },
      ],
    },
    {
      name: "Aprobar legalización",
      action: (requestItem) => {
        requestItem?.paramTipoViaje === "TVSINDICATOS" &&
        dataUser?.datosUsuario?.filial !== "CNT"
          ? handleOpenUnion(requestItem)
          : handleAction(modalText["approveLegalization"], () =>
              handleApproveLegalization(requestItem)
            );
      },

      stateByPath: [
        {
          path: "viajes-por-aprobar",
          state: ["LEGALIZENV", "PEDNCONFIRMACIONLEG", "LEGALIZENVPREV"],
        },
      ],
    },
    {
      name: "Recuperar comisión enviada a aprobar",
      action: (requestItem) => handleRetrieveApproval(requestItem),
      stateByPath: [
        {
          path: "mis-viajes-en-curso",
          state: [
            "LEGALIZENV",
            "LEGALIZENVPREV",
            "PEDNCONFIRMACIONLEG",
            "PENDAPROBO",
            "PEDNCONFIRMACION",
            "PRAPROBADO",
            "APROBPAGPARCIAL",
          ],
        },
        {
          path: "viajes-gestionados",
          state: [
            "PENDAPROBO",
            "PEDNCONFIRMACION",
            "PRAPROBADO",
            "APROBPAGPARCIAL",
            "LEGALIZENV",
            "LEGALIZENVPREV",
          ],
        },
      ],
    },
    {
      name: "Ver detalle",
      action: (requestItem) => handleViewDetails(requestItem),
      stateByPath: [
        {
          path: "viajes-por-aprobar",
          state: [
            "PENDAPROBO",
            "PEDNCONFIRMACION",
            "PRAPROBADO",
            "APROPAGSAP",
            "CONFIRPAGSAP",
            "APROBADO",
            "PEDNCONFIRMACIONLEG",
          ],
        },
        {
          path: "mis-viajes-en-curso",
          state: [
            "GUARDADO",
            "PEDNCONFIRMACIONLEG",
            "ANULSINVIAJE",
            "APROBADO",
            "PENDAPROBO",
            "RECHAZADO",
          ],
        },
        {
          path: "viajes-gestionados",
          state: [
            "GUARDADO",
            "RECHAZADO",
            "APROBADO",
            "APROPAGSAP",
            "PENDAPROBO",
            "APROSINPAGSAP",
          ],
        },
        {
          path: "viajes-aprobados-por-cerrar",
          state: ["APROPAGSAP", "APROSINPAGSAP", "APROBADO"],
        },
      ],
    },
    {
      name: "Crear novedad",
      action: (requestItem) => handleNoveltyDetails(requestItem),
      stateByPath: [
        {
          path: "mis-viajes-en-curso",
          state: [
            "APROPAGSAP",
            "CONFSINPAGSAP",
            "CONFIRPAGSAP",
            "APROERRSAP",
            "APROSINPAGSAP",
            "APROBADO",
            "APROCUPCONFIRM",
            "CONFIRMADO",
          ],
        },
        {
          path: "viajes-gestionados",
          state: ["APROBADO", "APROPAGSAP", "APROSINPAGSAP"],
        },
      ],
      validations: [
        dataUser?.datosUsuario?.permisosUnicos?.includes("Novedades"),
      ],
    },
    {
      name: "Finalizar viaje",
      action: (requestItem) => handleOpen(requestItem),
      stateByPath: [
        {
          path: "mis-viajes-en-curso",
          state: [
            "APROPAGSAP",
            "CONFSINPAGSAP",
            "CONFIRPAGSAP",
            "APROERRSAP",
            "APROSINPAGSAP",
            "APROBADO",
            "APROCUPCONFIRM",
            "CONFIRMADO",
          ],
        },
        {
          path: "viajes-gestionados",
          state: ["APROBADO", "APROPAGSAP", "APROSINPAGSAP"],
        },
        {
          path: "viajes-aprobados-por-cerrar",
          state: ["APROPAGSAP", "APROSINPAGSAP", "APROBADO"],
        },
      ],
    },
    {
      name: "Alertar al viajero",
      action: (requestItem) => handleCommissionerNotify(requestItem),
      stateByPath: [
        {
          path: "viajes-aprobados-por-cerrar",
          state: ["APROBADO", "ANULADO", "APROPAGSAP", "APROSINPAGSAP"],
        },
      ],
    },
    {
      name: "Eliminar",
      action: (requestItem) => {
        handleAction(modalText["delete"], () =>
          handleDeleteLiquidationByID(
            requestItem.id,
            dataUser?.datosUsuario?.codigoEmpleado
          )
        );
      },
      stateByPath: [
        {
          path: "viajes-gestionados",
          state: ["GUARDADO", "RECHAZADO"],
        },
        {
          path: "mis-viajes-en-curso",
          state: ["GUARDADO", "RECHAZADO"],
        },
      ],
    },
    {
      name: "Editar",
      action: (requestItem) => handleEdit(requestItem),
      stateByPath: [
        {
          path: "viajes-gestionados",
          state: ["GUARDADO"],
        },
        {
          path: "mis-viajes-en-curso",
          state: ["GUARDADO"],
        },
      ],
    },
  ];

  const cardDetailsProps = {
    GlobalCard,
    isMd,
    showDetails,
    setShowDetails,
    classes,
    findTravelName,
    utils: { parseDateLocaleFormat, formatter, moment },
    validateValidity,
    path,
    handleChangeViaticum,
    approveAll,
    setApproveAll,
    filterButtonStates,
    dataUser,
    data,
    approveAllLeg,
    setApproveAllLeg,
    hasMassiveLegalization,
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return (
    <>
      <RadioGroupWithState
        setModalContent={setModalContent}
        setLoading={setLoading}
        tokenApi={tokenApi}
        close={closeEnd}
        open={openedEnd}
        item={itemModal}
        openModal={open}
        closeModal={close}
        dinamicRoutes={dinamicRoutes}
      />
      <ModalUnionApprove
        form={formApproveUnion}
        opened={openedUnion}
        onClose={closeUnion}
        handleApprove={
          itemUnion?.fkEstadoViaje === "LEGALIZENV"
            ? handleApproveLegalization
            : handleApproveTrip
        }
        item={itemUnion}
        classes={classes}
        ModalComponent={ModalComponent}
      />
      <ModalComponent opened={opened} onClose={close} title="Alerta">
        {modalContent.content}
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={() => modalContent.onClick()}
              fullWidth
              className={classes.button}
            >
              Aceptar
            </Button>
            <Button onClick={close} fullWidth className={classes.buttonOutline}>
              Cancelar
            </Button>
          </Flex>
        )}
      </ModalComponent>
      <ModalComponent
        opened={openedReject}
        onClose={handleCloseReject}
        title="Alerta"
      >
        <div dangerouslySetInnerHTML={{ __html: modalContent.content }} />
        <Textarea
          value={formRejectTravel.values.obsAnulacion}
          onChange={(e) =>
            formRejectTravel.setFieldValue("obsAnulacion", e.target.value)
          }
          label="Observación"
          placeholder="Escriba aquí su observación"
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          mt={12}
        />
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={() =>
                !isRejectLegalization
                  ? handleRejectTrip(itemModal)
                  : handleRejectLegalization(itemModal)
              }
              fullWidth
              className={classes.button}
              disabled={!formRejectTravel.isValid()}
            >
              Aceptar
            </Button>
            <Button
              onClick={handleCloseReject}
              fullWidth
              className={classes.buttonOutline}
            >
              Cancelar
            </Button>
          </Flex>
        )}
      </ModalComponent>
      <CardDetail {...cardDetailsProps} />
    </>
  );
}
