import {
  Anchor,
  Box,
  Button,
  FileInput,
  Flex,
  Grid,
  Text,
} from "@mantine/core";
import { FaUpload } from "react-icons/fa";
import ReportedExpenses from "./reportedExpenses.component";
import { StatusExpenses } from "./statusExpenses.component";
import { ConfirmAid } from "./confirmAid.component";
import { navigateToUrl } from "single-spa";

export const LegalizationTravelExternalAidComponent = ({
  utils,
  isEdit,
  classes,
  aidData,
  aidValue,
  cardTravel,
  GlobalCard,
  getAmbulanceData,
  dataLegalization,
  statusExpensesProps,
  filteredDataExpenses,
  reportedExpensesProps,
  handleTravelCardFileChange,
  handleSubmitExternalAid,
  disabledButtonExternal,
}) => {
  const reportedExpensesPropsExt = {
    ...reportedExpensesProps,
    disabled: true,
  };

  const modifiedList = aidValue?.map((item) => {
    const hasAccompanying = isEdit
      ? dataLegalization?.[0]?.auxilioMedico?.Acompanante
      : aidData?.Acompanante;
    if (!hasAccompanying) {
      return {
        ...item,
        values: [item?.values?.[0]],
      };
    }
    return item;
  });

  return (
    <Box>
      <Text color="#004236" size="xl" fw={500}>
        Tarjeta de viaje
      </Text>
      <GlobalCard>
        <FileInput
          sx={{
            button: {
              overflow: "hidden",
              span: {
                color: "#000",
              },
            },
          }}
          classNames={{
            input: classes.input,
            label: classes.labelSecondary,
          }}
          onChange={handleTravelCardFileChange}
          label="Adjuntar tarjeta de viaje efectivo de atención"
          description="Adjuntar un archivo con capacidad máxima de 200 MB."
          placeholder={cardTravel?.nombre ? String(cardTravel?.nombre) : ""}
          withAsterisk
          icon={<FaUpload size="1rem" />}
        />
        {cardTravel?.UlrArchivo && (
          <Anchor
            mt={15}
            href={cardTravel?.UlrArchivo}
            c="#fd7e14"
            target="_blank"
            underline
            download
          >
            Ver Documento
          </Anchor>
        )}
      </GlobalCard>

      <Text color="#004236" size="xl" fw={500}>
        Valor auxilio ambulatorio
      </Text>
      <GlobalCard styles={{ padding: 0 }}>
        <Grid columns={5} p={20}>
          {modifiedList?.map((item, index) => (
            <Grid.Col xs={5} md={1} key={item.title}>
              <Grid ta="center">
                <Grid.Col xs={4} md={12}>
                  <Text color="#004236" fw={500}>
                    {item.title}
                  </Text>
                </Grid.Col>
                {item.values.map((value, index) => (
                  <Grid.Col xs={4} md={12} key={index}>
                    {value && (
                      <Text>
                        {item.title === "Tarifa" || item.title === "Valor"
                          ? utils.formatter.format(value)
                          : value}
                      </Text>
                    )}
                  </Grid.Col>
                ))}
              </Grid>
            </Grid.Col>
          ))}
        </Grid>
        <Flex
          sx={{ background: "#F5F5F5" }}
          gap={12}
          px={15}
          py={15}
          justify="space-between"
        >
          <Text size="lg" weight={700} color="#004236">
            Total
          </Text>
          <Text size="lg" weight={700} color="#004236">
            {utils.formatter.format(
              isEdit
                ? dataLegalization?.[0]?.auxilioMedico?.ValorTotalAuxilio
                : aidData?.ValorTotalAuxilio
            )}
          </Text>
        </Flex>
      </GlobalCard>

      <ConfirmAid data={getAmbulanceData} GlobalCard={GlobalCard} />

      {filteredDataExpenses?.length > 0 && (
        <Text color="#004236" size="xl" fw={500}>
          Gastos reportados
        </Text>
      )}
      <ReportedExpenses {...reportedExpensesPropsExt} />

      <StatusExpenses {...statusExpensesProps} />

      <Flex align="center" gap={12}>
        <Button
          className={classes.buttonOutline}
          fullWidth
          onClick={() => navigateToUrl("solicitudes-auxilio-autogestion")}
        >
          Cancelar
        </Button>
        <Button
          className={classes.button}
          fullWidth
          disabled={disabledButtonExternal}
          onClick={() => handleSubmitExternalAid()}
        >
          Enviar a verificación
        </Button>
      </Flex>
    </Box>
  );
};
