import { Grid, Textarea, TextInput } from "@mantine/core";

export const StatusExpenses = ({
  classes,
  GlobalCard,
  expensesExternalAidData,
}) => {
  const { estado, observation } = expensesExternalAidData;
  return (
    !!observation?.length && (
      <GlobalCard>
        <Grid columns={5} align="start">
          <Grid.Col xs={5} md={1}>
            <TextInput
              data-testid="status-review"
              value={estado}
              classNames={{
                input: classes.input || "",
                label: classes.labelSecondary || "",
              }}
              label="Estado de revisión soportes"
              disabled={true}
            />
          </Grid.Col>
          <Grid.Col xs={5} md={4}>
            <Textarea
              data-testid="manager-comments"
              value={observation}
              classNames={{
                input: classes.input || "",
                label: classes.labelSecondary || "",
              }}
              label="Observaciones del gestor acerca de los soportes"
              disabled={true}
            />
          </Grid.Col>
        </Grid>
      </GlobalCard>
    )
  );
};
