import { useEffect, useState } from "react";
import {
  useStyles,
  GlobalCard,
  StepperComponent,
  ServiceDetailCard,
  ModalComponent,
  useWindowSize,
} from "@mfe/js-common-ave-uiutils";
import { LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  formatter,
  formatterUSD,
  getAccessTokenApi,
  fetchTraveLiquidatorByIDData,
  fetchTraveLiquidatorDelete,
  parseDateLocaleFormat,
  fetchTravelSendtoApproval,
  fetchTravelReservation,
  fetchTravelByIDData,
  numDays,
  fetchTravelGetReservationByFlight,
  currenciesSum,
  fetchTravelRequestRejectTrip,
  fetchTravelRequestApproveTrip,
  fetchUserAdditionalInfoByTravelData,
  fetchSeizureInfoData,
  filterByBeneficiaryType,
  fetchApproveUnions,
  fetchApproveNovelty,
  fetchRejectNovelty,
  fetchGetSpecialLandTransportData,
  fetchTravelRequestPreApproveTrip,
  fetchNumberOfTripsByUserData,
  valuePerNight,
  extractTime,
  lodash,
  fetchDeleteReferenceByID,
  isExternalFlow,
} from "@mfe/ts-common-ave-utilitaries";
import {
  MsalProvider,
  useIsAuthenticated,
  useMsal,
} from "@mfe/react-common-ave-msal";
import { navigateToUrl } from "single-spa";

import {
  renderReservation,
  renderConfirmServices,
} from "../components/modalConfirmations.component";
import { ReservationDetailsComponent } from "../components/reservationDetails.component";

export default function Reservation({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <ReservationDetails organization={organization} />
    </MsalProvider>
  );
}

function ReservationDetails({ organization }) {
  const { classes } = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const isMd = useWindowSize("md");
  const userData = JSON?.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [openedUnion, { open: openUnion, close: closeUnion }] =
    useDisclosure(false);
  const [openedReject, { open: openReject, close: closeReject }] =
    useDisclosure(false);
  const isHistorical = localStorage?.getItem("isHistorical") === "true";
  const [disableButton, setDisableButton] = useState(false);

  const [data, setData] = useState<any>({
    travelStatus: {},
    travelLiquidatorData: {},
    dataAid: [],
    seizureInfo: [],
    specialLandTransport: [],
    numberOfTrips: null,
  });

  const [tokenApi, setTokenApi] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [modalContent, setModalContent] = useState({
    content: {},
    showButtons: true,
    closeOnClickOutside: true,
    onClick: () => {},
    buttonName: "",
  });
  const { instance, accounts } = useMsal();
  const [reservationData, setReservationData] = useState([]);

  const formCheapest = useForm({
    initialValues: {
      justification: "",
    },
  });
  const formRejectTravel = useForm({
    validateInputOnChange: true,
    initialValues: {
      obsAnulacion: "",
    },
    validate: {
      obsAnulacion: (value) =>
        value.length > 2
          ? null
          : "Ingrese el motivo, con al menos 3 caracteres, del rechazo",
    },
  });

  const formApproveUnion = useForm({
    initialValues: {
      flights: 0,
      buses: 0,
    },
  });

  const {
    Id,
    CodigoEmpleadoAprobador,
    CodigoEmpleadoViajero,
    ViajeTrayecto,
    TipoViajeAlias,
    TipoProceso,
    Perfil,
    CodigoEmpleadoaprobadorPrevio,
    gestorCodigoEmpleado,
  } = data?.travelLiquidatorData || {};

  const validationData =
    (String(TipoProceso) === "1" && TipoViajeAlias === "TVINTERNCL") ||
    TipoViajeAlias !== "TVINTERNCL";

  const getFlightData = data?.travelLiquidatorData?.ViajeTrayecto;

  const isAid = TipoViajeAlias === "TVAMBULATORIOS";

  const isPreApprover =
    CodigoEmpleadoaprobadorPrevio === userData?.datosUsuario?.codigoEmpleado;

  const dataUserPermission = userData?.datosUsuario?.permisosUnicos;

  const isPermission = (permission) => dataUserPermission?.includes(permission);

  const isCDPPermission = isPermission("Control Presupuestal");
  const isExternalAid = isExternalFlow(dataUserPermission);
  const referenceByTravel = getFlightData?.[0]?.auxilioMedico?.NumeroRef;

  const modalText = {
    delete: "¿Está seguro de eliminar este viaje?",
    successDelete: `El viaje con ID ${Id} se eliminó correctamente`,
    sendToApprove: `${
      TipoProceso === 4 ? "El auxilio" : "Su viaje"
    } con ID ${Id} será enviado a aprobar.`,
    sendToApproveRequest: `¿Está seguro de aprobar esta comisión?`,
    successApprove: `El ${
      TipoProceso === 4 ? "auxilio" : "viaje"
    } con ID ${Id} se envió a aprobar correctamente`,
    successApproveRequest: `El viaje con ID ${Id} se aprobó correctamente`,
    didNotChooseTheCheapest:
      "Ingrese el motivo por el cual no escogió el servicio con tarifa más económica.",
    rejectTrip: "¿Está seguro de rechazar esta comisión?",
    rejectTripRequest: `El viaje con ID ${Id} se rechazó correctamente`,
    noveltyApproved: `La novedad fue aprobada exitosamente.`,
    noveltyRejected: `La novedad fue rechazada.`,
  };

  const formatValidationResult = (travelLiquidatorData) => {
    const { totalLiquidacion, codigoElementoPresupuestal } =
      travelLiquidatorData || {};

    if (!codigoElementoPresupuestal) {
      return null;
    }

    const [idElemPresup, tipoElemPresup] =
      codigoElementoPresupuestal.split(" / ");

    return {
      tipoElemPresup: tipoElemPresup || "",
      idElemPresup: idElemPresup || "",
      vrSolicitado: totalLiquidacion || 0,
    };
  };

  const spliceServices = () => {
    const services = {};
    const reservation = [];
    const confirmServices = [];

    getFlightData?.forEach((item) => {
      item.ServicioBpo.forEach((service) => {
        const {
          AliasServicio,
          Valor,
          ServicioId,
          descripcionServicio,
          nombreServicio,
        } = service;
        const serviceType = AliasServicio;

        reservation.push({
          idServicio: validateServiceKey(serviceType, false),
          idObjectoReserva: descripcionServicio
            ? JSON.parse(descripcionServicio).id
            : "",
        });

        confirmServices.push({
          service: validateServiceKey(serviceType, true),
          origin: item.nombreCiudadOrigen,
          destination: item.nombreCiudadDestino,
          valor: Valor,
        });

        if (Valor > 0) {
          services[serviceType] = services[serviceType] || {
            idServicioBpo: 0,
            total: 0,
            dataService: [],
          };
          services[serviceType].total += Valor;
          services[serviceType].idServicioBpo = ServicioId;

          switch (serviceType) {
            case "HOTEL":
              services[serviceType].dataService.push({
                id: JSON?.parse(descripcionServicio)?.id,
                nameHotel: nombreServicio,
                estrellasHotel: service.estrellasHotel,
                total: Valor,
                nombreCiudadOrigen: item.nombreCiudadOrigen,
                nombreCiudadDestino: item.nombreCiudadDestino,
                FechaInicio: item.FechaInicio,
                FechaFin: item.FechaFin,
                descripcionServicio: descripcionServicio,
              });
              break;
            case "AVION":
              const flightData = JSON?.parse(descripcionServicio);
              const flightSegments = validationData
                ? [flightData]
                : [
                    ...flightData?.departureFlight?.flightSegments,
                    ...flightData?.returnsFlight?.flightSegments,
                  ];
              services[serviceType].dataService.push(...flightSegments);
              break;
            case "ASISMED":
            case "ALQVEH":
              services[serviceType].dataService.push({
                Valor: Valor,
                nombreServicio: nombreServicio,
                descripcionServicio: descripcionServicio,
              });
              break;
          }
        }
      });
    });

    return {
      services,
      reservation: reservation.filter((item) => item.idObjectoReserva !== ""),
      confirmServices: confirmServices.filter((item) => item.service),
    };
  };

  const validateServiceKey = (key, isAlias) => {
    switch (key) {
      case "HOTEL":
        return isAlias ? "Hotel" : 2;
      case "AVION":
        return isAlias ? "Avion" : 1;
      case "ASISMED":
        return isAlias ? "Asistencia Medica" : 5;
      case "ALQVEH":
        return isAlias ? "Alquiler Vehiculo" : 4;
    }
  };

  const didChooseCheapest = () => {
    const services = ViajeTrayecto?.flatMap((item) => item.ServicioBpo).filter(
      ({ Valor }) => Valor > 0
    );
    return services?.some(
      (item) => JSON?.parse(item.descripcionServicio).isCheapest === false
    );
  };

  const allCosts = () => {
    return data?.travelLiquidatorData?.ViajeTrayecto?.reduce((acc, trip) => {
      trip.Viatico.forEach((cost) => {
        const alias = cost.AliasViatico;

        const existingCost = acc.find((c) => c.alias === alias);

        if (existingCost) {
          existingCost.value += cost.Valor;
          existingCost.details.push({
            originCity: trip.nombreCiudadOrigen,
            destinationCity: trip.nombreCiudadDestino,
            initialDate: trip.FechaInicio,
            finalDate: trip.FechaFin,
          });
        } else {
          acc.push({
            alias,
            value: cost.Valor,
            currency: cost.MonedaTipoId,
            details: [
              {
                originCity: trip.nombreCiudadOrigen,
                destinationCity: trip.nombreCiudadDestino,
                initialDate: trip.FechaInicio,
                finalDate: trip.FechaFin,
              },
            ],
          });
        }
      });

      return acc;
    }, []);
  };

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchUserAdditionalInfoByTravelData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID")
      );
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoading
      );
    }
  }, [tokenApi]);

  const getReservation = async () => {
    const { data } = await fetchTravelGetReservationByFlight(tokenApi, Id);
    setReservationData(data);
  };

  useEffect(() => {
    if (tokenApi && ViajeTrayecto?.length > 0) {
      fetchTravelByIDData(tokenApi, Id, setData, setLoading);
      fetchNumberOfTripsByUserData(setData, tokenApi, CodigoEmpleadoViajero);
      if (organization === "cenit")
        fetchGetSpecialLandTransportData(setData, tokenApi, Id);
    }
  }, [ViajeTrayecto?.length]);

  useEffect(() => {
    if (data?.travelStatus?.fkEstadoViaje) {
      data?.travelStatus?.fkEstadoViaje !== "GUARDADO" && getReservation();
    }
  }, [data?.travelStatus?.fkEstadoViaje]);

  useEffect(() => {
    if (data?.dataAid?.length > 0 && TipoProceso === 4) {
      fetchSeizureInfoData(
        setData,
        tokenApi,
        filterByBeneficiaryType(data?.dataAid, "TITULAR", "numeroDocumento"),
        filterByBeneficiaryType(
          data?.dataAid,
          "BENEFICIARIO",
          "numeroDocumento"
        )
      );
    }
  }, [data?.dataAid?.length, TipoProceso]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("isHistorical");
    };
  }, []);

  const handleDeleteLiquidationByID = async (
    accessToken,
    travelId,
    employeeCode
  ) => {
    try {
      setLoading(true);
      const response = await fetchTraveLiquidatorDelete(
        accessToken,
        travelId,
        employeeCode
      );

      isExternalAid &&
        (await fetchDeleteReferenceByID(accessToken, referenceByTravel));
      if (response.data) {
        setLoading(false);
        setModalContent({
          content: modalText["successDelete"],
          closeOnClickOutside: true,
          showButtons: false,
          onClick: () => {},
          buttonName: "",
        });
        setTimeout(() => {
          navigateToUrl(
            TipoProceso === 4 ? "/busqueda-beneficiario" : "/viajes"
          );
          localStorage.removeItem("isEdit");
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setModalContent({
          content: JSON?.parse(error.request.responseText).ExceptionMessage,
          closeOnClickOutside: true,
          showButtons: false,
          onClick: () => close(),
          buttonName: "",
        });
      }
    }
  };
  const handleSendToAproval = async (accessToken, travelId, employeeCode) => {
    const sendToApprove = async () => {
      try {
        setLoading(true);
        await fetchTravelSendtoApproval(accessToken, travelId, employeeCode);
        await fetchTravelByIDData(tokenApi, Id, setData, setLoading);
        setModalContent({
          content: `${modalText["successApprove"]}. Usted lleva ${
            Number(data?.numberOfTrips) + 1
          } realizados`,
          closeOnClickOutside: true,
          showButtons: false,
          onClick: () => {},
          buttonName: "",
        });
      } catch (error) {
        if (error.response) {
          if (
            JSON?.parse(
              error.request.responseText
            ).ExceptionMessage?.startsWith(
              "Excede el presupuesto de la vicepresidencia"
            )
          ) {
            setDisableButton(true);
          }

          setModalContent({
            content: JSON?.parse(error.request.responseText).ExceptionMessage,
            closeOnClickOutside: false,
            showButtons: false,
            onClick: () => {},
            buttonName: "",
          });
        }
      } finally {
        setLoading(false);
      }
    };

    try {
      setLoading(true);
      const resultadoValidacionCdp = formatValidationResult(
        data?.travelLiquidatorData
      );
      if (Object.keys(spliceServices().services).length > 0) {
        await fetchTravelReservation(accessToken, {
          idViaje: Id,
          codEmpleado: CodigoEmpleadoViajero,
          serviciosSeleccionados: spliceServices().reservation,
          codEmpleadoLogueado: userData.datosUsuario.codigoEmpleado,
          justificacion: formCheapest.values.justification,
          serviciosEspeciales: [],
          novedadadViaje: false,
          idNovedadViaje: 0,
          ...(isCDPPermission && {
            resultadoValidacionCdp: resultadoValidacionCdp,
          }),
        });

        const responseReservationByFlight =
          await fetchTravelGetReservationByFlight(tokenApi, Id);
        setModalContent({
          content: renderReservation(responseReservationByFlight.data),
          showButtons: true,
          closeOnClickOutside: false,
          onClick: () => sendToApprove(),
          buttonName: "",
        });
      } else {
        await sendToApprove();
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setModalContent({
          content: JSON?.parse(error.request.responseText).ExceptionMessage,
          closeOnClickOutside: false,
          showButtons: false,
          onClick: () => {},
          buttonName: "",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAction = (modalContent, actionFunction) => {
    open();
    setModalContent({
      content: modalContent,
      showButtons: true,
      closeOnClickOutside: true,
      onClick: actionFunction,
      buttonName: "",
    });
  };

  const handleApproveTrip = async () => {
    try {
      setLoading(true);
      !isPreApprover
        ? TipoViajeAlias === "TVSINDICATOS"
          ? await fetchApproveUnions(tokenApi, {
              travelId: Id,
              approverCode: CodigoEmpleadoAprobador,
              flights: formApproveUnion.values.flights,
              buses: formApproveUnion.values.buses,
            })
          : await fetchTravelRequestApproveTrip(
              tokenApi,
              Id,
              CodigoEmpleadoAprobador
            )
        : await fetchTravelRequestPreApproveTrip(
            tokenApi,
            Id,
            CodigoEmpleadoaprobadorPrevio
          );
      await fetchTravelByIDData(tokenApi, Id, setData, setLoading);
      closeUnion();
      setModalContent({
        content: modalText["successApproveRequest"],
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => {},
        buttonName: "",
      });
      open();
    } catch (error) {
      setLoading(false);
      closeUnion();
      setModalContent({
        content: JSON.parse(error?.request?.responseText)?.ExceptionMessage,
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => close(),
        buttonName: "",
      });
      open();
    } finally {
      setLoading(false);
    }
  };

  const handleRejectTrip = async () => {
    try {
      setLoading(true);
      await fetchTravelRequestRejectTrip(tokenApi, {
        id: Id,
        codAprobador: CodigoEmpleadoAprobador,
        descripcionRechazo: formRejectTravel.values.obsAnulacion,
      });
      await fetchTravelByIDData(tokenApi, Id, setData, setLoading);
      closeReject();
      setModalContent({
        content: modalText["rejectTripRequest"],
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => {},
        buttonName: "",
      });
      open();
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => close(),
        buttonName: "",
      });
      open();
    } finally {
      setLoading(false);
    }
  };

  const handleApproveNovelty = async (novelty) => {
    const payload = {
      noveltyPutRequestInformation: {
        idViaje: Id,
        idNovedad: novelty?.IdNovedad,
        codEmpleado: CodigoEmpleadoViajero,
        perfilCodEmpleado: Perfil,
        motivoCambEstado: "",
      },
      idFilial: organization === "ecopetrol" ? "ECP" : "CNT",
    };
    try {
      setLoading(true);
      await fetchApproveNovelty(tokenApi, payload);
      setModalContent({
        content: modalText["noveltyApproved"],
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => {},
        buttonName: "",
      });
      open();
      await fetchTravelByIDData(tokenApi, Id, setData, setLoading);
    } catch (error) {
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => close(),
        buttonName: "",
      });
      open();
    } finally {
      setLoading(false);
    }
  };

  const handleRejectNovelty = async (novelty) => {
    const payload = {
      noveltyPutRequestInformation: {
        idViaje: Id,
        idNovedad: novelty?.IdNovedad,
        codEmpleado: CodigoEmpleadoViajero,
        perfilCodEmpleado: Perfil,
        motivoCambEstado: "",
      },
      idFilial: organization === "ecopetrol" ? "ECP" : "CNT",
    };
    try {
      setLoading(true);
      await fetchRejectNovelty(tokenApi, payload);
      setModalContent({
        content: modalText["noveltyRejected"],
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => {},
        buttonName: "",
      });
      open();
      await fetchTravelByIDData(tokenApi, Id, setData, setLoading);
    } catch (error) {
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => close(),
        buttonName: "",
      });
      open();
    } finally {
      setLoading(false);
    }
  };

  const handleApproveTripAction = () => {
    TipoViajeAlias === "TVSINDICATOS" &&
    userData?.datosUsuario?.filial !== "CNT"
      ? handleOpenUnion()
      : handleAction(modalText["sendToApproveRequest"], () =>
          handleApproveTrip()
        );
  };

  const handleSendApproveAction = async () => {
    const numberTrips = Number(data?.numberOfTrips) + 1;
    const textToShow =
      TipoProceso === 4
        ? `Su auxilio número ${numberTrips} de su beneficiario ${filterByBeneficiaryType(
            data?.dataAid,
            "BENEFICIARIO",
            "nombres"
          )} ${filterByBeneficiaryType(
            data?.dataAid,
            "BENEFICIARIO",
            "apellidos"
          )} con ID ${Id} será enviado a aprobar. Usted tiene ${
            numberTrips - 1
          } auxilios en total.`
        : `Su viaje número ${
            Number(data?.numberOfTrips) + 1
          } con ID ${Id} será enviado a aprobar.`;
    open();
    setModalContent({
      content: didChooseCheapest()
        ? modalText["didNotChooseTheCheapest"]
        : textToShow,
      closeOnClickOutside: true,
      showButtons: true,
      buttonName: didChooseCheapest() ? "" : "Enviar a Aprobar",
      onClick: () => {
        if (Object.keys(spliceServices().services).length > 0) {
          setModalContent({
            content: renderConfirmServices(
              lodash(spliceServices()?.confirmServices)
                .sortBy("service")
                .value()
            ),
            showButtons: true,
            closeOnClickOutside: true,
            onClick: () =>
              handleSendToAproval(
                tokenApi,
                Id,
                gestorCodigoEmpleado
                  ? gestorCodigoEmpleado
                  : CodigoEmpleadoViajero
              ),
            buttonName: "",
          });
        } else {
          handleSendToAproval(
            tokenApi,
            Id,
            gestorCodigoEmpleado ? gestorCodigoEmpleado : CodigoEmpleadoViajero
          );
        }
      },
    });
  };

  const handleDeleteTravelAction = () => {
    open();
    setModalContent({
      content: modalText["delete"],
      closeOnClickOutside: true,
      showButtons: true,
      onClick: () =>
        handleDeleteLiquidationByID(tokenApi, Id, CodigoEmpleadoViajero),
      buttonName: "",
    });
  };

  const handleOpenUnion = () => {
    openUnion();
  };

  const navigateToHistorical = () => {
    navigateToUrl(
      TipoViajeAlias === "TVAMBULATORIOS" ? "/historico-aux" : "/historico"
    );
    localStorage.removeItem("isHistorical");
  };

  const navigateToEditTravel = async () => {
    try {
      setLoading(true);
      isExternalAid &&
        (await fetchDeleteReferenceByID(tokenApi, referenceByTravel));
      localStorage.setItem("isEdit", "true");
      navigateToUrl(isAid ? "auxilios" : "viajes");
    } catch (error) {
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => close(),
        buttonName: "",
      });
      open();
    } finally {
      setLoading(false);
    }
  };

  const printWindow = () => window.print();

  const utils = {
    numDays,
    valuePerNight,
    extractTime,
    formatter,
    formatterUSD,
    currenciesSum,
    parseDateLocaleFormat,
  };

  const reservationDetailsComponentProps = {
    data,
    utils,
    classes,
    GlobalCard,
    StepperComponent,
    ServiceDetailCard,
    ModalComponent,
    isHistorical,
    handleRejectTrip,
    handleApproveTrip,
    handleAction,
    modalText,
    handleOpenUnion,
    userData,
    handleSendToAproval,
    tokenApi,
    spliceServices,
    setModalContent,
    didChooseCheapest,
    handleDeleteLiquidationByID,
    filterByBeneficiaryType,
    allCosts,
    reservationData,
    validationData,
    handleRejectNovelty,
    handleApproveNovelty,
    openedUnion,
    closeUnion,
    formApproveUnion,
    formCheapest,
    modalContent,
    opened,
    isPreApprover,
    isMd,
    navigateToHistorical,
    printWindow,
    handleApproveTripAction,
    handleSendApproveAction,
    navigateToEditTravel,
    handleDeleteTravelAction,
    close,
    openedReject,
    closeReject,
    formRejectTravel,
    openReject,
    disableButton,
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <ReservationDetailsComponent {...reservationDetailsComponentProps} />
  ) : null;
}
