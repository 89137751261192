import React from "react";
import moment from "moment";
import { groupBy } from "lodash";
import { AiFillWarning } from "react-icons/ai";
import { Text, Grid, Button, Flex, Box, Badge, Textarea } from "@mantine/core";
import { ContableReport } from "./contableReport.component";
import { OtherExpenses } from "./otherExpenses.component";
import AccordionService from "./accordionService.component";
import Costs from "./costs.component";
import InfoAid from "./infoAid.component";
import SeizureCard from "./seizureCard.component";
import AidValueCard from "./aidValueCard.component";
import { BankSupport } from "./bankSupport.component";
import { PayrollDeduction } from "./payrollDeduction.component";
import ModalUnionApprove from "./modalUnionApprove.component";

const textStyles = {
  fontSize: "1.5rem",
  "@media print": {
    fontSize: "1.2rem",
  },
};

const buttonStyles = {
  "@media print": {
    display: "none",
  },
};

const labelTextStyles = {
  "@media print": {
    fontSize: "0.8rem",
  },
};

const totalTextStyles = {
  "@media print": {
    fontSize: "1rem",
  },
};

export const LegalizationDetailComponent = ({
  data,
  form,
  isMd,
  GlobalCard,
  classes,
  utils,
  ModalComponent,
  ServiceDetailCard,
  isHistorical,
  handleApproveZeroTripAction,
  handleApproveTripAction,
  handleRejectTrip,
  handleApproveLegalizationAction,
  userData,
  gotoLegalization,
  isPreApproverTravel,
  handleOpenSupport,
  handlePSE,
  gotoReservationDetails,
  openedUnion,
  closeUnion,
  modalContent,
  opened,
  close,
  openedSupports,
  closeSupports,
  allCosts,
  handleSubmitSupports,
  getAllBpo,
  filterByBeneficiaryType,
  handleApproveTrip,
  formApproveUnion,
  navigateToHistorical,
  printWindow,
  legalizationTravelExpense,
  handleAttachSupport,
  expensesSupportReview,
  isExternalAid,
  openedReject,
  closeReject,
  formRejectTravel,
  openReject,
  handlePayrollDeduction,
  openedPayroll,
  closePayroll,
  observationForm,
  handleUploadSupportPayrollDeduction,
  payrollDeductionForm,
}) => {
  const {
    Id,
    CodigoEmpleadoViajero,
    NombreEmpleadoViajero,
    ViajeTrayecto,
    codigoElementoPresupuestal,
    descripcion,
    ComisionAlias,
    TipoViajeAlias,
    descricionEmergencia,
    Multiviaje,
    TipoProceso,
    TieneViaticoPermanente,
  } = data?.travelLiquidatorData || {};

  const { TotalBpo } = data?.legalization?.data?.liquidacion || {};

  const dataLegalization = data?.legalization?.data;

  const itsZero = dataLegalization?.tipoLegalizacion === "CERO";

  const initialDate =
    dataLegalization?.liquidacion?.ViajeTrayecto?.[0]?.FechaInicio;

  const endDate =
    dataLegalization?.liquidacion?.ViajeTrayecto?.[
      dataLegalization?.liquidacion?.ViajeTrayecto?.length - 1
    ]?.FechaFin;

  const totalDays = `${dataLegalization?.diasViaje} Días`;

  const isInternationalFlight =
    data?.travelLiquidatorData?.TipoViajeAlias === "TVINTERNCL";

  const isApprovedTravel =
    data?.travelLiquidatorData?.CodigoEmpleadoAprobador ===
    userData?.datosUsuario?.codigoEmpleado;

  const isAid = TipoProceso === 4;

  const isManagerAux =
    TipoProceso === 4 &&
    data?.travelStatus?.funcionarioIdGestor ===
      userData?.datosUsuario?.codigoEmpleado;

  const dateOfApproved = data?.travelStatus?.listaEstados
    ?.filter(
      (estado) =>
        estado.paramFkEstadoViaje === "APROBADO" ||
        estado.paramFkEstadoViaje === "CONFIRMADO"
    )
    .sort((a, b) => b.id - a.id);

  const modifyLegalizationStates = [
    "ANULFAVORECP",
    "APROPAGSAP",
    "LEGALIZENV",
    "LEGAPROSNV",
    "LEGAPROSAF",
    "LEGAPROSEC",
    "CERRADO",
    "PEDNCONFIRMACIONLEG",
    "LEGALIZENVPREV",
    "VIJCERRADO",
    "DESCNOMINA",
  ];

  const allowedModifyLegalization = !modifyLegalizationStates.includes(
    data?.travelStatus?.fkEstadoViaje
  );

  const infoAidProps = {
    data: data?.travelLiquidatorData,
    extraData: data?.travelStatus,
    dataAid: data?.dataAid,
    dataLiquidation: dataLegalization,
    filterByBeneficiaryType,
    utils,
  };

  const seizureCardProps = { data: data?.seizureInfo, classes, utils };

  const accordionServiceProps = {
    data: getAllBpo,
    GlobalCard,
    utils,
  };

  const costProps = {
    utils,
    data: allCosts(),
    isInternational: isInternationalFlight,
    ModalComponent,
    isMd,
  };

  const aidValueCardProps = {
    utils,
    liquidator: dataLegalization?.liquidacion,
    dataAid: data?.dataAid,
    ModalComponent,
    filterByBeneficiaryType,
  };

  const otherExpensesProps = {
    data: legalizationTravelExpense,
    travelExpensesType: isAid ? data?.travelExpenses : data?.travelExpensesType,
    GlobalCard,
    utils,
  };

  const contableReportProps = {
    utils,
    GlobalCard,
    data: Object.entries(groupBy(data?.accountingReports, "nombreTercero")),
  };

  const bankSupportProps = {
    opened: openedSupports,
    close: closeSupports,
    item: data?.travelStatus,
    form,
    handleSubmitSupports,
    ModalComponent,
    classes,
    utils,
    handleAttachSupport,
  };

  const payrollDescountProps = {
    classes,
    ModalComponent,
    opened: openedPayroll,
    close: closePayroll,
    form: payrollDeductionForm,
    handleAttachSupport,
    handleUploadSupportPayrollDeduction,
  };

  return (
    <>
      <ModalComponent
        title="Alerta"
        opened={opened}
        onClose={close}
        closeOnClickOutside={modalContent.closeOnClickOutside}
      >
        <Text ta="center" fw={700} color="#004236">
          {modalContent.content}
        </Text>
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={modalContent.onClick}
              fullWidth
              className={classes.button}
            >
              Aceptar
            </Button>
            <Button onClick={close} fullWidth className={classes.buttonOutline}>
              Cancelar
            </Button>
          </Flex>
        )}
      </ModalComponent>
      <ModalUnionApprove
        form={formApproveUnion}
        opened={openedUnion}
        onClose={closeUnion}
        handleApprove={handleApproveTrip}
        item={data?.travelStatus}
        ModalComponent={ModalComponent}
        classes={classes}
      />

      <ModalComponent
        opened={openedReject}
        onClose={closeReject}
        title="Alerta"
      >
        <Text ta="center" fw={700} color="#004236">
          La legalización del viaje {Id} será rechazado. Ingrese la observación
          o razón del rechazo
        </Text>
        <Textarea
          value={formRejectTravel.values.obsAnulacion}
          onChange={(e) =>
            formRejectTravel.setFieldValue("obsAnulacion", e.target.value)
          }
          label="Observación"
          placeholder="Escriba aquí su observación"
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          mt={12}
        />

        <Flex gap={16} my={12}>
          <Button
            onClick={handleRejectTrip}
            fullWidth
            className={classes.button}
            disabled={!formRejectTravel.isValid()}
          >
            Aceptar
          </Button>
          <Button
            onClick={closeReject}
            fullWidth
            className={classes.buttonOutline}
          >
            Cancelar
          </Button>
        </Flex>
      </ModalComponent>
      <BankSupport {...bankSupportProps} />
      <PayrollDeduction {...payrollDescountProps} />

      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
        <Grid py={16}>
          <Grid.Col span="auto">
            <Text size={24} fw={700} color="#004236">
              Información general
            </Text>
          </Grid.Col>
          <Grid.Col span="auto" ta="right">
            <Text size={24} fw={700} color="#004236">
              Estado
              <Text span c="orange" inherit>
                {` ${
                  data?.travelStatus?.listaEstados?.find(
                    (status) =>
                      status.paramFkEstadoViaje ===
                      data?.travelStatus?.fkEstadoViaje
                  ).paramNombreEstado
                }`}
              </Text>
            </Text>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col
            xs={12}
            md={9}
            sx={{
              "@media print": {
                flexBasis: "70%",
                maxWidth: "70%",
              },
            }}
          >
            {dataLegalization?.detSoportesFaltantes?.[0]?.length > 0 && (
              <GlobalCard>
                {dataLegalization?.detSoportesFaltantes?.[0]}
              </GlobalCard>
            )}
            <ServiceDetailCard
              showTotal
              totalDays={totalDays}
              showTotalDays
              gridStyles={{
                ".mantine-Grid-col": {
                  "@media print": {
                    flexBasis: "33%",
                    maxWidth: "33%",
                  },
                },
              }}
            >
              {isAid ? (
                <>
                  <InfoAid {...infoAidProps} />
                  {data?.travelLiquidatorData?.ViajeTrayecto?.[0]?.auxilioMedico
                    ?.ObservacionDestinoPena && (
                    <Grid.Col pt={0}>
                      <Box>
                        <Text size="sm" fw={700} color="#004236">
                          Justificación PENA:
                        </Text>
                        <Text size="sm">
                          {
                            data?.travelLiquidatorData?.ViajeTrayecto?.[0]
                              ?.auxilioMedico?.ObservacionDestinoPena
                          }
                        </Text>
                      </Box>
                    </Grid.Col>
                  )}
                </>
              ) : (
                <>
                  {TipoViajeAlias === "TVEMERGENCIA" && (
                    <Grid.Col
                      sx={{
                        "@media print": {
                          flexBasis: "100% !important",
                          maxWidth: "100% !important",
                        },
                      }}
                    >
                      <Badge
                        sx={{
                          backgroundColor: "#ed7703",
                          color: "#fff",
                        }}
                      >
                        Viaje de emergencia
                      </Badge>
                    </Grid.Col>
                  )}
                  {TieneViaticoPermanente && (
                    <Grid.Col
                      sx={{
                        "@media print": {
                          flexBasis: "100% !important",
                          maxWidth: "100% !important",
                        },
                      }}
                    >
                      <Badge
                        sx={{
                          backgroundColor: "#ed7703",
                          color: "#fff",
                        }}
                      >
                        Viaje con viático permanente
                      </Badge>
                    </Grid.Col>
                  )}
                  <Grid.Col xs={12} md={4}>
                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Viajero:
                      </Text>
                      <Text size="sm">{NombreEmpleadoViajero}</Text>
                    </Box>
                    <Box my={12}>
                      <Text size="sm" fw={700} color="#004236">
                        T. Viaje / T. Comisión:
                      </Text>
                      <Text size="sm">
                        {TipoViajeAlias} / {ComisionAlias}
                      </Text>
                    </Box>
                    {Multiviaje ? (
                      (itsZero ||
                      data?.travelStatus?.fkEstadoViaje === "ANULFAVORECP"
                        ? ViajeTrayecto
                        : dataLegalization?.liquidacion?.ViajeTrayecto
                      )?.map((item, index) => (
                        <Box key={index}>
                          <Box my={12}>
                            <Text size="sm" fw={700} color="#004236">
                              Ciudad Origen:
                            </Text>
                            <Text size="sm">{item?.nombreCiudadOrigen}</Text>
                          </Box>
                          <Box my={12}>
                            <Text size="sm" fw={700} color="#004236">
                              Fecha Inicio:
                            </Text>
                            <Text size="sm">
                              {utils.parseDateLocaleFormat(item?.FechaInicio)}
                            </Text>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box my={12}>
                        <Text size="sm" fw={700} color="#004236">
                          Ciudad Origen:
                        </Text>
                        <Text size="sm">
                          {ViajeTrayecto?.[0]?.nombreCiudadOrigen}
                        </Text>
                      </Box>
                    )}
                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Id Viaje
                      </Text>
                      <Text size="sm">{Id}</Text>
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={12} md={4}>
                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Cod. Empleado:
                      </Text>
                      <Text size="sm">{CodigoEmpleadoViajero}</Text>
                    </Box>
                    <Box my={12}>
                      <Text size="sm" fw={700} color="#004236">
                        Fecha Inicio Viaje:
                      </Text>
                      <Text size="sm" transform="capitalize">
                        {utils.parseDateLocaleFormat(initialDate)}
                      </Text>
                    </Box>
                    {Multiviaje ? (
                      (itsZero ||
                      data?.travelStatus?.fkEstadoViaje === "ANULFAVORECP"
                        ? ViajeTrayecto
                        : dataLegalization?.liquidacion?.ViajeTrayecto
                      )?.map((item, index) => (
                        <Box key={index}>
                          <Box my={12}>
                            <Text size="sm" fw={700} color="#004236">
                              Ciudad Destino:
                            </Text>
                            <Text size="sm">{item?.nombreCiudadDestino}</Text>
                          </Box>
                          <Box my={12}>
                            <Text size="sm" fw={700} color="#004236">
                              Fecha Fin:
                            </Text>
                            <Text size="sm">
                              {utils.parseDateLocaleFormat(item?.FechaFin)}
                            </Text>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box my={12}>
                        <Text size="sm" fw={700} color="#004236">
                          Ciudad Destino:
                        </Text>
                        <Text size="sm">
                          {ViajeTrayecto?.[0]?.nombreCiudadDestino}
                        </Text>
                      </Box>
                    )}

                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Objetivo del viaje:
                      </Text>
                      <Text size="sm">{descripcion}</Text>
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={12} md={4}>
                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Centro de costo:
                      </Text>
                      <Text size="sm">{codigoElementoPresupuestal}</Text>
                    </Box>
                    <Box my={12}>
                      <Text size="sm" fw={700} color="#004236">
                        Fecha Fin Viaje:
                      </Text>
                      <Text size="sm" transform="capitalize">
                        {utils.parseDateLocaleFormat(endDate)}
                      </Text>
                    </Box>
                    <Box my={12}>
                      <Text size="sm" fw={700} color="#004236">
                        Gestor del viaje:
                      </Text>
                      <Text size="sm">
                        {data?.travelStatus?.funcionarioNombreGestor
                          ? data?.travelStatus?.funcionarioNombreGestor
                          : "N/A"}
                      </Text>
                    </Box>
                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Tiene que viajar:
                      </Text>
                      <Text size="sm">Sí</Text>
                    </Box>
                  </Grid.Col>
                  {TipoViajeAlias === "TVEMERGENCIA" && (
                    <Grid.Col xs={12} md={4}>
                      <Box>
                        <Text size="sm" fw={700} color="#004236">
                          Objetivo de la emergencia:
                        </Text>
                        <Text size="sm">{descricionEmergencia}</Text>
                      </Box>
                    </Grid.Col>
                  )}
                </>
              )}
            </ServiceDetailCard>

            {data?.seizureInfo?.length > 0 && (
              <ServiceDetailCard title="Con pago a un tercero">
                <SeizureCard {...seizureCardProps} />
              </ServiceDetailCard>
            )}

            {isAid && (
              <>
                <ServiceDetailCard
                  totalLabel="Total"
                  title="Valor auxilio ambulatorio"
                  totalCop={utils.formatter.format(
                    dataLegalization?.liquidacion?.ViajeTrayecto?.[0]
                      ?.auxilioMedico?.ValorTotalAuxilio
                  )}
                >
                  <AidValueCard {...aidValueCardProps} />
                </ServiceDetailCard>
                <ServiceDetailCard title="Fechas del auxilio ambulatorio">
                  <Grid.Col>
                    <Grid columns={5} fz="sm" fw={700} color="#004236">
                      <Grid.Col span={1}>
                        <Text color="#004236">Nombre</Text>
                      </Grid.Col>

                      <Grid.Col span={1}>
                        <Text color="#004236">Tipo</Text>
                      </Grid.Col>

                      <Grid.Col span={1}>
                        <Text color="#004236">Fecha inicial</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text color="#004236">Fecha final</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text color="#004236">Retorno</Text>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                  <Grid.Col>
                    <Grid columns={5} fz="sm">
                      <Grid.Col span={1}>
                        <Text>
                          {`${filterByBeneficiaryType(
                            data?.dataAid,
                            "BENEFICIARIO",
                            "nombres"
                          )} ${filterByBeneficiaryType(
                            data?.dataAid,
                            "BENEFICIARIO",
                            "apellidos"
                          )}`}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text>Beneficiario</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text>
                          {utils.parseDateLocaleFormat(
                            dataLegalization?.liquidacion?.ViajeTrayecto?.[0]
                              ?.auxilioMedico?.PerBeneficiario?.fechaInicioViaje
                          )}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text>
                          {utils.parseDateLocaleFormat(
                            dataLegalization?.liquidacion?.ViajeTrayecto?.[0]
                              ?.auxilioMedico?.PerBeneficiario?.fechaFinViaje
                          )}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text>
                          {dataLegalization?.liquidacion?.ViajeTrayecto?.[0]
                            ?.auxilioMedico?.PerBeneficiario?.retorno === false
                            ? "No"
                            : "Sí"}
                        </Text>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                  {dataLegalization?.liquidacion?.ViajeTrayecto?.[0]
                    ?.auxilioMedico?.Acompanante && (
                    <Grid.Col>
                      <Grid columns={5} fz="sm">
                        <Grid.Col span={1}>
                          <Text>
                            {`${filterByBeneficiaryType(
                              data?.dataAid,
                              "ACOMPANANTE",
                              "nombres"
                            )} ${filterByBeneficiaryType(
                              data?.dataAid,
                              "ACOMPANANTE",
                              "apellidos"
                            )}`}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Text>Acompañante</Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Text>
                            {utils.parseDateLocaleFormat(
                              dataLegalization?.liquidacion?.ViajeTrayecto?.[0]
                                ?.auxilioMedico?.PerAcompanante
                                ?.fechaInicioViaje
                            )}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Text>
                            {utils.parseDateLocaleFormat(
                              dataLegalization?.liquidacion?.ViajeTrayecto?.[0]
                                ?.auxilioMedico?.PerAcompanante?.fechaFinViaje
                            )}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Text>
                            {dataLegalization?.liquidacion?.ViajeTrayecto?.[0]
                              ?.auxilioMedico?.PerAcompanante?.retorno === false
                              ? "No"
                              : "Sí"}
                          </Text>
                        </Grid.Col>
                      </Grid>
                    </Grid.Col>
                  )}
                </ServiceDetailCard>
              </>
            )}
            {!!allCosts()?.length && (
              <ServiceDetailCard
                totalLabel="Total"
                title="Viáticos"
                totalCop={
                  isInternationalFlight
                    ? utils.formatter.format(
                        dataLegalization?.liquidacion?.ViaticoInt
                      )
                    : utils.formatter.format(
                        dataLegalization?.liquidacion?.TotalViaticos
                      )
                }
                totalUsd={
                  !isInternationalFlight
                    ? 0
                    : utils.formatterUSD.format(
                        dataLegalization?.liquidacion?.totalViaticosUsd
                      )
                }
                hasInfo
              >
                <Grid.Col>
                  <Grid>
                    <Grid.Col span={6}>
                      <Text size="sm" fw={700} color="#004236">
                        Concepto
                      </Text>
                    </Grid.Col>

                    <Grid.Col
                      span={3}
                      sx={{
                        display: !isInternationalFlight && "none",
                      }}
                    >
                      <Text ta="right" size="sm" fw={700} color="#004236">
                        Dólares
                      </Text>
                    </Grid.Col>

                    <Grid.Col span={isInternationalFlight ? 3 : 6}>
                      <Text size="sm" ta="right" fw={700} color="#004236">
                        Pesos
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Costs {...costProps} />
              </ServiceDetailCard>
            )}
            {getAllBpo.length > 0 && (
              <ServiceDetailCard
                totalLabel="Total"
                title="Servicios de viajes"
                totalCop={utils.formatter.format(TotalBpo)}
                totalUsd={0}
              >
                <AccordionService {...accordionServiceProps} />
              </ServiceDetailCard>
            )}

            {data?.accountingReports?.length > 0 && (
              <ServiceDetailCard
                totalLabel="Total"
                title="Reporte contable gastos servicios Aviatur"
                totalCop={utils.formatter.format(TotalBpo)}
                totalUsd={0}
              >
                <Grid.Col p={15}>
                  <ContableReport {...contableReportProps} />
                </Grid.Col>
              </ServiceDetailCard>
            )}

            {legalizationTravelExpense?.length > 0 && (
              <ServiceDetailCard
                totalLabel="Total"
                title="Otros gastos"
                totalCop={utils.formatter.format(
                  dataLegalization?.liquidacion?.TotalGastos
                )}
                totalUsd={
                  !isInternationalFlight
                    ? 0
                    : utils.formatterUSD.format(
                        dataLegalization?.liquidacion?.totalGastosUsd
                      )
                }
              >
                <OtherExpenses {...otherExpensesProps} />
              </ServiceDetailCard>
            )}

            {dataLegalization?.justificacion !== "" && (
              <>
                <Text size="lg" color="#004236" weight={700}>
                  Justificación legalización
                </Text>
                <GlobalCard>{dataLegalization?.justificacion}</GlobalCard>
              </>
            )}

            {data?.travelStatus?.fkEstadoViaje === "ANULFAVORECP" && (
              <>
                <Text size="lg" color="#004236" weight={700}>
                  Justificación Anulación
                </Text>
                <GlobalCard>{data?.travelStatus?.obsAnulacion}</GlobalCard>
              </>
            )}
            <Box>
              <Text size="lg" color="#004236" weight={700} sx={totalTextStyles}>
                Valor total
              </Text>
              {TipoViajeAlias === "TVAMBULATORIOS" && (
                <Flex
                  bg="#cbd300"
                  py={10}
                  px={15}
                  sx={{ borderRadius: "0.5rem" }}
                  mt={20}
                  justify="space-between"
                  align="center"
                >
                  <Text
                    size="lg"
                    color="#004236"
                    fw={700}
                    ml="auto"
                    sx={totalTextStyles}
                  >
                    {utils.formatter.format(
                      dataLegalization?.liquidacion?.ViajeTrayecto?.[0]
                        ?.TotalAveLiqCop
                    )}
                  </Text>
                </Flex>
              )}
              <Flex
                bg={TipoViajeAlias === "TVAMBULATORIOS" ? "#000" : "#cbd300"}
                py={10}
                px={15}
                sx={{ borderRadius: "0.5rem" }}
                my={20}
                justify="space-between"
                align="center"
              >
                {TipoViajeAlias === "TVINTERNCL" && (
                  <Text size="lg" color="#004236" fw={700} sx={totalTextStyles}>
                    Total (Viáticos + Otros gastos)
                  </Text>
                )}
                <Text
                  size="lg"
                  color={
                    TipoViajeAlias === "TVAMBULATORIOS" ? "#fff" : "#004236"
                  }
                  fw={700}
                  ml="auto"
                  sx={totalTextStyles}
                >
                  {TipoViajeAlias === "TVINTERNCL"
                    ? `${utils.currenciesSum(
                        [
                          dataLegalization?.liquidacion?.totalGastosUsd,
                          dataLegalization?.liquidacion?.totalViaticosUsd,
                        ],
                        true
                      )} + ${utils.currenciesSum([
                        dataLegalization?.liquidacion?.TotalGastos,
                        isInternationalFlight
                          ? dataLegalization?.liquidacion?.ViaticoInt
                          : dataLegalization?.liquidacion?.TotalViaticos,
                      ])}`
                    : utils.formatter.format(
                        dataLegalization?.liquidacion?.totalLiquidacion
                      )}
                </Text>
              </Flex>
            </Box>
          </Grid.Col>
          <Grid.Col
            xs={12}
            md={3}
            sx={{
              "@media print": {
                flexBasis: "30%",
                maxWidth: "30%",
              },
            }}
          >
            <GlobalCard>
              <Flex justify="center" align="center" direction="column">
                <Text size="lg" fw={700} color="#004236">
                  Detalle Legalización
                </Text>
                {TipoViajeAlias !== "TVTURNOS" && (
                  <Flex
                    justify="center"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    gap={8}
                  >
                    <Text size="md" sx={labelTextStyles}>
                      Total días:
                    </Text>
                    <Text sx={{ fontSize: "1.5rem" }} fw={700} color="#004236">
                      {totalDays}
                    </Text>
                  </Flex>
                )}

                <Text size="md" ta="center" sx={labelTextStyles}>
                  Valor Anticipo: <br />
                  {`${
                    TipoViajeAlias === "TVAMBULATORIOS"
                      ? "(Auxilio ambulatorio + otros gastos)"
                      : "(Viáticos + Servicios de viajes)"
                  }`}
                </Text>
                <Text sx={textStyles} fw={700} color="#004236">
                  {utils.formatter.format(
                    Math.abs(Math.floor(dataLegalization?.valorAbonadoCop))
                  )}
                </Text>
                <Text size="md" ta="center" sx={labelTextStyles}>
                  {`Gastos totales ${
                    TipoViajeAlias === "TVAMBULATORIOS"
                      ? "del auxilio"
                      : "de viajes"
                  }`}
                </Text>
                <Text sx={textStyles} fw={700} color="#004236">
                  {utils.formatter.format(
                    Math.abs(
                      Math.floor(
                        dataLegalization?.liquidacion?.totalLiquidacion
                      )
                    )
                  )}
                </Text>
                <Text size="md" ta="center" sx={labelTextStyles}>
                  {dataLegalization?.tipoLegalizacion === "CERO" && (
                    <Text>Saldo en cero</Text>
                  )}
                  {dataLegalization?.tipoLegalizacion === "MENOR" && (
                    <Text>Valor a Favor de la empresa</Text>
                  )}
                  {dataLegalization?.tipoLegalizacion === "MAYOR" && (
                    <Text>Valor a Favor del Viajero</Text>
                  )}
                </Text>
                <Text sx={textStyles} fw={700} color="#004236">
                  {utils.formatter.format(
                    Math.abs(Math.floor(dataLegalization?.totalAveLegCop))
                  )}
                </Text>
                <GlobalCard
                  styles={{
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <AiFillWarning
                    color="#004236"
                    size="24px"
                    style={{
                      flexShrink: 0,
                      float: "left",
                      marginRight: "8px",
                      marginTop: "8px",
                    }}
                  />
                  <Text size="sm">
                    Corresponde a la diferencia del valor consignado menos el
                    valor legalizado (de Viáticos y otros gastos). No incluye
                    los servicios de viaje dados por la agencia.
                  </Text>
                </GlobalCard>
                <Box bg="#F6F6F6" w="100%" p={10} my={10}>
                  <Text size="sm" ta="left">
                    Aprobador
                  </Text>
                  <Text size="sm" fw={700} color="#004236" ta="left">
                    {data?.travelStatus?.funcionarioNombreAprobador}
                  </Text>
                </Box>
                {isManagerAux && dateOfApproved?.length > 0 && (
                  <Box bg="#F6F6F6" w="100%" p={10} my={10}>
                    <Text size="sm" ta="left">
                      El auxilio fue aprobado el
                    </Text>
                    <Text size="sm" fw={700} color="#004236" ta="left">
                      {moment(dateOfApproved?.[0]?.fechaCreacion)
                        .locale("es")
                        .format("DD-MMM-YYYY hh:mm A")}
                    </Text>
                  </Box>
                )}
                {data?.travelStatus?.funcionarioNombreAprobadorPrevio && (
                  <Box bg="#F6F6F6" w="100%" p={10} mb={10}>
                    <Text size="sm" ta="left">
                      Aprobador previo
                    </Text>
                    <Text size="sm" fw={700} color="#004236" ta="left">
                      {data?.travelStatus?.funcionarioNombreAprobadorPrevio}
                    </Text>
                  </Box>
                )}
                <Button
                  fullWidth
                  mb={10}
                  className={classes.buttonOutline}
                  onClick={gotoReservationDetails}
                  sx={buttonStyles}
                >
                  Ver detalle anticipo
                </Button>
                {data?.travelStatus?.fkEstadoViaje === "LEGAPROSEC" &&
                  isManagerAux && (
                    <>
                      {userData?.datosUsuario?.permisosUnicos?.includes(
                        "Pago PSE"
                      ) && (
                        <Button
                          fullWidth
                          mb={10}
                          className={classes.button}
                          sx={buttonStyles}
                          onClick={handlePSE}
                          disabled
                        >
                          Pago PSE
                        </Button>
                      )}
                      {userData?.datosUsuario?.permisosUnicos?.includes(
                        "Soportes Bancarios"
                      ) &&
                        isExternalAid && (
                          <Button
                            fullWidth
                            mb={10}
                            className={classes.button}
                            sx={buttonStyles}
                            onClick={handleOpenSupport}
                          >
                            Soporte bancario
                          </Button>
                        )}
                    </>
                  )}
                {data?.travelStatus?.fkEstadoViaje === "LEGALIZENV" ||
                data?.travelStatus?.fkEstadoViaje === "LEGALIZENVPREV"
                  ? ((isApprovedTravel &&
                      data?.travelStatus?.fkEstadoViaje !== "LEGALIZENVPREV") ||
                      (isPreApproverTravel &&
                        data?.travelStatus?.fkEstadoViaje ===
                          "LEGALIZENVPREV")) && (
                      <>
                        <Button
                          mb={10}
                          fullWidth
                          className={classes.button}
                          onClick={handleApproveLegalizationAction}
                          sx={buttonStyles}
                        >
                          Aprobar Legalización
                        </Button>
                        <Button
                          mb={10}
                          fullWidth
                          className={classes.button}
                          onClick={openReject}
                          sx={buttonStyles}
                        >
                          Rechazar Legalización
                        </Button>
                      </>
                    )
                  : null}
                {data?.travelStatus?.fkEstadoViaje !== "ENPROLEGAL" ? null : (
                  <Button
                    fullWidth
                    mb={10}
                    className={classes.button}
                    onClick={handleApproveTripAction}
                    sx={buttonStyles}
                  >
                    Enviar Aprobar
                  </Button>
                )}
                {allowedModifyLegalization && (
                  <Button
                    fullWidth
                    mb={10}
                    className={classes.button}
                    onClick={gotoLegalization}
                    sx={buttonStyles}
                  >
                    Modificar Legalización
                  </Button>
                )}
                {isManagerAux &&
                  (data?.travelStatus?.fkEstadoViaje === "ENPROLEGAL" ||
                    data?.travelStatus?.fkEstadoViaje === "LEGALIZENV") &&
                  dataLegalization?.tipoLegalizacion === "CERO" &&
                  userData?.datosUsuario?.permisosUnicos?.includes(
                    "Cerrar Saldo Cero"
                  ) && (
                    <Button
                      fullWidth
                      mb={10}
                      className={classes.button}
                      onClick={handleApproveZeroTripAction}
                      sx={buttonStyles}
                    >
                      Cerrar con saldo cero
                    </Button>
                  )}
                {isHistorical && (
                  <Button
                    fullWidth
                    className={classes.buttonOutline}
                    mb={10}
                    onClick={navigateToHistorical}
                    sx={{
                      "@media print": {
                        display: "none",
                      },
                    }}
                  >
                    Volver al histórico
                  </Button>
                )}
                {isExternalAid &&
                  TipoProceso === 4 &&
                  ["LEGAPROSEC", "ANULFAVORECP", "DESCNOMINA"].includes(
                    data?.travelStatus?.fkEstadoViaje
                  ) && (
                    <Button
                      fullWidth
                      mb={10}
                      className={classes.button}
                      onClick={handlePayrollDeduction}
                      styles={{
                        root: {
                          minHeight: "2.25rem",
                        },
                        label: {
                          textAlign: "center",
                          whiteSpace: "unset",
                        },
                      }}
                    >
                      {data?.travelStatus?.fkEstadoViaje === "DESCNOMINA"
                        ? "Adjuntar Soporte Aprobación"
                        : "Marcar como descuento por nómina"}
                    </Button>
                  )}
                <Button
                  fullWidth
                  className={classes.button}
                  onClick={printWindow}
                  sx={{
                    ...buttonStyles,
                    paddingInline: 0,
                  }}
                >
                  Imprimir
                </Button>
              </Flex>
            </GlobalCard>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};
