import { Flex, Text, Button, FileInput, Textarea } from "@mantine/core";
import { FaUpload } from "react-icons/fa";

export const PayrollDeduction = ({
  opened,
  close,
  form,
  ModalComponent,
  classes,
  handleAttachSupport,
  handleUploadSupportPayrollDeduction,
}) => {
  return (
    <ModalComponent
      opened={opened}
      onClose={close}
      title="Soporte de aprobación del descuento por nómina"
      closeOnClickOutside={true}
    >
      <Flex direction="column" gap={12}>
        <Text ta="center">
          Adjunte el soporte de aprobación del descuento por nómina.
        </Text>
        <FileInput
          mt={10}
          onChange={handleAttachSupport}
          label="Adjuntar soporte de la aprobación"
          classNames={{
            input: classes.input,
            label: classes.labelSecondary,
          }}
          icon={<FaUpload size="1rem" />}
        />
        <Textarea
          {...form.getInputProps("observation")}
          minRows={5}
          label="Observaciones del gestor"
          placeholder="Ingrese una observación con al menos 10 caracteres"
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
        />
        <Text size="sm" color="#004236">
          Seleccionar un archivo con una capacidad maxima de 200 mb
        </Text>
        <Flex gap="md">
          <Button fullWidth className={classes.buttonOutline} onClick={close}>
            Cancelar
          </Button>
          <Button
            fullWidth
            disabled={!form.isValid()}
            className={classes.button}
            onClick={handleUploadSupportPayrollDeduction}
          >
            Aceptar
          </Button>
        </Flex>
      </Flex>
    </ModalComponent>
  );
};
