import {
  images,
  useStyles,
  outpatientcareServicesList,
  ModalComponent,
  useWindowSize,
  CardComponent,
  PaperComponent,
  SelectItem,
} from "@mfe/js-common-ave-uiutils";
import { BackgroundImage, Grid, LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import { useEffect, useState, useRef } from "react";
import BeneficiaryCard from "../components/beneficiaryCard.component";
import AidCard from "../components/aidCard.component";
import {
  es,
  getAge,
  currenciesSum,
  parserNumberInput,
  formatterNumberInput,
  formatter,
  getAccessTokenApi,
  fetchTraveLiquidatorByIDData,
  getAidBeneficiariesData,
  fetchAidBudgetData,
  fetchAidApproversData,
  fetchUserAdditionalInfoByTravelData,
  filterByBeneficiaryType,
  fetchLocalCitiesData,
  fetchAidTravelExpensesData,
  fetchTraveLiquidatorData,
  travelSelectionDataLiquidator,
  fetchAidPENAValidationData,
  fetchTraveLiquidatorPutData,
  travelSelectionDataLiquidatorEditAid,
  editFormDataAid,
  fetchAssistancecauseData,
  fetchAidreferenceBeneficiaryData,
  fetchAidreferenceBeneficiaryByID,
  getFormatDate,
  isExternalFlow,
  fetchTravelServicesData,
  fetchValidateTripsByBeneficiaryData,
} from "@mfe/ts-common-ave-utilitaries";
import moment from "moment";

export default function OutpatientcareQuotation({
  organization,
  msalInstance,
}) {
  return (
    <MsalProvider instance={msalInstance}>
      <OutpatientcareQuotationComponent organization={organization} />
    </MsalProvider>
  );
}

export const OutpatientcareQuotationComponent = ({ organization }) => {
  const isAuthenticated = useIsAuthenticated();
  const { classes } = useStyles();
  const { instance, accounts } = useMsal();
  const beneficiaryData = JSON.parse(
    localStorage.getItem("aidBeneficiaryData")
  );
  const [tokenApi, setTokenApi] = useState("");
  const isEdit = JSON.parse(localStorage.getItem("isEdit"));

  const [loadingBen, setLoadingBen] = useState(false);
  const [beneficiaryDataResponse, setBeneficiaryDataResponse] = useState([]);
  const [data, setData] = useState<any>({
    travelLiquidatorData: {
      ViajeTrayecto: [],
      CodigoEmpleadoViajero: "",
    },
    aidBudget: [],
    aidApprovers: [],
    dataAid: [],
    cities: [],
    travelExpenses: [],
    aidPENAValidation: [],
    services: [],
    assistancecause: [],
    aidreferenceBeneficiary: [],
    travelServices: [],
  });

  const dataUser = JSON.parse(localStorage.getItem("userData"));
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [
    openedBeneficiary,
    { open: openBeneficiary, close: closeBeneficiary },
  ] = useDisclosure(false);
  const [travelExpensesSelected, setTravelExpensesSelected] = useState([]);
  const [modalContent, setModalContent] = useState("TRAVEL");

  const inputRef = useRef(null);
  const [validateBeneficiaryDates, setValidateBeneficiaryDates] = useState({
    state: false,
    message: "",
  });

  const [errorModal, setErrorModal] = useState({
    liquidator: {
      message: "",
    },
  });

  const dataUserPermission = dataUser?.datosUsuario?.permisosUnicos;
  const isPermission = (permission) => dataUserPermission?.includes(permission);

  const isExternalAid = isExternalFlow(dataUserPermission);

  const form = useForm({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      budget: "",
      approver: "",
      referenceNumber: "",
      objective: "",
      beneficiaryDates: [null, null],
      accompanyingDates: [null, null],
      originCity: "",
      destinationCity: "",
      clinicalConditions: "",
      urgency: false,
      aidWithoutBeneficiary: false,
      accompanyingLiquidate: false,
      travelExpenses: { name: "", value: "" },
      withAccompanying: beneficiaryData?.withAccompanying || false,
      isReturnBeneficiary: false,
      isReturnAccompanying: false,
      reasonPENA: "",
      causesAid: "",
      manualReference: false,
    },
    validate: {
      budget: (value) =>
        value ? null : "Seleccione el elemento presupuestal del auxilio.",
      approver: (value) =>
        value ? null : "Seleccione el aprobador del auxilio.",
      referenceNumber: (value) =>
        /^[0-9\-_ ]+$/.test(value)
          ? null
          : "Ingrese un número de referencia valido.",
      causesAid: (value) => (value ? null : "Seleccione una causa de auxilio."),
      objective: (value) =>
        value.length >= 5
          ? value.length >= 500
            ? "No puede superar los 500 caracteres"
            : null
          : "El objetivo debe tener al menos 5 caracteres.",
      reasonPENA: (value) =>
        value.length >= 5
          ? null
          : "El motivo debe tener al menos 5 caracteres.",
      clinicalConditions: (value) =>
        value.length <= 500
          ? null
          : "Las condiciones clínicas no pueden superar los 500 caracteres.",
    },
  });

  const requiredFields = [
    "objective",
    "budget",
    "approver",
    "referenceNumber",
    ...(isExternalAid ? ["causesAid"] : []),
  ];

  const isValid = () => {
    return requiredFields.every((field) => form.isValid(field));
  };
  //@ts-ignore
  const [budgetCode, budgetElement, budgetCodRegional, budgetLocality] =
    form?.values?.budget?.split("/") || [];

  const [codOrigin, groupOriginId, originCity] =
    form?.values?.originCity?.split("/") || [];

  const [codDestination, groupDestinationId, destinationCity] =
    form?.values?.destinationCity?.split("/") || [];

  const [approverCode, approverName] = form?.values?.approver?.split("/") || [];

  const [id, name, alias] = form.values.travelExpenses.name.split("/");

  const liquidator = data?.travelLiquidatorData;

  const aidData = liquidator?.ViajeTrayecto?.[0]?.auxilioMedico;

  const filterCityByIata = (codIata) =>
    data?.cities?.filter(({ value }) => value?.split("/")?.[0] === codIata);

  const isManualReference = form.values.manualReference;
  const referenceNumberValue = form.values.referenceNumber;

  const referenceDataFiltered = async () => {
    if (!isManualReference && referenceNumberValue.length) {
      const { data } = await fetchAidreferenceBeneficiaryByID(
        tokenApi,
        form.values.referenceNumber
      );
      const filterOrigin = filterCityByIata(data?.viaje?.CiudadOrigen)?.[0]
        ?.value;

      const filterDestination = filterCityByIata(
        data?.viaje?.CiudadDestino
      )?.[0]?.value;

      const initialDate = getFormatDate(data?.viaje?.FechaInicioServicio);

      const endDate = getFormatDate(data?.viaje?.FechaFinServicio);

      form.setValues((prev) => ({
        ...prev,
        originCity: filterOrigin,
        destinationCity: filterDestination,
        accompanyingDates: form.values.withAccompanying
          ? [initialDate, endDate]
          : [null, null],
        beneficiaryDates: [initialDate, endDate],
      }));
    }
  };

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchAidBudgetData(
        tokenApi,
        setData,
        beneficiaryData?.holder?.regional,
        beneficiaryData?.holder?.ciudad
      );
    }
  }, [tokenApi]);

  useEffect(() => {
    if (isExternalAid) {
      if (tokenApi) {
        fetchAssistancecauseData(tokenApi, setData, setLoading);
        fetchAidreferenceBeneficiaryData(
          tokenApi,
          setData,
          setLoading,
          beneficiaryData?.userSelected?.doc
        );
      }
    }
  }, [tokenApi]);

  useEffect(() => {
    if (isExternalAid) {
      referenceDataFiltered();
    }
  }, [isManualReference, dataUserPermission?.length, referenceNumberValue]);

  useEffect(() => {
    if (isManualReference && isExternalAid) {
      form.setValues((prev) => ({
        ...prev,
        originCity: "",
        destinationCity: "",
        accompanyingDates: [null, null],
        beneficiaryDates: [null, null],
      }));
    }
  }, [isManualReference]);

  useEffect(() => {
    if (form.values.budget) {
      form.setFieldValue("approver", "");
      fetchAidApproversData(tokenApi, setData, {
        budgetCodRegional,
        budgetLocality,
      });
    }
  }, [form.values.budget]);

  useEffect(() => {
    if (isPermission("auxilios Cenit")) {
      form.setFieldValue("referenceNumber", "0000");
    }
  }, [dataUser?.datosUsuario?.codigoEmpleado?.length]);

  useEffect(() => {
    if (tokenApi && isEdit) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoading
      );
      fetchUserAdditionalInfoByTravelData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID")
      );
      if (liquidator.ViajeTrayecto.length) {
        getAidBeneficiariesData(
          setBeneficiaryDataResponse,
          tokenApi,
          liquidator?.CodigoEmpleadoViajero,
          setLoadingBen
        );
      }
    }
  }, [isEdit, tokenApi, liquidator?.ViajeTrayecto?.length]);

  useEffect(() => {
    if (beneficiaryDataResponse.length > 0 && isEdit) {
      const validateTitularDocument = filterByBeneficiaryType(
        data?.dataAid,
        "TITULAR",
        "numeroDocumento"
      );
      const validateBeneficiary = filterByBeneficiaryType(
        data?.dataAid,
        "BENEFICIARIO",
        "numeroDocumento"
      );
      const filterHolder = beneficiaryDataResponse?.filter(
        ({ doc }) => doc === validateTitularDocument
      );
      const filterBeneficiary = beneficiaryDataResponse?.filter(
        ({ doc }) => doc === validateBeneficiary
      );

      const editStructure = {
        userSelected: filterBeneficiary?.[0],
        withAccompanying: aidData?.Acompanante,
        holder: filterHolder?.[0],
      };
      localStorage.setItem("aidBeneficiaryData", JSON.stringify(editStructure));
    }
  }, [beneficiaryDataResponse.length, isEdit]);

  useEffect(() => {
    if (form.values.urgency && form.values.destinationCity.length > 0) {
      setSelected(
        outpatientcareServicesList.filter((item) => item.alias === "AMBULANCIA")
      );
    }
  }, [form.values.urgency, form.values.destinationCity]);

  useEffect(() => {
    if (tokenApi) {
      fetchLocalCitiesData(setData, tokenApi);
      fetchAidTravelExpensesData(setData, tokenApi);
      if (codOrigin && codDestination && form.isDirty("objective")) {
        setData((prev) => ({
          ...prev,
          aidPENAValidation: [],
        }));
        form.setFieldValue("reasonPENA", "");
        fetchAidPENAValidationData(tokenApi, setData, {
          origin: codOrigin,
          destination: codDestination,
        });
        fetchTravelServicesData(
          setData,
          tokenApi,
          beneficiaryData?.holder?.descNomina === "Directivo" ? 100 : 110,
          codDestination,
          {
            travelType: 8,
            travelCommission: 7,
          }
        );
      }
    }
  }, [tokenApi, codOrigin, codDestination, form.isDirty("objective")]);

  useEffect(() => {
    if (!form.values.withAccompanying || form.values.urgency) {
      form.setValues((prev) => ({
        ...prev,
        beneficiaryDates: [null, null],
        accompanyingDates: [null, null],
      }));
    } else if (!isEdit) {
      form.setValues((prev) => ({
        ...prev,
        beneficiaryDates: [new Date(), new Date()],
      }));
    }
  }, [form.values.urgency, form.values.withAccompanying, isEdit]);

  useEffect(() => {
    if (
      !isEdit &&
      form.values.accompanyingDates[1] &&
      form.values.withAccompanying &&
      form.values.urgency
    ) {
      form.setFieldValue("beneficiaryDates", [
        new Date(form.values.accompanyingDates[1]),
        new Date(form.values.accompanyingDates[1]),
      ]);
    }
  }, [
    String(form.values.accompanyingDates[1]).length,
    form.values.withAccompanying,
    form.values.urgency,
  ]);

  useEffect(() => {
    if (
      !isEdit &&
      form.values.beneficiaryDates[1] &&
      form.values.withAccompanying &&
      new Date(form.values.beneficiaryDates[0]).toDateString() !==
        new Date(form.values.beneficiaryDates[1]).toDateString() &&
      !form.values.urgency
    ) {
      form.setFieldValue("accompanyingDates", [
        new Date(form.values.beneficiaryDates[0]),
        new Date(form.values.beneficiaryDates[0]),
      ]);
    }
  }, [
    String(form.values.beneficiaryDates[1]).length,
    form.values.withAccompanying,
    form.values.urgency,
  ]);

  useEffect(() => {
    if (data?.aidPENAValidation === null && !isEdit) {
      setModalContent("PENA");
      inputRef?.current?.blur();
      open();
    }
  }, [data?.aidPENAValidation, isEdit]);

  useEffect(() => {
    if (!!errorModal.liquidator.message.length) {
      open();
    }
  }, [errorModal]);

  useEffect(() => {
    if (form.values.beneficiaryDates[1] && !form.values.aidWithoutBeneficiary) {
      setValidateBeneficiaryDates({
        state: false,
        message: "",
      });
      const payload = {
        registroTitular: beneficiaryData?.holder?.registro,
        acompanante: false,
        urgencia: form.values.urgency,
        beneficiarioNoViaja: form.values.aidWithoutBeneficiary,
        datosBeneficiario: {
          cedula: beneficiaryData?.userSelected?.doc,
          fechaInicio: moment(form.values.beneficiaryDates[0]).format(
            "YYYY-MM-DD"
          ),
          fechaFin: moment(form.values.beneficiaryDates[1]).format(
            "YYYY-MM-DD"
          ),
        },
        datosAcompanante: {},
      };
      fetchValidateTripsByBeneficiaryData(
        tokenApi,
        payload,
        setValidateBeneficiaryDates
      );
    }
  }, [
    String(form.values.beneficiaryDates[1]),
    form.values.urgency,
    form.values.aidWithoutBeneficiary,
  ]);

  useEffect(() => {
    if (validateBeneficiaryDates.state) {
      openBeneficiary();
    }
  }, [validateBeneficiaryDates.state]);

  useEffect(() => {
    if (
      isEdit &&
      liquidator?.ViajeTrayecto?.length > 0 &&
      data?.cities?.length > 0
    ) {
      editFormDataAid(form, {
        liquidator: liquidator,
        aidBudget: data?.aidBudget,
        aidApprovers: data?.aidApprovers,
        cities: data?.cities,
        isExternalAid,
      });
      setSelected(
        liquidator?.ViajeTrayecto?.[0]?.ServicioBpo?.map((item) => ({
          Id: item.Id,
          id: item.ServicioId,
          alias: item.AliasServicio,
        }))
      );
      setTravelExpensesSelected(
        liquidator?.ViajeGasto?.map((item) => ({
          Id: item.Id,
          name: item.Nombre,
          id: item.GastoId,
          value: item.Valor,
          alias: item.alias,
        }))
      );
    }
  }, [
    isEdit,
    data?.cities?.length,
    data?.aidBudget?.length,
    data?.aidApprovers?.length,
    liquidator?.ViajeTrayecto?.length,
  ]);

  const servicesData = (data, defaultData) => {
    return [
      {
        alias: "HOTEL",
        title: "Hotel",
        value: "HOTEL",
      },
      ...(!isPermission("auxilios Cenit")
        ? [
            {
              alias: "TERRESTRE",
              title: "Buses",
              value: "TERRESTRE",
            },
          ]
        : []),
      ,
      ...(isPermission("transporte Especial")
        ? [
            {
              alias: "ESPTERREST",
              title: "Transporte Especial Terrestre",
              value: "ESPTERREST",
            },
          ]
        : []),
      ...(data?.some((service) => service?.alias === "AVION")
        ? [
            {
              alias: "AVION",
              title: "Avión",
              value: "AVION",
            },
          ]
        : []),
      ,
      {
        alias: "AMBULANCIA",
        title: "Ambulancia",
        value: "AMBULANCIA",
      },
    ]
      .map((item) => {
        const iconList = defaultData.find((i) => i.alias === item.alias);
        return {
          ...item,
          icon: iconList?.icon,
        };
      })
      ?.filter(Boolean);
  };

  const toggleSelected = (option) => {
    if (option.alias === "AMBULANCIA") {
      setSelected([option]);
    } else {
      if (selected.some((item) => item.alias === "AMBULANCIA")) {
        setSelected([option]);
      } else {
        if (selected.some((item) => item.alias === option.alias)) {
          setSelected(selected.filter((item) => item.alias !== option.alias));
        } else {
          setSelected([...selected, option]);
        }
      }
    }
  };

  const excludeDate = (date, isBeneficiary = false) => {
    const dateToCheck = moment(date);
    const value = isBeneficiary
      ? form.values.accompanyingDates
      : form.values.beneficiaryDates;

    const [start, end] = value.map((date) => moment(date));
    const mapDate =
      dateToCheck.isBetween(start, end, "day", "(]") ||
      dateToCheck.isSame(start);

    return !mapDate;
  };

  const excludeBeneficiaryDates = (date) => {
    if (form.values.urgency && form.values.withAccompanying) {
      return excludeDate(date, true);
    }
  };

  const excludeAcompanionDates = (date) => {
    if (
      !form.values.urgency &&
      new Date(form.values.beneficiaryDates[0]).toDateString() !==
        new Date(form.values.beneficiaryDates[1]).toDateString()
    ) {
      return excludeDate(date);
    }
  };

  const handleAddTravelExpensesType = () => {
    setTravelExpensesSelected((prev) => [
      ...prev,
      {
        id,
        name,
        alias,
        value: form.values.travelExpenses.value,
      },
    ]);
    form.setFieldValue("travelExpenses.value", "");
    form.setFieldValue("travelExpenses.name", "");
  };

  const handleOpen = () => setIsOpen(!isOpen);

  const filterServices = !form.values.urgency
    ? servicesData(data.travelServices, outpatientcareServicesList)
    : servicesData(data.travelServices, outpatientcareServicesList)?.filter(
        (item) => item.alias === "AMBULANCIA"
      );

  const isToday = (date) => {
    const today = new Date();
    if (!date) return false;
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const minDateAccompanying =
    form.isTouched("beneficiaryDates") &&
    form.values.beneficiaryDates.every((fecha) => !isToday(fecha)) &&
    form.values.beneficiaryDates[0] &&
    new Date(form.values.beneficiaryDates[0]);

  const submitValidation = () => {
    if (
      selected.some((item) => item.alias === "AMBULANCIA") ||
      selected.length === 0
    ) {
      handleSubmit();
    } else if (selected.length > 0) {
      setModalContent("TRAVEL");
      open();
    }
  };

  const payload = {
    form: form,
    process: 4,
    codOrigin,
    codDestination,
    originCity,
    groupOriginId,
    groupDestinationId,
    destinationCity,
    budgetCode,
    budgetElement,
    approverCode,
    approverName,
    dataUser,
    beneficiaryData,
    servicesSelected: selected,
    travelExpensesTypeSelected: travelExpensesSelected,
    payroll: beneficiaryData?.userSelected?.descNomina,
    liquidator: liquidator,
    isExternalAid,
  };

  const handleSubmit = () => {
    !isEdit
      ? fetchTraveLiquidatorData(
          tokenApi,
          setLoading,
          () => {},
          travelSelectionDataLiquidator(payload, true),
          () => {},
          beneficiaryData
        )
      : fetchTraveLiquidatorPutData(
          tokenApi,
          setLoading,
          travelSelectionDataLiquidatorEditAid(payload)
        );
  };

  const utils = {
    currenciesSum,
    parserNumberInput,
    formatterNumberInput,
    formatter,
    es,
  };
  const aidCardProps = {
    form,
    isValid: !isValid(),
    beneficiaryData,
    data,
    modalContent,
    loading,
    utils,
    isOpen,
    setIsOpen,
    ModalComponent,
    PaperComponent,
    CardComponent,
    opened,
    close,
    classes,
    useWindowSize,
    handleSubmit,
    outpatientcareServicesList,
    editFormDataAid,
    inputRef,
    setTravelExpensesSelected,
    travelExpensesSelected,
    selected,
    servicesData,
    toggleSelected,
    submitValidation,
    excludeBeneficiaryDates,
    excludeAcompanionDates,
    errorModal,
    handleAddTravelExpensesType,
    handleOpen,
    filterServices,
    minDateAccompanying,
    isExternalAid,
    openedBeneficiary,
    closeBeneficiary,
    validateBeneficiaryDates,
  };

  const beneficiaryCardProps = {
    form,
    tokenApi,
    beneficiaryData,
    data,
    beneficiaryDataResponse,
    ModalComponent,
    CardComponent,
    images,
    classes,
    getAge,
    SelectItem,
    isPermission,
    isExternalAid,
  };

  if (loading || loadingBen) {
    return (
      <LoadingOverlay
        visible={loading || loadingBen}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <BackgroundImage
      src={images.skyImageBackground}
      className={classes.backgroundImage}
    >
      <Grid
        maw={{ xs: "100%", md: "100%", lg: "80%" }}
        mx="auto"
        sx={(theme) => ({
          [theme.fn.smallerThan("md")]: {
            height: "100%",
          },
        })}
      >
        <Grid.Col md={6}>
          <BeneficiaryCard {...beneficiaryCardProps} />
        </Grid.Col>
        <Grid.Col
          md={6}
          sx={(theme) => ({
            [theme.fn.smallerThan("md")]: {
              paddingBottom: "3rem",
            },
          })}
        >
          <AidCard {...aidCardProps} />
        </Grid.Col>
      </Grid>
    </BackgroundImage>
  ) : null;
};
