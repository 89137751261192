import {
  useStyles,
  useWindowSize,
  GlobalCard,
  usePathname,
  WCInlineInputFilter,
  ModalComponent,
} from "@mfe/js-common-ave-uiutils";

import {
  Button,
  Box,
  Flex,
  LoadingOverlay,
  Grid,
  Text,
  Center,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import {
  getAccessTokenApi,
  fetchTravelRequestExternalData,
  lodash,
} from "@mfe/ts-common-ave-utilitaries";
import { ExternalRequestCardDetail } from "../views/externalRequestCardDetail.component";
import Tabs from "../components/tabs.component";
import React, { useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import TopFilterSection from "../components/topFilterSection.component";

export default function Requests({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <ExternalRequestsComponent organization={organization} />
    </MsalProvider>
  );
}

export const ExternalRequestsComponent = ({ organization }) => {
  const { classes } = useStyles();
  const pathname = usePathname();
  const isSm = useWindowSize("sm");

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [tokenApi, setTokenApi] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [clearFilters, setClearFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [opened, { toggle }] = useDisclosure(false);
  const [openedError, { open, close }] = useDisclosure(false);

  const [modalContent, setModalContent] = useState({
    content: null,
    showButtons: false,
    onClick: () => {},
  });

  const [data, setData] = useState({
    requestData: [],
    status: [],
    approverActive: null,
  });

  const form = useForm({
    initialValues: {
      statusFilter: [],
      idFilter: 0,
      refectTripConfirmTravel: "yes",
      refectTripConfirmContinue: "yes",
    },
  });

  const dataUser = userData?.datosUsuario;

  const sapRegisterCode = lodash.trimStart(
    organization !== "externos" ? dataUser?.registroSap : dataUser?.codigoSap,
    "0"
  );

  const filterData = {
    viajeId: form.values.idFilter || "0",
    estadosviaje: ["APROPAGSAP"],
    codigoEmpleadoviajero: sapRegisterCode,
    esAuxilio: true,
  };

  const dinamicRoutes = [
    {
      name: "Auxilios Por Legalizar",
      route: "auxilios-por-legalizar",
      fetchData: () =>
        fetchTravelRequestExternalData(
          tokenApi,
          sapRegisterCode,
          setData,
          pageNumber,
          setLoading,
          filterData
        ),
    },
    {
      name: "Auxilios con saldo a favor de la empresa",
      route: "auxilios-saldo-a-favor",
      fetchData: () =>
        fetchTravelRequestExternalData(
          tokenApi,
          sapRegisterCode,
          setData,
          pageNumber,
          setLoading,
          {
            ...filterData,
            estadosviaje: ["LEGAPROSEC", "ANULFAVORECP"],
          }
        ),
    },
  ];

  const routeMap = dinamicRoutes.reduce((acc, routeObj) => {
    acc[routeObj.route] = routeObj.name;
    return acc;
  }, {});

  const routeNames = dinamicRoutes.map((routeObj) => routeObj.route);
  const [activeRouteName, setActiveRouteName] = useState(routeNames[0]);
  const route = pathname.split("/")[2];
  const index = routeNames.indexOf(route);

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
    localStorage.removeItem("liquidationID");
    localStorage.removeItem("isRejectedSupports");
    localStorage.removeItem("isEdit");
    localStorage.removeItem("externalFlow");
  }, []);

  useEffect(() => {
    const newRouteNames = [...dinamicRoutes].map((routeObj) => routeObj.route);
    const index = newRouteNames.indexOf(route);
    if (tokenApi && index !== -1) {
      setActiveRouteName(route);
      dinamicRoutes[index].fetchData();
    } else {
      setActiveRouteName(newRouteNames[0]);
      navigateToUrl(`/solicitudes-auxilio-autogestion/${newRouteNames[0]}`);
    }
  }, [tokenApi, pathname, pageNumber]);

  useEffect(() => {
    if (clearFilters) {
      setPageNumber(1);
      dinamicRoutes[index].fetchData();
      setClearFilters(false);
    }
  }, [clearFilters]);

  const handleChangeTab = (value) => {
    navigateToUrl(`/solicitudes-auxilio-autogestion/${value}`);
    setActiveRouteName(value);
    form.reset();
    setPageNumber(1);
  };

  const handleReset = () => {
    form.reset();
    setClearFilters(true);
  };

  const handleFilter = () => {
    setPageNumber(1);
    dinamicRoutes[index].fetchData();
  };

  const loadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const topFilterData = [
    {
      sm: 6,
      md: 3,
      lg: 3,
      inputType: "textInput",
      label: "Filtrar por ID",
      placeHolder: "Digite un ID",
      value: "idFilter",
      form: form.getInputProps("idFilter"),
    },
    {
      sm: 6,
      md: 2,
      lg: 2,
      inputType: "button",
      title: "Filtrar",
      onClick: handleFilter,
    },
    {
      sm: 6,
      md: 2,
      lg: 2,
      inputType: "button",
      title: "Limpiar Filtro",
      outline: true,
      onClick: handleReset,
    },
  ];

  const tabsProps = {
    activeRouteName,
    handleChangeTab,
    routeNames,
    classes,
    routeMap,
  };

  const topFilterSectionProps = {
    activeRouteName,
    data,
    classes,
    WCInlineInputFilter,
    opened,
    topFilterData,
    toggle,
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <>
      <ModalComponent opened={openedError} onClose={close} title="Alerta">
        <Flex direction="column" gap={12}>
          {typeof modalContent?.content === "string" ? (
            <Text>{modalContent?.content}</Text>
          ) : (
            modalContent?.content
          )}
        </Flex>
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={() => modalContent.onClick()}
              fullWidth
              className={classes.button}
            >
              Aceptar
            </Button>
            <Button onClick={close} fullWidth className={classes.buttonOutline}>
              Cancelar
            </Button>
          </Flex>
        )}
      </ModalComponent>

      <Tabs {...tabsProps} />
      <TopFilterSection {...topFilterSectionProps} />
      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
        {!!data.requestData.length ? (
          <>
            <ExternalRequestCardDetail
              data={data.requestData}
              path={route}
              tokenApi={tokenApi}
              dinamicRoutes={() => dinamicRoutes[index].fetchData()}
            />

            <Center mt={12}>
              <Button className={classes.button} onClick={loadMore}>
                Cargar más
              </Button>
            </Center>
          </>
        ) : (
          <Grid>
            <Grid.Col xs={12}>
              <GlobalCard>
                <Flex
                  gap={12}
                  justify="center"
                  align="center"
                  direction="column"
                  sx={{ height: isSm ? "auto" : "224px" }}
                >
                  <Text fw={700} color="#004236" ta="center">
                    No hay solicitudes.
                  </Text>
                </Flex>
              </GlobalCard>
            </Grid.Col>
          </Grid>
        )}
      </Box>
    </>
  ) : null;
};
